import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import { useParams } from 'react-router-dom';
import config from "../common/Config";
import Swal from 'sweetalert2';


const EditPurchasesFrom = (props) => {
	const { orderId } = useParams();

	const [supplierOptions, setSupplierOptions] = useState([]);
    const [supplierFilteredOptions, setSupplierFilteredOptions] = useState([]);
    const [supplierValue, setSupplierValue] = useState('');
    const [supplierInputValue, setSupplierInputValue] = useState('');

    const[selectedSupplierValue, setSelectedSupplierValue] = useState('');

  	const [productOptions, setProductOptions] = useState([]);
    const [productFilteredOptions, setProductFilteredOptions] = useState([]);
    const [productValue, setProductValue] = useState(null);  
    const [productInputValue, setProductInputValue] = useState('');

    const accessToken = localStorage.getItem('accessToken'); 

    const [newProductList, setNewProductList] = useState([]);
    const [tableRows, setTableRows] = useState([]);

    const [note, setNote] = useState('');

    const supplierHandleInputChange = (inputValue) => {
        setSupplierInputValue(inputValue);
        const newFilteredOptions = supplierOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setSupplierFilteredOptions(newFilteredOptions);
    };
    const supplierHandleChange = (selectedOption) => {
        setSupplierValue(selectedOption);
    };
    const productHandleInputChange = (inputValue) => {
        setProductInputValue(inputValue);
        const newFilteredOptions = productOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setProductFilteredOptions(newFilteredOptions);
    };
    const productHandleChange = (selectedOption) => {
        
        if (selectedOption ) {
            setProductValue(selectedOption);
            fetchAddProductInfo(selectedOption);

            //console.log(selectedOption);
        } else {
            setProductValue(null); 
        }
    };
    const formatNumber = (num) => {
	    return parseFloat(num).toFixed(2);
	};

	const fetchAddProductInfo = async (selectedOption) => {
        const formData = new FormData();
        formData.append('product_id', selectedOption.value);
        formData.append('supplier', supplierValue.value);
        const existingProduct = tableRows.find(item => item.code === selectedOption.code);

        if (existingProduct) {
            
             Swal.fire({
              title: 'Error',
              text: 'Product already exists in the list.',
              icon: 'error',
              showConfirmButton: false, 
              timer: 2000,               
              timerProgressBar: true,     
            });
            return;
        }

       
        const apiUrl = `${config.API_URL}/purchases/get_order_datails`; 
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to fetch new product');
            }

            const data = await response.json();
            const productData = data[0];

            //console.log(data);

            setNewProductList(data.productData);

            


            const unitCost = productData.split === '0' || productData.split === '2'
                ? parseFloat(productData.price)
                : parseFloat(productData.piece_cost);


            const newRow = {
                code: productData.code,
                id: productData.id,
                price: productData.price,
                piece_cost: productData.piece_cost,
                split: productData.split,
                type :  productData.split === '0' || productData.split === '2' ? 'box' : 'piece',
                split_quantity: productData.split_quantity,
                split_price: productData.split_price,
                name: productData.name,
                size: productData.size,
                cost: productData.cost,
                rate: productData.rate,
                stockCount: productData.split == '0' || productData.split == '2' ? parseInt(productData.quantity) : parseInt(productData.split_quantity),
                pdtcost : unitCost.toFixed(2),
                order_qty: 1,
                subtotal: (unitCost * 1).toFixed(2),
                vat: ((unitCost * 1) * formatNumber(productData.rate) / 100).toFixed(2),
                total: ((unitCost * 1) + ((unitCost * 1) * formatNumber(productData.rate) / 100)).toFixed(2),
                current_stock : productData.quantity,
            };

            //console.log(newRow);

            setTableRows((prevRows) => [...prevRows, newRow]);

        } catch (error) {
            console.error('API fetch error:', error);
        }
    };
	

	const fetchSupplierList = async () => {
  		
  		const apiSalesRouteUrl = `${config.API_URL}/purchases/getSupplier`;
  		try {
  			const response = await fetch(apiSalesRouteUrl, {
  				method : 'POST',
  				headers : {
  					authorization : `Bearer ${accessToken}`,
  				}
  			});
  			const data = await response.json();
  			const formattedOptions = data.supplier.map(item => ({
                label: item.company,  
          		value: item.id,    
            }));
            setSupplierOptions(formattedOptions);
            setSupplierFilteredOptions(formattedOptions);

  		}catch(error){
  			console.error('api fail error fetching sales routes');
  		}
  	};

  	const fetchProductList = async () => {
        const apiSalesRouteUrl = `${config.API_URL}/purchases/getProducts`;
        try {
            const response = await fetch(apiSalesRouteUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            });

            const data = await response.json();
           // console.log(data);
            const formattedOptions = data.products.map(item => ({
                label: item.name,
                value: item.id,
                code : item.code,
            }));
            setProductOptions(formattedOptions);
            setProductFilteredOptions(formattedOptions);

        } catch (error) {
            console.error('api fail error fetching products');
        }
    };

    const fetchPurchesItemsList = async () => {

  		const formData = new FormData();
  		formData.append('id', orderId);
  		
  		
        const apiSalesRouteUrl = `${config.API_URL}/purchases/getpurches_items`; 
  		try {
  			const response = await fetch(apiSalesRouteUrl, {
  				method : 'POST',
  				headers : {
  					authorization : `Bearer ${accessToken}`,
  				},
  				body: formData,
  			});
  			const data = await response.json();
  			const productData = data.purchase;
  			setNewProductList(data.productData);
  			//console.log(productData);

            setSelectedSupplierValue(data.commonData[0].supplierId);
            console.log(data.commonData[0].supplierId);

  			const pdtRows = productData.map((item) => {
	            // Determine unit cost
	            const unitCost = item.split === '0' || item.split === '2'
	                ? parseFloat(item.unit_cost)
	                : parseFloat(item.piece_cost);
            
	            // Format the new row object
	            return {
	                code: item.product_code,
	                id: item.id,
	                price: item.price,
	                piece_cost: item.piece_cost,
	                split: item.split,
	                type: item.split === '0' || item.split === '2' ? 'box' : 'piece',
	                split_quantity: item.split_quantity,
	                split_price: item.split_price,
	                name: item.product_name,
	                size: item.size,
	                cost: item.unit_cost,
	                rate: item.rate,
	                stockCount: item.split == '0' || item.split == '2' ? parseInt(item.current_stock) : parseInt(item.split_quantity),
	                pdtcost: unitCost.toFixed(2),
	                order_qty:  parseInt(item.quantity),
	                subtotal: (unitCost * parseInt(item.quantity)).toFixed(2),
	                vat: ((unitCost * parseInt(item.quantity)) * (parseFloat(item.rate) / 100)).toFixed(2),
	                total: ((unitCost * parseInt(item.quantity)) + ((unitCost * parseInt(item.quantity)) * (parseFloat(item.rate) / 100))).toFixed(2),
                    current_stock : item.current_stock
	            };
	        });

        	// Update the state with the formatted rows
        	setTableRows(pdtRows);

        	

  		}catch(error){
  			console.error('api fail error fetching sales routes');
  		}
  	};


  	useEffect(() => {

  		if (accessToken === null){
            window.location.href = `${config.BASE_URL}/login`; 
            return;
        }
        fetchSupplierList();
        fetchProductList();
        fetchPurchesItemsList();

        

  	},[]);

    useEffect(() => {
        if (selectedSupplierValue) {
            const selectedCategory = supplierOptions.find(option => option.value == selectedSupplierValue);
            setSupplierValue(selectedCategory || null);
        }
    }, [selectedSupplierValue, supplierOptions]);


  	const handleQuantityChange = (id, newQty) => {
        setTableRows(prevRows => {
            return prevRows.map(row => {
                if (row.id === id) {
                    const order_qty = parseFloat(newQty) || 0;
                    const unitCost = parseFloat(row.pdtcost) || 0;
                    const rate = parseFloat(row.rate) || 0;

                    const subtotal = (order_qty * unitCost).toFixed(2);
                    const vat = ((order_qty * unitCost) * rate / 100).toFixed(2);
                    const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);

                    return {
                        ...row,
                        order_qty,
                        subtotal,
                        vat,
                        total
                    };
                }
                return row;
            });
        });
    };

    const handleCostChange = (id, newCost) => {
        setTableRows(prevRows => {
            return prevRows.map(row => {
                if (row.id === id) {
                    const pdtcost = parseFloat(newCost) || 0;
                    const order_qty = parseFloat(row.order_qty) || 0;
                    const rate = parseFloat(row.rate) || 0;

                    const subtotal = (order_qty * pdtcost).toFixed(2);
                    const vat = ((order_qty * pdtcost) * rate / 100).toFixed(2);
                    const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);

                    return {
                        ...row,
                        pdtcost,
                        subtotal,
                        vat,
                        total
                    };
                }
                return row;
            });
        });
    };

    const handleOptionChange = (id, pdtType) => {
        console.log(id + '----'+ pdtType);
        setTableRows(prevRows => {
            return prevRows.map(row => {
                if (row.id === id) {
                    const pdtcost = pdtType === 'Box' ? row.price : row.piece_cost;
                    const order_qty = parseFloat(row.order_qty) || 0;
                    const rate = parseFloat(row.rate) || 0;
                    const subtotal = (order_qty * pdtcost).toFixed(2);
                    const vat = ((order_qty * pdtcost) * rate / 100).toFixed(2);
                    const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);
                    const type = pdtType === 'Box' ? 'box' : 'piece';
                    const stockCount = pdtType == 'Box' ? parseInt(row.current_stock || 0) : parseInt(row.split_quantity || 0);
                    return {
                        ...row,
                        type,
                        pdtcost,
                        subtotal,
                        vat,
                        total,
                        stockCount
                    };
                }
                return row;
            });
        });
    };
    const handleCancel = (id) => {
        setTableRows(prevRows => prevRows.filter(row => row.id !== id));
    };

    const handleChange = (event) => {
        setNote(event.target.value);
    };

    const submitFormData = async() =>{
        

        const filteredTableRows = tableRows.map(row => ({
            code: row.code,
            name: row.name,
            order_qty: row.order_qty,
            subtotal: row.subtotal,
            vat: row.vat,
            total: row.total,
            type: row.type,
        }));

        //console.log(filteredTableRows);

        const form = document.getElementById('editproductform');
        const formData = new FormData(form);
        formData.append('supplier', supplierValue.value);
        formData.append('purchase_id', orderId);
        formData.append('note', note);

        const apiSalesRapUrl = `${config.API_URL}/purchases/update_purchase`;
        try {
            const response = await fetch(apiSalesRapUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            const data = await response.json();
            
            if (data.success == true){
                Swal.fire({
                  title: "Success!",
                  text: "Purchase Updated successfully.",
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                }).then(() => 
                {
                  
                    window.location.href = `${config.BASE_URL}/purchases/purchases_order/`;
                });
            }else{
              Swal.fire({
                  title: "Failed!",
                  text: "Purchase Updated Failed",
                  icon: "error",
                  timer: 1500,
                  showConfirmButton: false,
              });
            }

        } catch (error) {
          console.error('Error fetching api:', error);
        }

        setTableRows([]);
        setSupplierValue('');
        setNote('');

        
    }

	return(
		<>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/reports/mis_report">Purchases</Link> 
		                  <Link to="/reports/mis_report">Edit PO </Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>
	      	<div className="main-container">
			    <div className="container-fluid">
			    	<div className="row">
			    		<div className="col-md-9 col-lg-9 col-sm-12">
			    			<div className="col-md-4 cp-lg-4 col-sm-4" style={{float: 'left',  padding: '17px 0px 0px 15px'}}>
							    

				                <Select
						            isClearable
						            onInputChange={supplierHandleInputChange}
						            onChange={supplierHandleChange}
						            options={supplierFilteredOptions}
						            placeholder="Select Supplier"
						            value={supplierValue}
						        />

							</div>

							<div className="col-md-4 cp-lg-4 col-sm-4" style={{float: 'left',  padding: '17px 0px 0px 15px'}}>
							    
				                <Select
						            isClearable
						            onInputChange={productHandleInputChange}
						            onChange={productHandleChange}
						            options={productFilteredOptions}  // Pass the filtered options here
						            placeholder="Select Product"
						            value={productValue}
						        />
							</div>
			    		</div>
			    	</div>

			    	<div className="total-manifests-area dashboard" style={{position : 'relative'}} >
	                    <p><b>Order Items </b></p>
	                    
                        <form id="editproductform">
	                    <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
	                        <table className="table table-bordered dataTable resizable" id="newProductTable">
                                <thead>
                                    <tr>
                                        <th>Code-Name-Size</th>
                                        <th>Type</th>
                                        <th>Cost Price</th>
                                        <th>Current Stock</th>
                                        <th>Order QTY</th>
                                        <th>Sub Total</th>
                                        <th>VAT</th>
                                        <th>Total</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRows.length === 0 ? (
                                        <tr style={{ textAlign: "center" }}>
                                            <td colSpan={9}>No product added</td>
                                        </tr>
                                    ) : (
                                        tableRows.map(row => (
                                            <tr key={row.id}>
                                                <td>{row.code} - {row.name} - {row.size}</td>
                                                <input type="hidden" value={row.id} name="product_id[]" />
                                                <input type="hidden" value={row.code} name="code[]" />
                                                <input type="hidden" value={row.name} name="name[]" />
                                                <td style={{width: '80px'}} className="text-center">

                                                    {row.split == 2 ? (
                                                        <select
                                                            
                                                           onChange={(e) => handleOptionChange(row.id, e.target.value)}
                                                            className="form-control"
                                                            name="type[]"
                                                             style={{width: '75px'}}
                                                        >
                                                            <option value="Box">Box</option>
                                                            <option value="Piece">Piece</option>
                                                        </select>
                                                    ) : (
                                                        
                                                        <>
                                                          <input
                                                            type="hidden"
                                                            value={row.split == 0 ? 'Box' : 'Piece'}
                                                            name="type[]"
                                                          />
                                                          {row.split == 0 ? 'Box' : 'Piece'}
                                                        </>
                                                    )}

                                                </td>
                                                <td style={{width: '80px'}}>
                                                    
                                                    <input
                                                        type="number"
                                                        value={row.pdtcost}
                                                        min="0"
                                                        name="cost[]"
                                                        onChange={(e) => handleCostChange(row.id, e.target.value)}
                                                        className="form-control order-cost no-arrows"
                                                        style={{width: '75px', textAlign : 'right'}}
                                                    />
                                                </td>
                                                <td style={{width: '80px'}} className="text-center">{row.stockCount}</td>
                                                <td style={{width: '80px'}}>
                                                    <input
                                                        type="number"
                                                        value={row.order_qty}
                                                        name="order_qty[]"
                                                        min="0"
                                                        onChange={(e) => handleQuantityChange(row.id, e.target.value)}
                                                        className="form-control order-qty no-arrows"
                                                        style={{width: '75px', textAlign : 'center'}}
                                                    />
                                                </td>
                                                <td style={{width: '60px'}} className="text-end">
                                                    {row.subtotal}
                                                </td>
                                                 <td style={{width: '60px'}} className="text-end">
                                                    {row.vat}
                                                </td>
                                                 <td style={{width: '60px'}} className="text-end">
                                                    {row.total}
                                                </td>
                                                <input type="hidden" name="subtotal[]" value={row.subtotal} />
                                                     <input type="hidden" name="vat[]" value={row.vat} />
                                                      <input type="hidden" name="total[]" value={row.total} />
                                                <td style={{width : '3%'}} className="text-center">
                                                    

                                                    <button
                                                        className="btn btn-danger cancel-btn"
                                                       onClick={() => handleCancel(row.id)}

                                                       style={{padding: '0px',fontSize: '19px',color: '#dc3545',background: 'none',border: 'none'}}
                                                    >
                                                    <i class="fa fa-trash-o" ></i>
                                                        
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
	                    </div>
                        </form>

                        <div className="row">
                            <div className="col-12">
                                <label><b>Note</b></label>
                                <textarea
                                    name="note"
                                    cols="40"
                                    rows="10"
                                    className="form-control"
                                    id="ponote"
                                    style={{ marginTop: '10px', height: '100px' }}
                                    value={note}        
                                    onChange={handleChange} 
                                />
                            </div>

                            <div className="col-12" style={{paddingTop: '20px'}}>
                                <div className="col-2">
                                    <button onClick={submitFormData} type="button" class="acceptbtn">Update Purchase Order</button>
                                </div>
                            </div>
                        </div>
	                </div>
			    </div>
			</div>
	      	<Footer />

		</>
	);
}
export default EditPurchasesFrom;