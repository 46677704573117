import jsPDF from 'jspdf';
import 'jspdf-autotable';
import config from "../common/Config";
import axios from 'axios';

const generateUniqueFilename = (baseName, extension) => {
  const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  return `${baseName}_${timestamp}.${extension}`;
};

const generateOrderSummaryPDF = async (orderId) => {
  const accessToken = localStorage.getItem('accessToken');
  const formData = new FormData();
  formData.set('orderId', orderId);
  const apiUrl = `${config.API_URL}/ws-order-history-details`;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');

    return `${day}-${month}-${year}`;  
  };

  try {
    const response = await axios.post(apiUrl, formData, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const data = response.data;
    console.log(data);

    if (!data.success || !data.details.orderDetails || data.details.orderDetails.length === 0) {
      console.error("No order details found in the response.");
      return;  
    }

    const orderDetails = data.details.orderDetails[0];  
    const shopDetails = data.details.shop_details;
    console.log(orderDetails);


    

    const totalstd = data.details.product_details
            .filter(item => item.item_tax != '0.00' )
            .reduce((sum, item) => sum + (parseFloat(item.unit_price) * parseFloat(item.order_qty)), 0);

    const totalzeroGoods = data.details.product_details
            .filter(item => item.item_tax == '0.00' )
            .reduce((sum, item) => sum + (parseFloat(item.unit_price) * parseFloat(item.order_qty)), 0);

    const totalSTDGoods = parseFloat(totalstd) - parseFloat(totalzeroGoods);
    const orderDiscount = parseFloat(data.details.orderDetails[0].order_discount) || 0;
    const orderDiscount1 = parseFloat(data.details.orderDetails[0].order_discount1) || 0;

   /* const totalVAT = data.details.product_details
            .reduce((sum, item) => sum + (item.item_tax ? parseFloat(item.item_tax) : 0), 0);*/

    const totalVAT = data.details.product_details
      .reduce((sum, item) => {
        if (parseFloat(item.tax_rate_percent) !== 0.00) {
          const vatAmount = (parseFloat(item.unit_price) * parseFloat(item.order_qty)) * (parseFloat(item.tax_rate_percent) / 100);
          return sum + vatAmount;
        }
        return sum; 
      }, 0);

    const totalAmount = totalstd + totalzeroGoods + totalVAT - orderDiscount - (totalstd * orderDiscount1 / 100);

    const itemTotal = totalstd + totalzeroGoods;


    const doc = new jsPDF('portrait'); // Set to portrait orientation
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 5;  // 5px padding
    let y = margin;
    const firstColumnWidth = pageWidth * 0.25;  // 30% of the page width
    const secondColumnWidth = pageWidth * 0.75; 
    const H2firstColumnWidth = pageWidth * 0.30; 

    // Add the logo image to the header (make sure the image path is correct)
    const logoUrl = `${config.BASE_URL}/assets/img/ayt_logo.png`; // Path to the logo image
    const logoWidth = 40; // Width of the logo in the PDF (adjust as needed)
    const logoHeight = 15; // Height of the logo (adjust as needed)
    doc.addImage(logoUrl, 'PNG', margin, y, logoWidth, logoHeight); // Adds the logo at the top-left corner
    y += logoHeight + 5; // Adjust y position after adding the logo

    // Now create the other sections of the header
    const headerSections2 = [
      [
        // Add shop details (below logo)
      ],
      [
        { text: `${shopDetails.shop_name}`, size: 13, bold: true },
        { text: `${shopDetails.address}`, size: 8, bold: false },
        { text: `Phone No : ${shopDetails.phone} | Email : ${shopDetails.email}`, size: 8, bold: false },
        { text: `Website : ${shopDetails.website}`, size: 8, bold: false },
        { text: `Company No : ${shopDetails.company_reg_no} | VAT No : ${shopDetails.vat_no ? shopDetails.vat_no : ''}`, size: 8, bold: false },
      ],

    ];

    const headerSections3 = [
      [

        { text: `${orderDetails.company}`, size: 11, bold: true },
        {
  text: `${orderDetails.address.split(' ').slice(0, Math.ceil(orderDetails.address.split(' ').length / 2)).join(' ')}\n${orderDetails.address.split(' ').slice(Math.ceil(orderDetails.address.split(' ').length / 2)).join(' ')}`,
  size: 8,
  bold: false
  
}, { text: ``, size: 8, bold: false },
        { text: `${orderDetails.city} ,${orderDetails.postal_code}`, size: 8, bold: false },
        { text: `Phone No : ${orderDetails.phone}`, size: 8, bold: false },
        { text: `Email Id : ${orderDetails.email}`, size: 8, bold: false },
      ],
      [
        { text: ``, size: 8, bold: false },
        { text: `Invoice No : ${orderDetails.reference_no}`, size: 8, bold: false },
        { text: `Order Date: ${formatDate(orderDetails.date)}`, size: 8, bold: false },
        { text: `Account No: ${orderDetails.account_no}`, size: 8, bold: false },
      ],
    ];

    const sectionWidth = (pageWidth - margin * 2) / 2; // Adjust for margins

    // Render header sections
    y = 7;
    const renderSections = (sections) => {
      sections.forEach((section, index) => {
        //const colStartX = margin + (index % 3) * sectionWidth;
        const colStartX = margin + (index % 2) * firstColumnWidth;

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, colStartX, y + i * 4); // Reduced space between lines
        });

        if (index % 4 === 3) {
          y += 12; // Reduced spacing after every 4 sections
        }
      });
      y += 25; // Add a little space after the last header section
    };
    const h2renderSections = (sections) => {
      sections.forEach((section, index) => {
        //const colStartX = margin + (index % 3) * sectionWidth;
        const colStartX = margin + (index % 2) * H2firstColumnWidth;

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, colStartX, y + i * 4); // Reduced space between lines
        });

        if (index % 4 === 3) {
          y += 12; // Reduced spacing after every 4 sections
        }
      });
      y += 25; // Add a little space after the last header section
    };

    
    renderSections(headerSections2);
    h2renderSections(headerSections3);
    // Render table columns with customized widths
    const tableColumns = [
      { title: 'Product', dataKey: 'product', size: 6, bold: false },
      { title: 'Order Type', dataKey: 'order_type', size: 6, bold: false },
      { title: 'Order Qty', dataKey: 'qty', size: 6, bold: false },
      { title: 'Unit Price', dataKey: 'unit_price', size: 6, bold: false },
      { title: 'Discount', dataKey: 'discount', size: 6, bold: false },
      { title: 'VAT', dataKey: 'vat', size: 6, bold: false },
      { title: 'Net Amount', dataKey: 'net_amount', size: 6, bold: false },
    ];

    // Calculate the table widths
     const totalWidth = pageWidth - 2 * margin;
    const productWidth = totalWidth * 0.45;  
    const orderTypeWidth = totalWidth * 0.06;  
    const qtyWidth = totalWidth * 0.06; 
    const unitPriceWidth = totalWidth * 0.12;  
    const discountWidth = totalWidth * 0.08;  
    const vatWidth = totalWidth * 0.11; 
    const netAmountWidth = totalWidth * 0.12; 



    /*const tableBody = data.details.product_details.map((item) => [
      item.product_code + ' - ' + item.product_name, 
      item.order_type,
      parseInt(item.order_qty), 
      '£ ' + parseFloat(item.unit_price).toFixed(2), 
      item.is_promoted, 
      '£ ' + parseFloat(item.item_tax).toFixed(2), 
      '£ ' + parseFloat(item.order_qty * item.unit_price).toFixed(2), 
    ]);*/

    const tableBody = data.details.product_details
      .filter(item => parseFloat(item.order_qty) > 0)
      .map((item) => {
      const unitPrice = parseFloat(item.unit_price);
      const orderQty = parseFloat(item.order_qty);
      const totalPrice = unitPrice * orderQty;
      
      let vat = 0;
      if (parseFloat(item.tax_rate_percent) !== 0.00) {
        vat = (totalPrice * parseFloat(item.tax_rate_percent)) / 100;
      }

      return [
        item.product_code + ' - ' + item.product_name, 
        item.order_type,
        parseInt(item.order_qty), 
        '£ ' + unitPrice.toFixed(2), 
        item.is_promoted, 
        vat > 0 ? '£ ' + vat.toFixed(2) : '£ 0.00',  // Display VAT if applicable
        '£ ' + totalPrice.toFixed(2), 
      ];
    });


    // Add an empty row with totals
    tableBody.push([
      { content: 'Item Total:', colSpan: 6, styles: { halign: 'right' } },
      {content : `£ ${itemTotal.toFixed(2)}`} 
      
    ]);

    tableBody.push([
      { content: `NON VAT Goods - £ ${totalzeroGoods.toFixed(2)}  | VAT Goods - £ ${totalstd.toFixed(2)}`, colSpan: 5, styles: { halign: 'left' } }, 
      { content: 'Total Vat:',  styles: { halign: 'right' } }, 
      {content : `£ ${totalVAT.toFixed(2)}` }
         
    ]);


    tableBody.push([
      { content: 'Order Discount(0%):', colSpan: 6, styles: { halign: 'right' } }, 
      orderDiscount.toFixed(2)
    ]);

    tableBody.push([
      { content: 'Total Amount:', colSpan: 6, styles: { halign: 'right' } }, 
      {content : `£ ${totalAmount.toFixed(2)}` }
    ]);

   

    doc.autoTable({
  startY: y,
  head: [tableColumns.map(col => col.title)],
  body: tableBody,
  theme: 'grid',
  headStyles: {
    fillColor: '#f8f9fa',
    textColor: '#404040',
    fontSize: 8,  // Ensure font size 6 for table header
    valign: 'middle', // Center-align vertically if needed
  },
  bodyStyles: {
    fontSize: 8,  // Ensure font size 6 for table body rows
    valign: 'middle', // Center-align vertically in rows
  },
  margin: { top: 0, left: margin, right: margin },

  columnStyles: {
    0: { cellWidth: productWidth },
    1: { cellWidth: orderTypeWidth },
    2: { cellWidth: qtyWidth },
    3: { cellWidth: unitPriceWidth },
    4: { cellWidth: discountWidth },
    5: { cellWidth: vatWidth },
    6: { cellWidth: netAmountWidth },
    1: { halign: 'center' },
    2: { halign: 'center' },
    3: { halign: 'right' },
    4: { halign: 'center' },
    5: { halign: 'right' },
    6: { halign: 'right' },
  },
  
});


    y = doc.autoTable.previous.finalY + 12; // Y position after table

    const addFooterSection = (footerSection) => {
      footerSection.forEach((section, index) => {
        const colStartX = margin + (index % 4) * sectionWidth;

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, colStartX, y + i * 5);
        });

        if (index % 4 === 3) {
          y += 20; // Move down after every 4 sections
        }
      });
      y += 20; // Add space after each footer section
    };

    const addFooterWithSpaceCheck = (footerSection) => {
      const remainingSpace = pageHeight - y - margin; // Remaining space on the page
      const requiredSpace = footerSection.length * 5 + 20; // Space needed

      if (remainingSpace < requiredSpace) {
        doc.addPage(); // Add a new page if not enough space
        y = margin; // Reset Y position for new page
      }

      addFooterSection(footerSection);
    };

    const footerSections2 = [
      [
        { text: 'Good Received & Checked By : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ No of Boxes Received : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _', size: 8, bold: false },
        { text: '', size: 8, bold: false },
        { text: 'Amount Paid : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ Cash / Cheque Collected By : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _', size: 8, bold: false },
        { text: 'Bank Details', size: 9, bold: false },
        { text: `A/C No : ${shopDetails.bank_ac}  |  Sort Code : ${shopDetails.sort_code}`, size: 9, bold: false },
        { text: 'Terms -', size: 8, bold: false },
        { text: `1 - Once delivery is received ${shopDetails.shop_name} is not responsible for any damage or loss `, size: 8, bold: false },
      ]
    ];

    addFooterWithSpaceCheck(footerSections2);

    const filename = generateUniqueFilename('Invoice', 'pdf');
    //doc.save(filename);

    doc.autoPrint();
    window.open(doc.output('bloburl'));

  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};

export default generateOrderSummaryPDF;
