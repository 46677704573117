import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import FlashMessage from "../FlashMessage";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { Link } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import config from "../common/Config";

const DeliveredInvoice = () => {
  const accessToken = localStorage.getItem("accessToken");
  const TableRef = useRef(null);

  let dateRange = '';
  useEffect(() => {
    if (accessToken === null) {
      window.location.href = "/react/login";
      return;
    }
    initializeTable();
  }, []);

  const s = localStorage.getItem('startDate');
  const e = localStorage.getItem('endDate');

  const initializeTable = () => {
    const $table = $(TableRef.current);
    if ($.fn.DataTable.isDataTable($table)) {
        $table.DataTable().destroy();
    }
  
    let i = 0;
    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
          url: `${config.API_URL}/get_deliveredInvoice`,
          type: 'POST',
          headers: {
             authorization : `Bearer ${accessToken}`,
          },
          data: function (d) {
              return $.extend({}, d, {
                  draw : d.draw,
                  start : d.start,
                  length : d.length,
                  start_date : s ? s : '',
                  end_date : e ? e : ''
              });
          },
          dataSrc: function (json) {
            return Array.isArray(json.data) ? json.data : [];
          }
      },
      language: {
          paginate: {
              first: 'First',
              last: 'Last',
              next: 'Next',
              previous: 'Previous',
          },
          search: 'Search:',
      },
      columns: [
        { data: "date",
          render: function(data, type, row){
            const [year, month, day] = row.date.split("-");
            const formattedDate = `${day}-${month}-${year}`;  
            return formattedDate;
          },
          createdCell: function (td) {
            $(td).css("text-align", "center");
          }
         },
         {
          data: "reference_no",
          render: function (data, type, row) 
          {
            return (
              `
                ${row.reference_no}
              `
            );
          },
          createdCell: function (td) 
          {
            $(td).css("text-align", "center");
          }
        },        
        { data: "customer" },
        { data: "grand_total" },
        { data: "spc" },
        { data: "prev_due" },
        { data: "route_number",
          createdCell: function (td) {
            $(td).css("text-align", "center");
          }
         },
        { data: "name" },
      ],
      columnDefs: [
        {
          orderable: false, 
          targets: '_all'
        },
      ],
    
      pageLength: 10,
    });
  };

  const formatDate = (date) => {
    if (!date) return "0";
    
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  };

  // Function to get the first and last dates of the current month
  const getCurrentMonthDates = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth(); // 0-indexed month (January is 0)
    
    // First day of the current month
    const startOfMonth = new Date(year, month, 1);
    const startDay = String(startOfMonth.getDate()).padStart(2, "0");
    const startMonth = String(startOfMonth.getMonth() + 1).padStart(2, "0");
    
    // Last day of the current month
    const endOfMonth = new Date(year, month + 1, 0); // 0 will give the last day of the previous month
    const endDay = String(endOfMonth.getDate()).padStart(2, "0");
    const endMonth = String(endOfMonth.getMonth() + 1).padStart(2, "0");
    
    // Format both start and end dates as dd-mm-yyyy
    const startDateFormatted = `${startDay}-${startMonth}-${year}`;
    const endDateFormatted = `${endDay}-${endMonth}-${year}`;
    
    return { startDateFormatted, endDateFormatted };
  };

  const { startDateFormatted, endDateFormatted } = getCurrentMonthDates();
  



  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <Link to="#">Sales</Link>
              <Link to="#">Delivered Invoice</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container"> 
        <div className="container-fluid">
        <div className="dashdate-area">
            From: {s ? formatDate(s) : startDateFormatted} &nbsp;
            To: {e ? formatDate(e) : endDateFormatted}
          </div>
          <div  className="total-manifests-area dashboard"   style={{ position: "relative" }}    >
            <div  className="verical-scrl-wrapper common-table autoheight-table"   id="style-3"  >
              <table className="table table-bordered resizable" id="neworderTable" ref={TableRef}>
                <thead>
                  <tr>
                    <th style={{textAlign:'center'}}>Order Date</th>
                    <th style={{textAlign:'center'}}>Order Id</th>
                    <th style={{textAlign:'center'}}>Customers (A/C No)</th>
                    <th style={{textAlign:'center'}}>Order Amt</th>
                    <th style={{textAlign:'center'}}>Rep Cash</th>
                    <th style={{textAlign:'center'}}>Previous Dues</th>
                    <th style={{textAlign:'center'}}>Route</th>
                    <th style={{textAlign:'center'}}>Order By</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DeliveredInvoice;
