import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import FlashMessage from "../FlashMessage";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { Link } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import config from "../common/Config";
import { DateRangePicker } from "rsuite";
import { format } from "date-fns";

const OrderAccepted = () => {
  const accessToken = localStorage.getItem("accessToken");
  const [dates, setDates] = useState([null, null]);
  const TableRef = useRef(null);
  useEffect(() => {
    if (accessToken === null) {
      window.location.href = "/react/login";
      return;
    }
    initializeTable();
  }, []);

  const handleSubmit = () => {
    initializeTable(); 
  };

  const handleDateChange = (value) => {
    setDates(value);
    if (value && value[0] && value[1]) {
       s = value[0] ? format(value[0], 'yyyy-MM-dd') : '';
       e = value[1] ? format(value[1], 'yyyy-MM-dd') : '';  
      }
    };


  const s = localStorage.getItem('startDate');
  const e = localStorage.getItem('endDate');

  const initializeTable = () => {
    const $table = $(TableRef.current);
    if ($.fn.DataTable.isDataTable($table)) {
        $table.DataTable().destroy();
    }
  
    let i = 0;
    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
          url: `${config.API_URL}/get_order_accepted`,
          type: 'POST',
          headers: {
             authorization : `Bearer ${accessToken}`,
          },
          data: function (d) {
              return $.extend({}, d, {
                  draw : d.draw,
                  start : d.start,
                  length : d.length,
                  start_date : s ? s : '',
                  end_date : e ? e : ''
              });
          },
          dataSrc: function (json) {
            return Array.isArray(json.data) ? json.data : [];
          }
      },
      language: {
          paginate: {
              first: 'First',
              last: 'Last',
              next: 'Next',
              previous: 'Previous',
          },
          search: 'Search:',
      },
      columns: [
        { data: "date",
          render: function(data, type, row){
            const [year, month, day] = row.date.split("-");
            const formattedDate = `${day}-${month}-${year}`;  
            return formattedDate;
          },
          createdCell: function (td) {
            $(td).css("text-align", "center");
          }
         },
         {
          data: "reference_no",
          render: function (data, type, row) 
          {
            return (
              `<a href="${config.BASE_URL}/sales/edit_invoice/${row.ids}">
                ${row.reference_no}
              </a>`
            );
          },
          createdCell: function (td) 
          {
            $(td).css("text-align", "center");
          }
        },        
        { data: "customer" },
        { data: "postal_code",
          createdCell: function (td) {
            $(td).css("text-align", "center");
          } },
        { data: "grand_total" },
        { data: "spc" },
        { data: "prev_due" },
        { data: "route_number",
          createdCell: function (td) {
            $(td).css("text-align", "center");
          }
         },
        { data: "name" },
      ],
      columnDefs: [
        {
          orderable: false, 
          targets: '_all'
        },
      ],
    
      pageLength: 10,
    });
  };

  const formatDate = (date) => {
    if (!date) return "0";
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  };

  const getCurrentMonthDate = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); 
    const year = now.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const startDateFormatted = getCurrentMonthDate();
  
  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <Link to="#">Sales</Link>
              <Link to="#">Order accepted</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
        <DateRangePicker
                                value={dates}
                                onChange={handleDateChange}
                                format="dd/MM/yyyy" // Ensure the calendar uses dd/MM/yyyy format
                                character=" - "
                                className="datepkr"
                              />
                            <button
                              type="button"
                              id="submit"
                              className="multisubmit"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
        <div className="dashdate-area">
            From: {s ? formatDate(s) : startDateFormatted} &nbsp;
            To: {e ? formatDate(e) : startDateFormatted}
          </div>
          <div
            className="total-manifests-area dashboard"
            style={{ position: "relative" }}
          >
            <div
              className="verical-scrl-wrapper common-table autoheight-table"
              id="style-3"
            >
              <table className="table table-bordered resizable" id="neworderTable" ref={TableRef}>
                <thead>
                  <tr>
                    <th style={{textAlign:'center'}}>Order Date</th>
                    <th style={{textAlign:'center'}}>Order Id</th>
                    <th style={{textAlign:'center'}}>Customers (A/C No)</th>
                    <th style={{textAlign:'center'}}>Postal Code</th>
                    <th style={{textAlign:'center'}}>Order Amt</th>
                    <th style={{textAlign:'center'}}>Rep Cash</th>
                    <th style={{textAlign:'center'}}>Previous Dues</th>
                    <th style={{textAlign:'center'}}>Route</th>
                    <th style={{textAlign:'center'}}>Order By</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OrderAccepted;
