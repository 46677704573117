import jsPDF from 'jspdf';
import 'jspdf-autotable';
import config from "../common/Config";

const generateUniqueFilename = (baseName, extension) => {
  const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  return `${baseName}_${timestamp}.${extension}`;
};

const generatePickerListPDF = async (picklistNo) => {
  const accessToken = localStorage.getItem('accessToken');
  const firstName = localStorage.getItem('first_name');
  const lastName = localStorage.getItem('last_name');

  const formData = new FormData();
  formData.set('picklist_number', picklistNo);
  const apiUrl = `${config.API_URL}/get_view_picklist`;

  const todayDate = new Date();
  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based) and pad
    const year = date.getFullYear(); // Get full year

    return `${day}-${month}-${year}`; // Return formatted date
  }

  function formatNewDate(dateString) {
    const date = new Date(dateString); // Parse the date string
    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based) and pad
    const year = date.getFullYear(); // Get full year

    return `${day}-${month}-${year}`; // Return formatted date
  }

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Picker List');
    }

    const data = await response.json();
    console.log(data);

    const packingDetails = Array.isArray(data.data.packing_details) ? data.data.packing_details : [];
    const totalQty = packingDetails.reduce((acc, item) => acc + parseInt(item.quantity, 10), 0);

    const doc = new jsPDF('p'); // Set to portrait orientation
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    let y = margin;

    // Header sections
    const headerSections1 = [
      [
        { text: `Customer Name : ${data.data.reference_no[0]?.customer}`, size: 8, bold: true },
        { text: `Order Number : ${data.data.reference_no[0]?.reference_no}`, size: 8, bold: false },
        { text: `Account Number : ${data.data.reference_no[0]?.accound_no}`, size: 8, bold: false },
        { text: `Route Number : ${data.data.reference_no[0]?.route_number}`, size: 8, bold: false },
      ],
      [
        { text: `Picking List : ${data.data.picklist_number}`, size: 8, bold: true },
        { text: `Delivery Date : ${formatNewDate(data.data.reference_no[0]?.deliverydate)}`, size: 8, bold: false },
        { text: `Print By: ${firstName} ${lastName}`, size: 8, bold: false },
        { text: `Print Date : ${formatDate(todayDate)}`, size: 8, bold: false },
      ],
    ];

    // Render header sections
    const renderSections = (sections) => {
      sections.forEach((section, index) => {
        const colStartX = margin + (index % 2) * (pageWidth - margin * 2) / 2;

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, colStartX, y + i * 4); // Reduced space between lines
        });

        if (index % 4 === 3) {
          y += 12; // Reduced spacing after every 4 sections
        }
      });
      y += 15; // Add a little space after the last header section
    };

    renderSections(headerSections1);

    // Render first table (Packing Details)
    const packingColumns = [
      { title: 'Product Code', dataKey: 'code', size: 8, bold: false },
      { title: 'Product Name', dataKey: 'name', size: 8, bold: false },
      { title: 'Product Type', dataKey: 'order_type', size: 8, bold: false },
      { title: 'Quantity', dataKey: 'quantity', size: 8, bold: false },
    ];

    const packingTableBody = packingDetails.map((item) => [
      item.code,
      item.name,
      item.order_type,
      parseInt(item.quantity),
    ]);

    // Add an empty row with totals
    packingTableBody.push(['', '', 'Total : ', totalQty.toString()]);

    // Render the packing details table with no padding and border
    doc.autoTable({
      startY: y,
      head: [packingColumns.map(col => col.title)],
      body: packingTableBody,
      theme: 'plain', // Use plain theme for no grid
      headStyles: {
        fillColor: null, // No background color
        textColor: '#000000', // Text color
        fontSize: 10, // Adjust as necessary
      },
      styles: {
        cellPadding: 1, // Remove padding
        lineWidth: 0, // Remove borders
        fontSize: 8, // Set body font size to 6
      },
    });

    y = doc.autoTable.previous.finalY + 12; // Y position after table

    // Add second table (Account Comments)
    const accountComments = data.data.accound_no || [];
    const commentsColumns = [
      { title: 'A/c No', dataKey: 'accound_no', size: 8, bold: false },
      { title: 'Comment', dataKey: 'comment', size: 8, bold: false },
      { title: 'Comment By', dataKey: 'name', size: 8, bold: false },
    ];

    const commentsTableBody = accountComments.map(item => {
      const comments = [];
      if (item.staff_note) {
        comments.push({ accound_no: item.accound_no, comment: item.staff_note, name: item.name });
      }
      if (item.note) {
        comments.push({ accound_no: item.accound_no, comment: item.note, name: item.name });
      }
      return comments;
    }).flat(); // Flatten the array of comments

    // Render the account comments table with no padding and border
    doc.autoTable({
      startY: y,
      head: [commentsColumns.map(col => col.title)],
      body: commentsTableBody.map(item => [item.accound_no, item.comment, item.name]),
      theme: 'plain', // Use plain theme for no grid
      headStyles: {
        fillColor: null, // No background color
        textColor: '#000000', // Text color
        fontSize: 8, // Adjust as necessary
      },
      styles: {
        cellPadding: 1, // Remove padding
        lineWidth: 0, // Remove borders
        fontSize: 8, // Set body font size to 6
      },
    });

    const uniqueFilename = generateUniqueFilename('PickerList', 'pdf');

    // Save the document with the unique filename
    //doc.save(uniqueFilename);

    doc.autoPrint();
    window.open(doc.output('bloburl'));

  } catch (error) {
    console.error('Error generating PDF:', error);
  }
}

export default generatePickerListPDF;
