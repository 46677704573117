import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "./common/Config";

const ClosedTripSummary= () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [completeTrips, setCompleteTrips] = useState([]); // State to store statistics data
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const accessToken = localStorage.getItem('accessToken'); 

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('role', selectedRole);

    const apiUrl = `${config.API_URL}/get_complete_trip_list`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch new sales data');
      }

      const data = await response.json();
      setCompleteTrips(data.data);
    } catch (error) {
      console.error('Error fetching new sales data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, selectedRole]);

  useEffect(() => {
    const table = $('#completeTripsTable').DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: true,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
      data: completeTrips,
      columns: [
        { data: null },
        { data: 'date' },
        { data: 'trip_number',
        render: function (data, type, row) {
            return '<a href="admin/delivery/trip_list/closed_trip_summary/' + row.id + '">' + row.trip_number + '</a>';
        }
    },
    { data: 'driver' },
    { data: 'vehicle' , className: "text-center" },
    // { data: 'status', className: "text-center" },
    //{ data: 'sale_status' },
        
    { 
      data: null,
      render: function (data, type, row) {                
            
            return '<span class="btn btn-primary badge " >'  + row.status + '</span>';
         
      }
  },
    ],
    columnDefs: [
    { 
        targets: [0,1,2,3,4,5], 
        sortable: false 
    }
    ],
    drawCallback: function (settings) {
        var api = this.api();
        var startIndex = api.page.info().start;
        api.column(0, { page: 'current' }).nodes().each(function (cell, i) {
            cell.innerHTML = startIndex + i + 1;
        });
    }
    });
    return () => {
      table.destroy();
    };
  }, [completeTrips]);
  return (
    <div>
      <Header />

      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="Dashboard">Dashboard</a> &gt; <span className="last-crumb">Close Trip Summary</span>
            </div>
          </div>
        </div>
      </div>

      <div class="main-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-12 col-sm-12">
        <div class="show-addprod-area dashboard">
          <div class="complete-summery-area">
            <div class="title"> <span>Complete Trip Summary </span>
              <ul>
                <li>Date - 22-07-2024</li>
                <li>Driver - kc</li>
                <li>Vehicle - FIAT GN17VZS</li>
                <li><a href="#">Summary Report</a></li>
              </ul>
            </div>
          </div>
          <div class=" float:left; width:100%;">
            <div class="row">
              <div class="col-12 col-lg-3 col-sm-6">
                <div class="sumery-delivery-box">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0" class="table table-bordered resizable">
                    <tbody>
                      <tr>
                        <td>Orders Delivered</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>Orders Undelivered</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>Cash Collected</td>
                        <td>£150.00</td>
                      </tr>
                      <tr>
                        <td>Cheque (0)</td>
                        <td>£0.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 col-lg-3 col-sm-6">
				<div class="sumery-delivery-box">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0" class="table table-bordered resizable">
                    <thead>
                      <tr>
                        <th>Return Goods	</th>
                        <th>Box	</th>
						<th>Pcs</th>
                      </tr>
                      
                    </thead>
					 <tbody>
                      <tr>
                        <td>Partial Undeliv.</td>
                        <td>0</td>
						<td>0</td>
                      </tr>
					<tr>
                        <td>Undelivered</td>
                        <td>12</td>
						<td>38</td>
                    </tr>
						 
					<tr>
                        <td>Return</td>
                        <td>0</td>
						<td>0</td>
                    </tr>
					</tbody> 
					  
                  </table>
                </div>
			  </div>
              <div class="col-12 col-lg-3 col-sm-6">
				<div class="sumery-delivery-box">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0" class="table table-bordered resizable">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Box	</th>
						<th>Pcs</th>
                      </tr>
                      
                    </thead>
					 <tbody>
                      <tr>
                        <td>Total Products	</td>
                        <td>18</td>
						<td>38</td>
                      </tr>
					<tr>
                        <td>Delivered</td>
                        <td>6</td>
						<td>0</td>
                    </tr>
						 
					<tr>
                        <td>Undelivered</td>
                        <td>12</td>
						<td>38</td>
                    </tr>
					</tbody> 
					  
                  </table>
                </div>
			  </div>
              <div class="col-12 col-lg-3 col-sm-6">
				<div class="sumery-delivery-box">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0" class="table table-bordered resizable">
                    <tbody>
                      <tr>
                        <td>Delivered AMT</td>
                        <td>£80.00</td>
                      </tr>
                      <tr>
                        <td>Undeliverd AMT</td>
                        <td>£1063.16</td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>£1143.16</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
			</div>
				
				
            </div>
            
			  
          </div>
        </div>
		  
		  
			  <div class="total-manifests-area">
				<p><b>Period From :</b> 01-05-2024 To : 28-06-2024</p>
			<div class="verical-scrl-wrapper common-table autoheight-table" id="style-3"> 
			 <table class="table table-bordered resizable">
			 	<thead><tr>
				 	<th>#</th>
					<th>Customers (A/C No)</th>
					<th>Manifest No	</th>
					<th>Order ID	</th>
					<th>Post code	</th>
					<th>Route	</th>
					<th>Status	</th>
					<th>Action
</th>
				 </tr>
					</thead>
				 <tbody>
				<tr>
				 	 <td>01</td>
					 <td>Vanilla Stores (05)</td>
					 <td>MANIFEST00106</td>
					 <td>27A07327</td>
					 <td>E1 7DR</td>
					 <td>N1</td>
					 <td>Undelivered</td>
					 <td>&nbsp;</td>
				 </tr>
					
					<tr>
				 	 <td>02</td>
					 <td>TK Trading Co. (01)	</td>
					 <td>MANIFEST00106</td>
					 <td>27A07364</td>
					 <td>181397</td>
					 <td>JR1</td>
					 <td>Delivered	</td>
					 <td>&nbsp;</td>
				 </tr>
				 
					 <tr>
				 	 <td>01</td>
					 <td>Vanilla Stores (05)</td>
					 <td>MANIFEST00106</td>
					 <td>27A07327</td>
					 <td>E1 7DR</td>
					 <td>N1</td>
					 <td>Undelivered</td>
					 <td>&nbsp;</td>
				 </tr>
					
					<tr>
				 	 <td>02</td>
					 <td>TK Trading Co. (01)	</td>
					 <td>MANIFEST00106</td>
					 <td>27A07364</td>
					 <td>181397</td>
					 <td>JR1</td>
					 <td>Delivered	</td>
					 <td>&nbsp;</td>
				 </tr>
					 
					 <tr>
				 	 <td>01</td>
					 <td>Vanilla Stores (05)</td>
					 <td>MANIFEST00106</td>
					 <td>27A07327</td>
					 <td>E1 7DR</td>
					 <td>N1</td>
					 <td>Undelivered</td>
					 <td>&nbsp;</td>
				 </tr>
					
					<tr>
				 	 <td>02</td>
					 <td>TK Trading Co. (01)	</td>
					 <td>MANIFEST00106</td>
					 <td>27A07364</td>
					 <td>181397</td>
					 <td>JR1</td>
					 <td>Delivered	</td>
					 <td>&nbsp;</td>
				 </tr>
					 
					 <tr>
				 	 <td>01</td>
					 <td>Vanilla Stores (05)</td>
					 <td>MANIFEST00106</td>
					 <td>27A07327</td>
					 <td>E1 7DR</td>
					 <td>N1</td>
					 <td>Undelivered</td>
					 <td>&nbsp;</td>
				 </tr>
					
					<tr>
				 	 <td>02</td>
					 <td>TK Trading Co. (01)	</td>
					 <td>MANIFEST00106</td>
					 <td>27A07364</td>
					 <td>181397</td>
					 <td>JR1</td>
					 <td>Delivered	</td>
					 <td>&nbsp;</td>
				 </tr>
					 
					 <tr>
				 	 <td>01</td>
					 <td>Vanilla Stores (05)</td>
					 <td>MANIFEST00106</td>
					 <td>27A07327</td>
					 <td>E1 7DR</td>
					 <td>N1</td>
					 <td>Undelivered</td>
					 <td>&nbsp;</td>
				 </tr>
					
					<tr>
				 	 <td>02</td>
					 <td>TK Trading Co. (01)	</td>
					 <td>MANIFEST00106</td>
					 <td>27A07364</td>
					 <td>181397</td>
					 <td>JR1</td>
					 <td>Delivered	</td>
					 <td>&nbsp;</td>
				 </tr>
					 
					 <tr>
				 	 <td>01</td>
					 <td>Vanilla Stores (05)</td>
					 <td>MANIFEST00106</td>
					 <td>27A07327</td>
					 <td>E1 7DR</td>
					 <td>N1</td>
					 <td>Undelivered</td>
					 <td>&nbsp;</td>
				 </tr>
					
					<tr>
				 	 <td>02</td>
					 <td>TK Trading Co. (01)	</td>
					 <td>MANIFEST00106</td>
					 <td>27A07364</td>
					 <td>181397</td>
					 <td>JR1</td>
					 <td>Delivered	</td>
					 <td>&nbsp;</td>
				 </tr>
					 
					 <tr>
				 	 <td>01</td>
					 <td>Vanilla Stores (05)</td>
					 <td>MANIFEST00106</td>
					 <td>27A07327</td>
					 <td>E1 7DR</td>
					 <td>N1</td>
					 <td>Undelivered</td>
					 <td>&nbsp;</td>
				 </tr>
					
					<tr>
				 	 <td>02</td>
					 <td>TK Trading Co. (01)	</td>
					 <td>MANIFEST00106</td>
					 <td>27A07364</td>
					 <td>181397</td>
					 <td>JR1</td>
					 <td>Delivered	</td>
					 <td>&nbsp;</td>
				 </tr>
					 <tr>
				 	 <td>01</td>
					 <td>Vanilla Stores (05)</td>
					 <td>MANIFEST00106</td>
					 <td>27A07327</td>
					 <td>E1 7DR</td>
					 <td>N1</td>
					 <td>Undelivered</td>
					 <td>&nbsp;</td>
				 </tr>
					
					<tr>
				 	 <td>02</td>
					 <td>TK Trading Co. (01)	</td>
					 <td>MANIFEST00106</td>
					 <td>27A07364</td>
					 <td>181397</td>
					 <td>JR1</td>
					 <td>Delivered	</td>
					 <td>&nbsp;</td>
				 </tr>
				
			 </tbody></table>
			 </div>
			 
			 
			 
		</div>
      </div>
    </div>
  </div>
</div>
      <Footer />
    </div>
  );
};

export default ClosedTripSummary;
