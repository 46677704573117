import jsPDF from 'jspdf';
import 'jspdf-autotable';
import config from "../common/Config";
import axios from 'axios';

const generateUniqueFilename = (baseName, extension) => {
  const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  return `${baseName}_${timestamp}.${extension}`;
};

const generateSummaryPDF = async (orderId) => {
  const accessToken = localStorage.getItem('accessToken');
  const formData = new FormData();
  formData.set('orderId', orderId);
  const apiUrl = `${config.API_URL}/ws-proforma-invoice`;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);  // Convert timestamp string to Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;  // Return formatted date as "YYYY-MM-DD"
  };

  try {
    const response = await axios.post(apiUrl, formData, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const data = response.data;
    console.log(data);

    if (!data.success || !data.details.orderDetails || data.details.orderDetails.length === 0) {
      console.error("No order details found in the response.");
      return;  // Return early if order details are not available
    }

    const orderDetails = data.details.orderDetails[0];  
    const shopDetails = data.details.shop_details;
    console.log(orderDetails);

    /*const totalSTDGoods = parseFloat(orderDetails.grand_total) - parseFloat(orderDetails.total_tax);
    const totalVAT = parseFloat(orderDetails.total_tax);
    const orderDiscount = parseFloat(orderDetails.order_discount);
    const totalAmount = parseFloat(orderDetails.grand_total);*/


    const totalstd = data.details.product_details
    .filter(item => item.item_tax != '0.00' && item.item_tax != null)
    .reduce((sum, item) => sum + (parseFloat(item.unit_price) * parseFloat(item.order_qty)), 0);

    const box_qty = data.details.product_details
    .filter(item => item.quantity != '0.00' && item.quantity != null && item.order_type === 'box')
    .reduce((sum, item) => sum + (parseFloat(item.quantity)), 0);

    const piece_qty = data.details.product_details
    .filter(item => item.quantity != '0.00' && item.quantity != null && item.order_type === 'piece')
    .reduce((sum, item) => sum + (parseFloat(item.quantity)), 0);




    const totalzeroGoods = data.details.product_details
    .filter(item => item.item_tax == '0.00' || item.item_tax === null)
    .reduce((sum, item) => sum + (parseFloat(item.unit_price) * parseFloat(item.order_qty)), 0);

    const totalSTDGoods = parseFloat(totalstd) - parseFloat(totalzeroGoods);
    const orderDiscount = parseFloat(data.details.orderDetails[0].order_discount) || 0;
    const orderDiscount1 = parseFloat(data.details.orderDetails[0].order_discount) || 0;

    const totalVAT = data.details.product_details
    .reduce((sum, item) => sum + (item.item_tax ? parseFloat(item.item_tax) : 0), 0);

    const totalAmount = totalstd + totalzeroGoods + totalVAT - orderDiscount - (totalstd * orderDiscount1 / 100);

    const itemTotal = totalstd + totalzeroGoods;

    const doc = new jsPDF('portrait'); // Set to portrait orientation
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 5;  // 5px padding
    let y = margin;
    const firstColumnWidth = pageWidth * 0.25;  // 30% of the page width
    const secondColumnWidth = pageWidth * 0.75; 
    //const H2firstColumnWidth = pageWidth * 0.45; 

    const H2firstColumnWidth = pageWidth * 0.45;  // 45% of the page width
const H2secondColumnWidth = pageWidth * 0.25; // 27.5% of the page width
const H2thirdColumnWidth = pageWidth * 0.25;  // 27.5% of the page width


    // Add the logo image to the header (make sure the image path is correct)
    const logoUrl = `${config.BASE_URL}/assets/img/logo_img.png`; // Path to the logo image
   // const logoUrl = `${config.BASE_URL}/logo_img.png`; // Path to the logo image
    const logoWidth = 40; // Width of the logo in the PDF (adjust as needed)
    const logoHeight = 15; // Height of the logo (adjust as needed)
    doc.addImage(logoUrl, 'PNG', margin, y, logoWidth, logoHeight); // Adds the logo at the top-left corner
    y += logoHeight + 5; // Adjust y position after adding the logo

    // Now create the other sections of the header
    const boldTxt = '<b>';
    const boldTxtClose = '</b>';
    const headerSections2 = [
    [
        // Add shop details (below logo)
        ],
        [
        { text: `${shopDetails.shop_name}`, size: 13, bold: true },
         { text: `${shopDetails.address.split(',')[0]}`, size: 8, bold: false }, // First line of address
    { text: `${shopDetails.address.split(',')[1]}`, size: 8, bold: false }, // Second line of address
    { text: `Phone No : ${shopDetails.phone} | Email : ${shopDetails.email}`, size: 8, bold: false },
    { text: `Website : ${shopDetails.website}`, size: 8, bold: false },
    { text: `Company No : ${shopDetails.company_reg_no} | VAT No : ${shopDetails.vat_no}`, size: 8, bold: false },
    ],
    // [
    // { text: `PLEASE PHONE THE OFFICE TO `, size: 8, bold: false,align: 'right'},
    // { text: `PAY BY CREDIT/DEBIT CARD `, size: 8, bold: false ,align: 'right' },
    // ]
    
    ];





    const headerSections3 = [
    [
    { text: `Customer Details`, size: 8, bold: true },
    { text: `${orderDetails.name}`, size: 8, bold: true },
    { text: `${orderDetails.address}`, size: 8, bold: false },
    { text: `${orderDetails.postal_code}`, size: 8, bold: false },
    { text: `Phone No : ${orderDetails.phone}`, size: 8, bold: false },
    { text: `Email Id : ${orderDetails.email}`, size: 8, bold: false },
    ],
    [
   
    { text: `Invoice No : ${orderDetails.reference_no}`, size: 8, bold: false },
    { text: `Invoice Date: ${orderDetails.date}`, size: 8, bold: false },
    { text: `A/C No: ${orderDetails.account_no}`, size: 8, bold: false },
    { text: `Amount Due:`, size: 8, bold: false },
    { text: `Amount Outstanding :`, size: 8, bold: false },
    ],
    [
    { text: `Bank Details`, size: 8, bold: true },
    { text: ``, size: 8, bold: false },
    { text: `A/C No : ${shopDetails.bank_ac}`, size: 8, bold: false},
    { text: `Sort Code: ${shopDetails.sort_code}`, size: 8, bold: false},

    ],


    ];




    const sectionWidth = (pageWidth - margin * 2) / 2; // Adjust for margins

    // Render header sections
    y = 7;
    const renderSections = (sections) => {
      sections.forEach((section, index) => {
        //const colStartX = margin + (index % 3) * sectionWidth;
        const colStartX = margin + (index % 3) * firstColumnWidth;

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, colStartX, y + i * 4); // Reduced space between lines
        });

        if (index % 4 === 3) {
          y += 12; // Reduced spacing after every 4 sections
        }
      });
      y += 25; // Add a little space after the last header section
    };
renderSections(headerSections2);



/*
const h2renderSections = (sections) => {
  let rowHeight = 0; // To store the maximum height of columns in a row
  let rowStartY = y; // Track the starting Y position for each row of 3 columns
  let columnHeights = []; // To store individual column heights within a row

  sections.forEach((section, index) => {
    // Column X position based on section index (for a 3-column layout)
    const colStartX = margin + (index % 3) * H2firstColumnWidth;
    let sectionHeight = 0; // To track the height of the current section

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');

      // Wrap text if it exceeds column width (maxWidth)
      const maxWidth = H2firstColumnWidth - 5; // 5 is a small margin for text
      const textWidth = doc.getTextWidth(item.text);

      let lines = [];
      if (textWidth > maxWidth) {
        // Split text into multiple lines
        let words = item.text.split(' ');
        let line = '';

        words.forEach((word) => {
          const testLine = line + (line ? ' ' : '') + word;
          if (doc.getTextWidth(testLine) <= maxWidth) {
            line = testLine;
          } else {
            lines.push(line);
            line = word;
          }
        });
        lines.push(line); // Push remaining line
      } else {
        lines.push(item.text); // No wrapping needed, just one line
      }

      // Render each line of text
      lines.forEach((lineText, lineIndex) => {
        const lineHeight = 4; // Adjust vertical spacing for each line
        doc.text(lineText, colStartX + 5, y + 5 + i * lineHeight + lineIndex * lineHeight);
        sectionHeight = y + i + lineIndex-5; // Update section height
      });
    });

    // Store the height of the current section (column height)
    columnHeights.push(sectionHeight);

    // After every 3 columns (3 sections), calculate the maximum height for this row
    if ((index + 1) % 3 === 0) {
      rowHeight = Math.max(...columnHeights); // Get the maximum height in this row
      columnHeights = []; // Reset for the next row

      // Draw borders for all 3 columns in the row
      for (let i = 0; i < 3; i++) {
        const colX = margin + i * H2firstColumnWidth;
        doc.setLineWidth(0.1); // Border line width
        doc.rect(colX, rowStartY - 2, H2firstColumnWidth, rowHeight + 4); // Draw the rectangle for the column with a small padding
      }

      // Move the Y position down for the next row of columns
      y += rowHeight + 12; // Add some space after each row of columns
    }
  });

  y += -3; // Add a little space after all header sections
};*/

const h2renderSections = (sections) => {
  let rowHeight = 0; // To store the maximum height of columns in a row
  let rowStartY = y; // Track the starting Y position for each row of 3 columns
  let columnHeights = []; // To store individual column heights within a row

  sections.forEach((section, index) => {
    // Column X position based on section index (for a 3-column layout)
    let colStartX;
    if (index % 3 === 0) {
      colStartX = margin;
    } else if (index % 3 === 1) {
      colStartX = margin + H2firstColumnWidth;
    } else {
      colStartX = margin + H2firstColumnWidth + H2secondColumnWidth;
    }

    let sectionHeight = 0; // To track the height of the current section

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');

      // Wrap text if it exceeds column width (maxWidth)
      let maxWidth;
      if (index % 3 === 0) {
        maxWidth = H2firstColumnWidth - 5; // First column width
      } else if (index % 3 === 1) {
        maxWidth = H2secondColumnWidth - 5; // Second column width
      } else {
        maxWidth = H2thirdColumnWidth - 5; // Third column width
      }

      const textWidth = doc.getTextWidth(item.text);
      let lines = [];
      if (textWidth > maxWidth) {
        // Split text into multiple lines
        let words = item.text.split(' ');
        let line = '';

        words.forEach((word) => {
          const testLine = line + (line ? ' ' : '') + word;
          if (doc.getTextWidth(testLine) <= maxWidth) {
            line = testLine;
          } else {
            lines.push(line);
            line = word;
          }
        });
        lines.push(line); // Push remaining line
      } else {
        lines.push(item.text); // No wrapping needed, just one line
      }

      // Render each line of text
      lines.forEach((lineText, lineIndex) => {
        const lineHeight = 4; // Adjust vertical spacing for each line
        doc.text(lineText, colStartX + 5, y + 5 + i * lineHeight + lineIndex * lineHeight);
        sectionHeight = y + i + lineIndex - 5; // Update section height
      });
    });

    // Store the height of the current section (column height)
    columnHeights.push(sectionHeight);

    // After every 3 columns (3 sections), calculate the maximum height for this row
    if ((index + 1) % 3 === 0) {
      rowHeight = Math.max(...columnHeights); // Get the maximum height in this row
      columnHeights = []; // Reset for the next row

      // Draw borders for all 3 columns in the row
      let colX = margin;
      for (let i = 0; i < 3; i++) {
        doc.setLineWidth(0.1); // Border line width
        let colWidth = (i === 0) ? H2firstColumnWidth : (i === 1) ? H2secondColumnWidth : H2thirdColumnWidth;
        doc.rect(colX, rowStartY - 2, colWidth, rowHeight + 4); // Draw the rectangle for the column with a small padding
        colX += colWidth; // Update X position for the next column
      }

      // Move the Y position down for the next row of columns
      y += rowHeight + 12; // Add some space after each row of columns
    }
  });

  y += -3; // Add a little space after all header sections
};

// Call the function to render sections with equal height borders
h2renderSections(headerSections3);






    // Render table columns with customized widths
    const tableColumns = [
    { title: 'Product', dataKey: 'product', size: 6, bold: false, halign: 'center' },
    { title: 'Type', dataKey: 'order_type', size: 6, bold: false, halign: 'center' },
    { title: 'Qty', dataKey: 'qty', size: 6, bold: false, halign: 'center' },
    { title: 'Price', dataKey: 'unit_price', size: 6, bold: false, halign: 'center' },
    { title: 'Disc', dataKey: 'discount', size: 6, bold: false, halign: 'center' },
    { title: 'VAT', dataKey: 'vat', size: 6, bold: false, halign: 'center' },
    { title: 'Total', dataKey: 'net_amount', size: 6, bold: false, halign: 'center' },
    ];
    // Calculate the table widths
    const totalWidth = pageWidth - 2 * margin;
    const productWidth = totalWidth * 0.45;  // 45% of the page width for product name/code
    const orderTypeWidth = totalWidth * 0.06;  // 5% for Order Type
    const qtyWidth = totalWidth * 0.06;  // 5% for Qty
    const unitPriceWidth = totalWidth * 0.12;  // 13% for Unit Price
    const discountWidth = totalWidth * 0.08;  // 6% for Discount
    const vatWidth = totalWidth * 0.11;  // 13% for VAT
    const netAmountWidth = totalWidth * 0.12; 

    // Construct table body
    const tableBody = data.details.product_details.map((item) => [
      item.product_code + ' - ' + item.product_name, // Combined product code and name
      item.order_type,
      parseInt(item.order_qty),
      '£'+item.unit_price,
      item.is_promoted,
      '£'+item.item_tax,
      '£'+item.subtotal,
      ]);

    // Add an empty row with totals
    tableBody.push([
      { content: 'Item Total:', colSpan: 6, styles: { halign: 'right' } },
      {content : `£ ${itemTotal.toFixed(2)}`} 
      
      ]);

    tableBody.push([
      { content: `Total Zero Goods - £ ${totalzeroGoods.toFixed(2)}  | Total Std Goods - £ ${totalstd.toFixed(2)}`, colSpan: 5, styles: { halign: 'left' } }, 
      { content: 'VAT Amount:',  styles: { halign: 'right' } }, 
      {content : `£ ${totalVAT.toFixed(2)}` }

      ]);


    tableBody.push([
      { content: 'Order Discount(0%):', colSpan: 6, styles: { halign: 'right' } }, 
      orderDiscount.toFixed(2)
      ]);

    tableBody.push([
      { content:  `Box : ${box_qty} PCs : ${piece_qty} `, colSpan: 5, styles: { halign: 'left' } }, 
      { content: 'Invoice Total:', styles: { halign: 'right' } }, 
      {content : `£ ${totalAmount.toFixed(2)}` }
      ]);



    doc.autoTable({
      startY: y,
      head: [tableColumns.map(col => col.title)],
      body: tableBody,
      theme: 'grid',
      headStyles: {
        fillColor: '#f8f9fa',
        textColor: '#404040',
    fontSize: 8,  // Ensure font size 6 for table header
    valign: 'middle', // Center-align vertically if needed
    halign: 'center', 
  },
  bodyStyles: {
    fontSize: 8,  // Ensure font size 6 for table body rows
    valign: 'middle', // Center-align vertically in rows
  },
  margin: { top: 0, left: margin, right: margin },
  columnStyles: {
    0: { cellWidth: productWidth },
    1: { cellWidth: orderTypeWidth },
    2: { cellWidth: qtyWidth },
    3: { cellWidth: unitPriceWidth },
    4: { cellWidth: discountWidth },
    5: { cellWidth: vatWidth },
    6: { cellWidth: netAmountWidth },
    1: { halign: 'center' },
    2: { halign: 'center' },
    3: { halign: 'right' },
    4: { halign: 'center' },
    5: { halign: 'right' },
    6: { halign: 'right' },
  },
});


    y = doc.autoTable.previous.finalY + 12; // Y position after table

    const addFooterSection = (footerSection) => {
      footerSection.forEach((section, index) => {
        const colStartX = margin + (index % 4) * sectionWidth;

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, colStartX, y + i * 5);
        });

        if (index % 4 === 3) {
          y += 20; // Move down after every 4 sections
        }
      });
      y += 20; // Add space after each footer section
    };

    const addFooterWithSpaceCheck = (footerSection) => {
      const remainingSpace = pageHeight - y - margin; // Remaining space on the page
      const requiredSpace = footerSection.length * 5 + 20; // Space needed

      if (remainingSpace < requiredSpace) {
        doc.addPage(); // Add a new page if not enough space
        y = margin; // Reset Y position for new page
      }

      addFooterSection(footerSection);
    };

    const footerSections2 = [
    [
  
    { text: 'Amount Paid : _ _ _ _ _ _ _ _ _ _Delivery Check & Collected By : _ _ _ _ _ _ _ _ _Amt Paid :_ _ _ _ _ _ _ _ _ _Amt Collected By :_ _ _ _ _ _ _ _ _ _ ', size: 8, bold: false },
    { text: 'Terms -', size: 8, bold: false },
    { text: `1 - Once delivery is received ${shopDetails.shop_name} is not responsible for any damage or loss `, size: 8, bold: false },
    { text: `2 - Goods remain the property of ${shopDetails.shop_name} until invoice fully paid `, size: 8, bold: false },
    ]
    ];

// Add footerSections2 normally, ensuring it is added in the available space
addFooterWithSpaceCheck(footerSections2);

// Define footerSections3 before use
const footerSections3 = [
[
{ text: 'No claims for damages or short delivery will be considered unless made at the time of delivery of goods.', size: 8, bold: false, alignment: 'center' },
{ text: 'These goods are not sold on a sale or return basis.', size: 8, bold: false, alignment: 'center' },
]
];


const remainingSpace = pageHeight - y - 10; // Calculate available space

// Add footer content with direct positioning
if (remainingSpace >= 20) {
  doc.text("No claims for damages or short delivery will be considered unless made at the time of delivery of goods. These goods are not sold on a sale or return basis.", 10, pageHeight - 20);  // Directly specify position
} else {
  doc.addPage();  // Add a new page if not enough space
  doc.text("No claims for damages or short delivery will be considered unless made at the time of delivery of goods. These goods are not sold on a sale or return basis.", 10, pageHeight - 20);  // Directly specify position on new page
}

const filename = generateUniqueFilename('Invoice', 'pdf');
//doc.save(filename);

doc.autoPrint();
    window.open(doc.output('bloburl')); 



} catch (error) {
  console.error('Error generating PDF:', error);
}
};

export default generateSummaryPDF;
