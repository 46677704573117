import React, { useState, useEffect, useRef } from 'react';

import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css'; // Import Select2 CSS
import 'select2'; 
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import Swal from 'sweetalert2';


const CreateNewManifest = () => {

	const [draw, setDraw] = useState(1);
  	const [start, setStart] = useState(0);
  	const [length, setLength] = useState(50);
  	const [driverOptions, setDriverOptions] = useState([]);
  	const [driverValue, setDriverValue] = useState('');
  	const [mIdValue, setMIdValue] = useState('');
  	const accessToken = localStorage.getItem('accessToken');
  	const user_id = localStorage.getItem('userId'); 

  	const [manifestList, setManifestList] = useState([]);

  	const [checkedValues, setCheckedValues] = useState([]);
  	const [checkedItems, setCheckedItems] = useState({});

  	const [routeOptions, setRouteOptions] = useState([]);
    const [salesRoute, setSalesRoute] = useState([]);
    const selectRef = useRef(null);
    const [flashMessage, setFlashMessage] = useState(null);

	

	    const handleCloseMessage = () => {
	    setFlashMessage(null);
	  };

	const handleChangeRoute = (event) => {
        const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
        setSalesRoute(selectedOptions);
    };

	const handelDriverChange = (event) => {
	    const value = event.target.value;
	    setDriverValue(value);
	};

	const createManifestEvent = async () =>{
		

        if (checkedValues.length > 0) {
			const formData = new FormData();
			if (salesRoute.length > 0) {
			 	salesRoute.forEach(value => {
	                formData.append('route_no[]', value);
	            });
			}
			formData.append('mId', mIdValue);
			formData.append('user_id', user_id);
			checkedValues.forEach((value, index) => {
			  formData.append('val[]', value);
			});

			if(driverValue != ''){
				formData.append('driver_id', driverValue);


				const apiAddOrdersUrl = `${config.API_URL}/ws-create-manifest`;
			  	try {
			      const response = await fetch(apiAddOrdersUrl, {
			      		method: 'POST',
			      		headers: {
			        		// 'Content-Type': 'application/json',
			        		authorization: `Bearer ${accessToken}`,
			      		},
			      		body: formData,
			    		});

			      if (!response.ok) {
			        setFlashMessage({ message: 'something went wrong, try again', type: 'error' });
			      }
			      const data = await response.json();
			      console.log(data);
			      if(data.success){
			      	//setFlashMessage({ message: 'manifest created successfully.', type: 'success' });

			      	Swal.fire({
			          title: 'Success!',
			          text: 'manifest created successfully.',
			          icon: 'success',
			          showConfirmButton: false, 
			          timer: 2000,               
			          timerProgressBar: true,     
			        });

			      	fetchRoutePlanningList();
			      	setSalesRoute([]);
		            setDriverValue('');
		            if (selectRef.current) {
		                $(selectRef.current).val(null).trigger('change'); // Reset Select2 for routes
		            }
		            
			      }


			      
			    } catch (error) {
			      	console.error('Error Accept Order:', error);	      
			     	setFlashMessage({ message: 'Error Accept Order order. Please check the console for details', type: 'error' });
			    }
			}else{
				setFlashMessage({ message: 'please select driver', type: 'error' });
			}

			
		}else{
			//console.log('please select order to create manifest');
			//setFlashMessage({ message: 'please select order to create manifest', type: 'error' });

			Swal.fire({
	          title: 'Error',
	          text: 'please select order to create manifest.',
	          icon: 'error',
	          showConfirmButton: false, 
	          timer: 2000,               
	          timerProgressBar: true,     
	        });
		}

		 //console.log(formData);

        // Log or handle the formatted routes

	}

	const fetchDriversOptions = async () => {

  		const apiSalesRapUrl = `${config.API_URL}/getDriver`;
	    try {
	     	const response = await fetch(apiSalesRapUrl, {
        		method: 'POST',
        		headers: {

          			authorization: `Bearer ${accessToken}`,
        		}
      		});

	      	const data = await response.json();
	      	console.log(data);
	      	const formattedOptions = data.data.driver.map(item => ({
                key: `${item.first_name} ${item.last_name}`,
                value: item.id,
            }));
            setDriverOptions(formattedOptions);
	    }catch (error) {
	      console.error('api fail Error fetching Not Order Report:', error);
	    }
  	};

  	const fetchSalesRoute = async () => {
  		
  		const apiSalesRouteUrl = `${config.API_URL}/getRoute`;
  		try {
  			const response = await fetch(apiSalesRouteUrl, {
  				method : 'POST',
  				headers : {
  					authorization: `Bearer ${accessToken}`,
  				}
  			});

  			const data = await response.json();
  			console.log(data);
  			const formattedOptions = data.data.map(item => ({
                key: `${item.route_number}`,
                value: item.id,
            }));
            setRouteOptions(formattedOptions);

  		}catch(error){
  			console.error('api fail error fetching sales routes');
  		}
  	};
  	const fetchRoutePlanningList = async () => {
		

		setCheckedValues([]);
		const routeData = new FormData();
		routeData.append('draw', draw);
	    routeData.append('start', start);
	    routeData.append('length', length);
	    routeData.append('user_id', user_id);

	    if (salesRoute.length > 0) {
		    salesRoute.forEach(value => {
		      routeData.append('route[]', value);
		    });
		}

  		const apiRoutePlanningUrl = `${config.API_URL}/ws-route-planning-list`;
	    try {
	     	const response = await fetch(apiRoutePlanningUrl, {
        		method: 'POST',
        		headers: {
          			authorization: `Bearer ${accessToken}`,
        		},
        		body: routeData,
      		});
	      	const data = await response.json();
           	//console.log(data.data);
           	setManifestList(data.data);
           	setMIdValue(data.mid);
	    }catch (error) {
	      console.error('api fail Error fetching Not Order Report:', error);
	    }
  	};

  	useEffect(() => {

  		if (accessToken === null){
            window.location.href = `${config.BASE_URL}/login`; 
            return;
        }
        fetchRoutePlanningList();
    	fetchDriversOptions();
    	fetchSalesRoute();
  	},[]);

  	const searchManifestEvent = () => {
	    fetchRoutePlanningList(); // This will call the fetchRoutePlanningList method when the button is clicked
	};

  	

  	useEffect(() => {
    	const table = $('#manifestListTable').DataTable({
      		pageLength: 50,
      		paging: true,
      		lengthMenu: [10, 20, 50],
      		searching: true,
      		ordering: false,
      		info: true,
      		responsive: true,
      		autoWidth: false,
      		language: {
        		paginate: {
          			first: 'First',
          			last: 'Last',
          			next: 'Next',
          			previous: 'Previous',
        		},
        		search: 'Search:',
      		},
      		data: manifestList,
      		columns: [
        		{
          			data: null,
         			render: function (data, type, row) { 
         				const isChecked = !!checkedItems[row.id];
            			return `
			              <label class="checkbox">
			                <input 
			                  type="checkbox" 
			                  name="val[]" 
			                  value="${row.id}" 
			                  class="dataTableCheckbox"
			                />
			                <span class="default"></span>
			              </label>
			            `;
          			}
        		},
		         {
				      data: 'reference_no',
				      width: '10%', 
				      className: 'text-center',
				      
				      render: function (data, type, row) {
				        return '<a href="'+BASE_URL+'/delivery/route_planing/view/' + row.id + '">' + row.reference_no + '</a>';
				      }
				    },
		        { data: 'customer' },
		        { data: 'postal_code' },
		        { data: 'grand_total' },
		        { data: 'total' },
		        { data: 'paid' },
		        { data: 'route_number' },
		        { data: 'created_by_name' },
		       
      		],
      		columnDefs: [
		        {
		          targets: [0], // Make sure the checkbox column is non-orderable
		          orderable: false
		        },
		        {
		          targets: [1, 2, 3, 4, 5, 6, 7], // Allow sorting on other columns
		          orderable: true
		        }
	      	]
    	});

    	$(document).off('change', '#manifestListTable .dataTableCheckbox').on('change', '#manifestListTable .dataTableCheckbox', function() {
     
	      const value = $(this).val();
	      const checked = $(this).is(':checked');
		    setCheckedValues(prevState => {
		      if (checked) {
		        // Add value if checked
		        return [...prevState, value];
		      } else {
		        // Remove value if unchecked
		        return prevState.filter(val => val !== value);
		      }
		    });

	    });

	    return () => {
	      table.destroy();
	    };
  	}, [manifestList]);


  	useEffect(() => {
        // Initialize Select2
        if (selectRef.current) {
            $(selectRef.current).select2({
                placeholder: 'Select Routes',
                width: '100%',
                tags: true,
            }).on('change', (e) => {
                setSalesRoute($(e.target).val() || []);
            });
        }

        // Cleanup function to destroy Select2 instance
        return () => {
            if (selectRef.current) {
                $(selectRef.current).select2('destroy');
            }
        };
    }, [routeOptions]);

	return(
		<>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>  
		                  <Link to="/delivery/route_planing">Dispatch & Delivery</Link>  
		                  <Link to="/delivery/route_planing">Create Manifest</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>

	      	<div className="main-container">
			    <div className="container-fluid">
			        <div className="row">
			        	

						<div className="col-md-12 col-lg-12 col-sm-12">
		        		<div className="date-range-picker col-md-2 cp-lg-2 col-sm-3" style={{float: 'left'}}>	
		            		<label style={{marginBottom: '5px'}} ><b>Select Route</b></label>
		            		<select
						            ref={selectRef}
						            multiple
						            value={salesRoute}
						            className="form-select"
						        >
						            {routeOptions.map(option => (
						                <option key={option.value} value={option.value}>
						                    {option.key}
						                </option>
						            ))}
						        </select>
		            </div>
		            <div className="date-range-picker col-md-2 col-lg-2 col-sm-3" style={{float: 'left', paddingLeft: '15px', paddingRight: '15px'}}>	
		            		<label style={{marginBottom: '5px'}} ><b>Select Driver</b></label>
		            		<select value={driverValue} onChange={handelDriverChange} className="form-select" name="" id="" >
				                    <option value="">Select a driver</option>
				                      {driverOptions.map(option => (
				                            <option key={option.value} value={option.value}>
				                              {option.key}
				                            </option>
				                      ))}
				                </select>
		            </div>
		            <div className="col-md-3 col-lg-2 col-sm-2" style={{float: 'left', paddingTop: '30px'}} >
					
					<button type="button" onClick={searchManifestEvent}  className="acceptbtn sbmit" style={{float: 'left', margin: '0px 0 0 0'}}>Submit</button>
								</div>



								<div>
								<button type="button" onClick={createManifestEvent}  className="acceptbtn sbmit" style={{float: 'right', margin: '30px 0 0 0'}} >Create Manifest</button>
									</div>


								
		        	</div>


			        </div>
			        <div className="total-manifests-area dashboard">
	                  
	                    <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
	                        <table className="table table-bordered dataTable resizable" id="manifestListTable">
	                            <thead>
	                                <tr>
	                                    <th>#</th>
	                                    <th>Invoice Number</th>
	                                    <th>Customer Name(A/C No)</th>
	                                    <th>Post Code</th>
	                                    <th>Invoice Amt</th>
	                                    <th>Total Due</th>
	                                    <th>Sales Rep Cash</th>
	                                    <th>Route</th>
	                                    <th>Added By</th>
	                                </tr>
	                            </thead>
	                            <tbody>
	                                
	                            </tbody>
	                            <tfoot>

					            </tfoot>
	                        </table>
	                    </div>
	                </div>
			    </div>
			</div>

			<FlashMessage
          message={flashMessage?.message}
          type={flashMessage?.type}
          onClose={handleCloseMessage}
        />

	      	<Footer />

		</>
	);


}

export default CreateNewManifest;