import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import AccountService from '../../services/AccountServices';

const PushSage = () => {

	const [draw, setDraw] = useState(1);
  	const [start, setStart] = useState(0);
  	const [length, setLength] = useState(50);
  	const accessToken = localStorage.getItem('accessToken'); 
  	const [pushSage, setPushSage] = useState([]);
  	const [checkedItems, setCheckedItems] = useState({});

  	useEffect(() => {
        if (accessToken === null) {
            window.location.href = `${config.BASE_URL}/login`; 
            return;
        }
        const loadPushSageList = async () => {
            try {
                const data = await AccountService.fetchPushSageList(draw, start, length, accessToken);
                setPushSage(data);
            } catch (error) {
                console.error('Failed to load push sage list:', error);
            }
        };

        loadPushSageList();
    }, [draw, start, length, accessToken]);

  	useEffect(() => {
    	const table = $('#pushSageList').DataTable({
      		pageLength: 50,
      		paging: true,
      		lengthMenu: [10, 20, 50],
      		searching: true,
      		ordering: false,
      		info: true,
      		responsive: true,
      		autoWidth: false,
      		language: {
        		paginate: {
          			first: 'First',
          			last: 'Last',
          			next: 'Next',
          			previous: 'Previous',
        		},
        		search: 'Search:',
      		},
      		data: pushSage,
      		columns: [
        		{
          			data: null,
          			className : 'text-center',
          			width : '4%',
         			render: function (data, type, row) { 
         				const isChecked = !!checkedItems[row.invoice_amount];
            			return `
			              <label class="checkbox" style="margin: 0px auto;float: none;">
			                <input 
			                  type="checkbox" 
			                  name="val[]" 
			                  value="${row.invoice_amount}" 
			                  class="dataTableCheckbox"
			                />
			                <span class="default"></span>
			              </label>
			            `;
          			}
        		},
		        { data: 'invoice_date', className : 'text-start', width : '12%' },
		        { data: 'invoice_date', className : 'text-start', width : '12%' },
		        { 
		        	data: null, className : 'text-start', width : '12%',
		        	render: function (data, type, row) {
	                	return '<a href="'+BASE_URL+'/sales/edit_invoice/' + row.invoice_amount + '">' + row.invoice_amount + '</a>';
	            	}
		        },
		        { data: 'customer' },
		        { data: 'invoice_amount', className : 'text-end', width : '10%' },
	        	{
	          		data: null,
	          		className : 'text-center',
	          		width : '5%',
	          		render: function (data, type, row) {
	            		return `<a style="display: contents;" class="viewbtn" href="#">  <i class="fa  fa-thumb-tack" style="width: 27px;height: 27px;padding: 5px 0px 0px 8px;" ></i></a>`;
	        		}
	        	}
      		],
      		columnDefs: [
		        
      		]
   	 	});

    

	    return () => {
	      table.destroy();
	    };
  	}, [pushSage]);


  	return(
  		<>
  			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/account/push_sage">Account</Link> 
		                  <Link to="/account/push_sage">Push to Sage</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>
	      	<div className="main-container">
			    <div className="container-fluid">

			    	<div className="total-manifests-area dashboard" style={{position : 'relative'}} >
	                    <p><b>Push To Sage </b></p>
	                    
	                    <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
	                        <table className="table table-bordered dataTable resizable" id="pushSageList">
	                            <thead>
	                                <tr>
	                                    <th>#</th>
	                                    <th>Proforma Inv Date</th>
	                                    <th>Delivery Date</th>
	                                    <th>Invoice Number</th>
	                                    <th>Customer Name</th>
	                                    <th>Invoice Amount</th>
	                                    <th>Action</th>

	                                </tr>
	                            </thead>
	                            <tbody>
	                                
	                            </tbody>
	                            <tfoot>

					            </tfoot>
	                        </table>
	                    </div>
	                    <div className="col-2 text-start">
	                		<button type="botton" class="acceptbtn" style={{float: 'left', margin: '26px 0px 0px'}}>Submit</button>
	                	</div>

	                </div>
	                

			    </div>
			</div>
	      	<Footer />

  		</>
  	);

}
export default PushSage;