import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";

const CategorySuggestionBox = ({ category_id }) =>{

	const accessToken = localStorage.getItem('accessToken');

	const [categoryOptions, setCategoryOptions] = useState([]);
    const [categoryFilteredOptions, setCategoryFilteredOptions] = useState([]);
    const [categoryValue, setCategoryValue] = useState(null); // Change to null initially
    const [categoryInputValue, setCategoryInputValue] = useState('');

    const categoryHandleChange = (selectedOption) => {
        setCategoryValue(selectedOption);
    };

    const categoryHandleInputChange = (inputValue) => {
        setCategoryInputValue(inputValue);
        const newFilteredOptions = categoryOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setCategoryFilteredOptions(newFilteredOptions);
    };

    const fetchcategoryList = async () => {
        const apiCategoryUrl = `${config.API_URL}/get_categories`;
        try {
            const response = await fetch(apiCategoryUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            const formattedOptions = data.data.map(item => ({
                label: item.name,
                value: item.id,
            }));
            setCategoryOptions(formattedOptions);
            setCategoryFilteredOptions(formattedOptions);
        } catch (error) {
            console.error('API failed: error fetching categories', error);
        }
    };

    useEffect(() => {
        if (accessToken === null) {
            window.location.href = `${config.BASE_URL}/login`;
            return;
        }
        fetchcategoryList();
    }, []);

    // Set initial value if category_id is provided
    useEffect(() => {
        if (category_id) {
            const selectedCategory = categoryOptions.find(option => option.value === category_id);
            setCategoryValue(selectedCategory || null);
        }
    }, [category_id, categoryOptions]);

	return(
		<>
			<Select
                isClearable
                onInputChange={categoryHandleInputChange}
                onChange={categoryHandleChange}
                options={categoryFilteredOptions}
                placeholder="Select"
                value={categoryValue}
                name="category" className="abovedd-cat promoselect abovedd"
            />


		</>
	);

}
export default CategorySuggestionBox;