import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import axios from 'axios';

const HoldList = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [newSales, setNewSales] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const orderIdRef = useRef(null);
  const reasonRef = useRef(null);

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('role', selectedRole);

    const apiUrl = `${config.API_URL}/sales/holdsaleslist`;
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch new sales data');
      }

      const data = await response.json();
      setNewSales(Array.isArray(data.data) ? data.data : []);
      
    } catch (error) {
      console.error('Error fetching new sales data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, selectedRole]);

  useEffect(() => {
    const table = $('#neworderTable').DataTable({
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: false,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
      data: newSales,
      columns: [
        {
          targets: 0,
          data: null,
          render: function (data, type, row, meta) {
              return '<div style="text-align: center;">' + (meta.row + 1) + '</div>';
            }
        },
        { data: 'date' },
        { 
          data: 'reference_no',
          render: function (data, type, row) {
              return '<a href="'+config.BASE_URL+'/sales/edit_invoice/' + row.ids + '">' + row.reference_no + '</a>';
          }
        },
        { data: 'customer' },
        { data: 'postal_code' },
        { data: 'route_number' },
        { data: 'grand_total' },
        { data: 'prev_due' },
        { data: 'name' },
        {
            data: null,
            render: function (data, type, row) {
             {/* return `<div>
                    <a class="icon" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-three-dots-vertical"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                        <li>
                          <a class="deductionbtn" data-action="hold" data-order='${row.ids}' >New</a>
                        </li>
                    </ul>
                </div>`;*/}

                return `<div>
					<a class="deductionbtn" data-action="hold" data-order='${row.ids}' title="New"><i class="bi bi-file-earmark-plus"></i></a>
					</div>`;
          }
        }
      ],
    });
    
	  // Handle clicks for actions
    $('#neworderTable').on('click', '.deductionbtn', function (e) {
      e.preventDefault();
      const orderData = $(this).data('order'); 
      handleNewStatus(orderData); 
    });

    return () => {
      table.destroy();
    };
  }, [newSales]);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };
  // Function to handle new status after user confirmation
  const handleNewStatus = (id, accessToken) => {
    // Confirming the action with the user
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to transfer this order to new sales?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'No, cancel',
    }).then((result) => {
      // If confirmed, proceed with the API call
      if (result.isConfirmed) {
        const accessToken = localStorage.getItem('accessToken');
        const formData = new FormData();
        formData.append('status', 'New');
        formData.append('id', id);
        formData.append('reason_note', ''); 
        axios
          .post(
            `${config.API_URL}/ws-update-status`,
            formData,
            {
              headers: {
                'authorization': `Bearer ${accessToken}`, 
              },
            }
          )
          .then((response) => {
            fetchData();
            Swal.fire('Success', 'Order has been transferred!', 'success');
          })
          .catch((error) => {
            Swal.fire('Error', 'Something went wrong!', 'error');
            console.error('Error:', error); 
          });
      }
    });
  };

  const updateReject = async (event) => {
    event.preventDefault();

    if (!orderIdRef.current || !reasonRef.current) {
      console.error('Required form elements are missing');
      alert('Required form elements are missing. Please try again.');
      return;
    }

    const orderId = orderIdRef.current.value;
    const reason = reasonRef.current.value;

    const formData = new FormData();
    formData.append('status', 'Rejected');
    formData.append('reason_note', reason);
    formData.append('id', orderId);

    const accessToken = localStorage.getItem('accessToken');
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/ws-update-status`, {
        method: 'POST',
        headers: {
          'authorization': `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! Status: ${response.status}. Error: ${errorText}`);
      }

      const data = await response.json();
      console.log('API Response:', data);
      

       Swal.fire({
        title: "Success!",
        text: "Order rejected successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      }).then(() => 
      {
        window.location.href = config.BASE_URL + '/picking/proforma/';
      });

    } catch (error) {
      console.error('Error rejecting order:', error);
      alert('Error rejecting order. Please check the console for details.');
    }

    setShowRejectModal(false);
  };

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12"> 
              <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
              <Link to="/reports/sales_report">Sales</Link> 
              <Link to="#">Hold List</Link> 
            </div>
          </div>
       	</div>
      </div>

      {showRejectModal && (
        <div className="modal fade show d-block" id="reject-order" tabIndex="-1" style={{ display: 'block' }}>
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Reject Order</h5>
                <button type="button" className="btn-close" onClick={() => setShowRejectModal(false)} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {selectedOrder && (
                  <div className="rejectorder-popup">
                    <form id="rejectForm">
                      <h4>Proforma Invoice Details</h4>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>Order Id</td>
                            <td>{selectedOrder.reference_no}</td>
                          </tr>
                          <tr>
                            <td>Customer (A/C)</td>
                            <td>{selectedOrder.customer}</td>
                          </tr>
                          <tr>
                            <td>Order Amount</td>
                            <td>{selectedOrder.invoice_total}</td>
                          </tr>
                          <tr>
                            <td>Previous Dues</td>
                            <td>{selectedOrder.previous_dues || 'N/A'}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="form-group">
                        <label className="col-form-label">Reason Note</label>
                        <textarea id="reason" name="reason" ref={reasonRef}></textarea>
                      </div>
                      <input type="hidden" name="order_id" id="order_id" ref={orderIdRef} value={selectedOrder.id} />
                      <button type="button" onClick={updateReject} className="updatebtn left updateReject">Update</button>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div >
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="date-range-picker col-md-2 cp-lg-2 col-sm-3" style={{ float: 'left' }}>
                      <label style={{ marginBottom: '5px' }} ><b>From Date</b></label>
                      <input
                        type="date"
                        name="fromDate"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="date-range-picker col-md-2 cp-lg-2 col-sm-3" style={{ float: 'left', paddingLeft: '15px' }}>
                      <label style={{ marginBottom: '5px' }} ><b>To Date</b></label>
                      <input
                        type="date"
                        name="toDate"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div>
                      <button type="submit" onClick={handleSubmit} className="acceptbtn" style={{ float: 'left', margin: '30px 0 0 15px' }} >Submit</button>
                    </div>
                  </div>
                </div>
                <div className='total-manifests-area dashboard'>
                  {/* <div style={{ float: 'right', width: '100%', textAlign: 'right', marginTop: '25px' }}></div> */}
                  <div className="verical-scrl-wrapper newsales-table" id="style-3">
                    <table className="table table-bordered dataTable" id="neworderTable">
                    <thead>
		                 	<tr>
                          <th style={{ textAlign: 'center' }}>#</th>
			                    <th>Order Date</th>
			                    <th>Order Id</th>
			                    <th>Customers (A/C No)</th>
			                    <th>Post Code</th>
			                    <th>Route</th>
			                    <th>Order Amt</th>
			                    <th>Previous Dues</th>
			                    <th>Order By</th>
			                    <th>Action</th>
		                  	</tr>
		              	</thead>
                      <tbody>
                        {/* DataTables will populate this tbody */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HoldList;
