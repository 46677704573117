import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import Select from "react-select";
import { API_URL } from "../Config";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import Swal from "sweetalert2";
import config from "../common/Config";
import {useNavigate, Link } from "react-router-dom";
import axios from "axios";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from 'date-fns';

const Add_Promos = () => {
  const [promoSelected, setPromoSelected] = useState(1);
  const [productInputValue, setProductInputValue] = useState("");
  const [productFilteredOptions, setProductFilteredOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  //for combo promotion
  const [buyP, setBuyP] = useState([]);
  const [getP, setGetP] = useState([]);
  const navigateToPromotionList = useNavigate(); 
  
  // for Product discount
  const [productList, setProductList] = useState([]);
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    //fetchProductList();
    setBuyP([]);
    setGetP([]);
  }, [promoSelected]);

  const [formData, setFormData] = useState({
    
    name          : '',
    start_date    : '',
    end_date      : '',
    total_buy_qty : '',
    total_get_qty : '',
    subtype       : 'Any',
    description   : '',
    amount        : '',
    gettype       : '',
    getamount     : '',
    percentage    : '',
    gettype1      : '',
    getamount1    : '',
    percentage1   : '',
  });
  const [errors, setErrors] = useState({});

  const [inputType, setInputType] = useState("amount");
  const [inputInvoiceType, setInputInvoiceType] = useState("amount");

  const handleSelectChange = (event) => {
    setInputType(event.target.value);
  };

  const handleInvoiceSelectChange = (event) => {
    setInputInvoiceType(event.target.value);
  };

  const handleChange = (e) => {

    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });



    

    let fieldErrors = {};
    

    const isPromoSelected = promoSelected;

    switch (name) {

      

      case 'name':
        if (!value) {
          fieldErrors.name = "Mention name.";
        } else if (value.length < 2) {
          fieldErrors.name = "name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.name = "name must not exceed 255 characters.";
        }
        if (!fieldErrors.name) {
          fieldErrors.name = '';
        }
        break;

      case 'start_date':
        if (!value) {
          fieldErrors.start_date = "Start date is required.";
        } else if (new Date(value) < new Date().setHours(0, 0, 0, 0)) {
          fieldErrors.start_date = "Start date must be later than or equal to the current date.";
        }
        if (!fieldErrors.start_date) {
          fieldErrors.start_date = '';
        }
        break;

      case 'end_date':
        // Validate end_date
        if (!value) {
          fieldErrors.end_date = "End date is required.";
         } else if (new Date(value) < new Date().setHours(0, 0, 0, 0)) {
          fieldErrors.end_date = "End date must be later than or equal to the current date.";
        } else if (formData.start_date && new Date(value) <= new Date(formData.start_date)) {
          fieldErrors.end_date = "End date must be greater than start date.";
        }
        if (!fieldErrors.end_date) {
          fieldErrors.end_date = '';
        }
        break;

      case 'total_buy_qty':
        if (isPromoSelected != 1) break;
        if (!value) {
          fieldErrors.total_buy_qty = "Mention quantity.";
        } else if (value < 0) {
          fieldErrors.total_buy_qty = "Mention positive.";
        } else if (value.total_buy_qty < 1) {
          fieldErrors.total_buy_qty = "quantity must consist of at least 1 digit.";
        } else if (value.total_buy_qty > 255) {
          fieldErrors.total_buy_qty = "quantity must not exceed 255 digit.";
        }
        if (!fieldErrors.total_buy_qty) {
          fieldErrors.total_buy_qty = '';
        }
        break;

      case 'total_get_qty':
        if (isPromoSelected != 1) break;
        if (!value) {
          fieldErrors.total_get_qty = "Mention quantity.";
        } else if (value < 0) {
          fieldErrors.total_get_qty = "Mention positive.";
        } else if (value.total_get_qty < 1) {
          fieldErrors.total_get_qty = "quantity must consist of at least 1 digit.";
        } else if (value.total_get_qty > 255) {
          fieldErrors.total_get_qty = "quantity must not exceed 255 digit.";
        }
        if (!fieldErrors.total_get_qty) {
          fieldErrors.total_get_qty = '';
        }
        break;


      case 'amount':
        if (isPromoSelected != 3) break;
        if (!value) {
          fieldErrors.amount = "Mention amount.";
        } else if (value < 0) {
          fieldErrors.amount = "Mention positive.";
        } else if (value.amount < 1) {
          fieldErrors.amount = "amount must consist of at least 1 digit.";
        } else if (value.amount > 255) {
          fieldErrors.amount = "amount must not exceed 255 digit.";
        }
        if (!fieldErrors.amount) {
          fieldErrors.amount = '';
        }
        break;

      case 'getamount':
        if (isPromoSelected != 3 && inputInvoiceType != 'amount') break;
        if (!value) {
          fieldErrors.getamount = "Mention amount.";
        } else if (value < 0) {
          fieldErrors.getamount = "Mention positive.";
        } else if (value.getamount < 1) {
          fieldErrors.getamount = "amount must consist of at least 1 digit.";
        } else if (value.getamount > 255) {
          fieldErrors.getamount = "amount must not exceed 255 digit.";
        }
        if (!fieldErrors.getamount) {
          fieldErrors.getamount = '';
        }
        break;

      case 'percentage':
        if (isPromoSelected != 3 && inputInvoiceType != 'percentage') break;
        if (!value) {
          fieldErrors.percentage = "Mention percentage.";
        } else if (value < 0) {
          fieldErrors.percentage = "Mention positive.";
        } else if (value.percentage < 1) {
          fieldErrors.percentage = "percentage must consist of at least 1 digit.";
        } else if (value.percentage > 255) {
          fieldErrors.percentage = "percentage must not exceed 255 digit.";
        }
        if (!fieldErrors.percentage) {
          fieldErrors.percentage = '';
        }
        break;

      case 'getamount1':
        if (isPromoSelected != 4 && inputType != 'amount') break;
        if (!value) {
          fieldErrors.getamount1 = "Mention amount.";
        } else if (value < 0) {
          fieldErrors.getamount1 = "Mention positive.";
        } else if (value.getamount1 < 1) {
          fieldErrors.getamount1 = "amount must consist of at least 1 digit.";
        } else if (value.getamount1 > 255) {
          fieldErrors.getamount1 = "amount must not exceed 255 digit.";
        }
        if (!fieldErrors.getamount1) {
          fieldErrors.getamount1 = '';
        }
        break;

      case 'percentage1':
        if (isPromoSelected != 4 && inputType != 'percentage') break;
        if (!value) {
          fieldErrors.percentage1 = "Mention percentage.";
        } else if (value < 0) {
          fieldErrors.percentage1 = "Mention positive.";
        } else if (value.percentage1 < 1) {
          fieldErrors.percentage1 = "percentage must consist of at least 1 digit.";
        } else if (value.percentage1 > 255) {
          fieldErrors.percentage1 = "percentage must not exceed 255 digit.";
        }
        if (!fieldErrors.percentage1) {
          fieldErrors.percentage1 = '';
        }
        break;


      default:
        break;
    }


    setErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors
    }));
  };


  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Mention name.";
    } else if (formData.name.length < 2) {
      newErrors.name = "name must consist of at least 2 characters.";
    } else if (formData.name.length > 255) {
      newErrors.name = "name must not exceed 255 characters.";
    }

    if (!formData.start_date) {
      newErrors.start_date = "Start date is required.";
     } else if (new Date(formData.start_date) < new Date().setHours(0, 0, 0, 0)) {
      newErrors.start_date = "Start date must be later than or equal to the current date.";
    }

    // End Date validation
    if (!formData.end_date) {
      newErrors.end_date = "End date is required.";
    } else if (new Date(formData.end_date) < new Date().setHours(0, 0, 0, 0)) {
      newErrors.end_date = "End date must be later than or equal to the current date.";
    } else if (formData.start_date && new Date(formData.end_date) <= new Date(formData.start_date)) {
      newErrors.end_date = "End date must be greater than start date.";
    }

    const isPromoSelected = promoSelected;
    if (isPromoSelected == 1) {
      if (!formData.total_buy_qty) {
        newErrors.total_buy_qty = "Mention quantity.";
      } else if (formData.total_buy_qty.length < 1) {
        newErrors.total_buy_qty = "quantity must consist of at least 1 digit.";
      } else if (formData.total_buy_qty.length > 255) {
        newErrors.total_buy_qty = "quantity must not exceed 255 digits.";
      }

      if (!formData.total_get_qty) {
        newErrors.total_get_qty = "Mention quantity.";
      } else if (formData.total_get_qty.length < 1) {
        newErrors.total_get_qty = "quantity must consist of at least 1 digit.";
      } else if (formData.total_get_qty.length > 255) {
        newErrors.total_get_qty = "quantity must not exceed 255 digits.";
      }
    }

    if (isPromoSelected == 3) {
      if (!formData.amount) {
        newErrors.amount = "Mention amount.";
      } else if (formData.amount.length < 1) {
        newErrors.amount = "amount must consist of at least 1 digit.";
      } else if (formData.amount.length > 255) {
        newErrors.amount = "amount must not exceed 255 digits.";
      }

      if(inputInvoiceType == "amount"){
        if (!formData.getamount) {
          newErrors.getamount = "Mention amount.";
        } else if (formData.getamount.length < 1) {
          newErrors.getamount = "amount must consist of at least 1 digit.";
        } else if (formData.getamount.length > 255) {
          newErrors.getamount = "amount must not exceed 255 digits.";
        }
      }else if(inputInvoiceType == "percentage"){
        if (!formData.percentage) {
          newErrors.percentage = "Mention percentage.";
        } else if (formData.percentage.length < 1) {
          newErrors.percentage = "percentage must consist of at least 1 digit.";
        } else if (formData.percentage.length > 255) {
          newErrors.percentage = "percentage must not exceed 255 digits.";
        }
      }

      
    }

    if (isPromoSelected == 4) {
      

      if(inputType == "amount"){
        if (!formData.getamount1) {
          newErrors.getamount1 = "Mention amount.";
        } else if (formData.getamount1.length < 1) {
          newErrors.getamount1 = "amount must consist of at least 1 digit.";
        } else if (formData.getamount1.length > 255) {
          newErrors.getamount1 = "amount must not exceed 255 digits.";
        }
      }else if(inputType == "percentage"){
        if (!formData.percentage1) {
          newErrors.percentage1 = "Mention percentage.";
        } else if (formData.percentage1.length < 1) {
          newErrors.percentage1 = "percentage must consist of at least 1 digit.";
        } else if (formData.percentage1.length > 255) {
          newErrors.percentage1 = "percentage must not exceed 255 digits.";
        }
      }

      
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  

  const productHandleInputChange = (inputValue) => {
    fetchProductNameOptions(inputValue);
  };

  const fetchProductNameOptions = async (inputValue) => {
      // Only fetch if the input length is greater than or equal to 3 characters
    if (inputValue.length < 3) {
      return; // Prevent sending requests if input is too short
    }

    // Construct the API URL with query parameters
    const apiSalesRapUrl = `${config.API_URL}/getproduct?term=${encodeURIComponent(inputValue)}`;

    try {
      // Make the GET request to the API
      const response = await fetch(apiSalesRapUrl, {
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`, // Ensure you have the correct access token
        },
      });

      // Check if the response was successful (status code 2xx)
      if (!response.ok) {
        throw new Error('Failed to fetch product data');
      }

      // Parse the response data
      const data = await response.json();

      // Ensure the data contains a valid 'products' array
      if (data && Array.isArray(data)) {
        // Format the product data into the desired format
        const formattedOptions = data.map((item) => ({
          label: item.label,
          value: item.id,
        }));

        // Set the options in the state (both for full and filtered options)
        setProductOptions(formattedOptions);
        setProductFilteredOptions(formattedOptions);
      } else {
        console.error('Invalid product data:', data);
      }

    } catch (error) {
      // Log any errors that occurred during the fetch process
      console.error('Error fetching product options:', error);

      // Optionally, you could set an error message in the state to show to the user
      // setError("Failed to load product options. Please try again later.");
    }
  };

  const productHandleChange = (selectedOption, type) => {
    if (selectedOption) {
      fetchAddProductInfo(selectedOption.value, type);
    } 
  };
  
  // product_disc
  const fetchAddProductInfo = async (selectedOption, type) => {
    if (promoSelected === 1) {
      if (type === "get_combo" && getP.length === 1) {
        Swal.fire({
          title: "Failed!",
          text: "Only one product can be added",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
        return;
      }
      if (type === "buy_combo") {
      const existingProduct = buyP.find((item) => item.id === selectedOption);
      if (existingProduct) {
        Swal.fire({
          title: "Failed!",
          text: "Product is already present",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
        return;
      }
     }
    }

    if (promoSelected === 2) {
      if (type === "get_combo_1" && getP.length === 1) {
        Swal.fire({
          title: "Failed!",
          text: "Only one product can be added",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
        return;
      }

      if (type === "buy_combo_1" && buyP.length === 1) {
        Swal.fire({
          title: "Failed!",
          text: "Only one product can be added",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
        return;
      }
    }

    const formData = new FormData();
    formData.append("product_id", selectedOption);

    const apiUrl = `${API_URL}/purchases/get_order_datails`;
    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      const productData = response.data[0];
      
      const newRow = {
        id: productData.id,
        code: productData.code,
        name: productData.name,
        size: productData.size,
        split: productData.split,
        code: productData.code
      };

      if (promoSelected === 1) 
      {
        if (type === "buy_combo") {
          setBuyP((prevRows) => [...prevRows, newRow]);
        } else if (type === "get_combo") {
          setGetP((prevRows) => [...prevRows, newRow]);
        }
      }
      else if(promoSelected === 2)
        {
          if (type === "buy_combo_1") {
            setBuyP((prevRows) => [...prevRows, newRow]);
          } else if (type === "get_combo_1") {
            setGetP((prevRows) => [...prevRows, newRow]);
          }
        }
      else if(promoSelected === 4)
      {
        if (type === "product_disc") {
          setProductList((prevRows) => [...prevRows, newRow]);
        }
      }

    } catch (error) {
      console.error("API fetch error:", error);
    }
  };

  const fetchProductList = async () => {
    //const apiSalesRouteUrl = `${API_URL}/purchases/getProducts`;
    const apiSalesRouteUrl = `${API_URL}/purchases/getProducts`;
    try {
      const response = await fetch(apiSalesRouteUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      const formattedOptions = data.products.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setProductOptions(formattedOptions);
      setProductFilteredOptions(formattedOptions);
    } catch (error) {
      console.error("api fail error fetching products");
    }
  };

  const CreatePromotion = async () => {

    if (!validateForm()) return;

    let form = "";
    let promotion_type = "";
    if (promoSelected == 1) {
      promotion_type = "combo";
      form = document.getElementById("combo_promotion_form");
    } else if (promoSelected == 2) {
      promotion_type = "buy_get";
      form = document.getElementById("buynget_promotion_form");
    } else if (promoSelected == 3) {
      promotion_type = "invoice_amount";
      form = document.getElementById("inv_amt_promotion_form");
    } else if (promoSelected == 4) {
      promotion_type = "product_discount";
      form = document.getElementById("product_disc_promotion_form");
    }

    let name = $("#name").val();
    let startDate = $("#startDate").val();
    let endDate = $("#endDate").val();
    let description = $("#description").val();
    const formData = new FormData(form);

    formData.set("name", name);
    formData.set("start_date", startDate);
    formData.set("end_date", endDate);
    formData.set("description", description);
    formData.set("type_promos", promotion_type);
    
    try {
      const response = await axios.post(`${API_URL}/addpromation`, formData, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

        const result = response.data;
       if (result.success == true){
        Swal.fire({
          title: "Success!",
          text: "Promotion added successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() =>
        {
          navigateToPromotionList('/Promotions/Promos');
        });

        }
        else{
      Swal.fire({
        title: "Failed!",
        text: "Promotion add Failed. Try Again",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
        }
    } catch (error) {
      console.error("Error creating promotion :", error);
    }
  };

  const handleRemove = (id, type) => {
    if(type === 'buy')
    {
      setBuyP(buyP.filter((product) => product.id !== id));
    }
    else if(type === 'get')
    {
      setGetP(getP.filter((product) => product.id !== id));
    }
    else if(type === 'productDisc')
    {
      setProductList(productList.filter((product) => product.id !== id));
    }
  };

  


  return (
    <div>
      <Header />
      <nav className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <Link to="/Promotions/Promos">Promotions</Link>
              <Link to="#">Add Promotion</Link>
            </div>
          </div>
        </div>
      </nav>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div 
                style={{ position: "relative" }}
              >
                <div className="">
                  <div className="add-promo-popup">
                    <form id="addcustomerform">
                      <div class="row mb-3 padd-l-13px">
                        <div class="col-sm-9">
                          <label for="inputEmail5" class="col-form-label">
                            Type
                          </label>

                          <div class="col-sm-10">
                            <div class="form-check">
                              <input
                                onClick={() => {
                                  setPromoSelected(1);
                                }}
                                class="form-check-input"
                                type="radio"
                                name="gridRadios"
                                id="gridRadios1"
                                value="option1"
                                defaultChecked
                              />
                              <label class="form-check-label" for="gridRadios1">
                                {" "}
                                Combo{" "}
                              </label>
                            </div>

                            <div class="form-check">
                              <input
                                onClick={() => {
                                  setPromoSelected(2);
                                }}
                                class="form-check-input"
                                type="radio"
                                name="gridRadios"
                                id="gridRadios2"
                                value="option2"
                              />
                              <label class="form-check-label" for="gridRadios2">
                                {" "}
                                Buy Get
                              </label>
                            </div>

                            <div class="form-check">
                              <input
                                onClick={() => {
                                  setPromoSelected(3);
                                }}
                                class="form-check-input"
                                type="radio"
                                name="gridRadios"
                                id="gridRadios3"
                                value="option3"
                              />
                              <label class="form-check-label" for="gridRadios3">
                                {" "}
                                Invoice Amount
                              </label>
                            </div>

                            <div class="form-check">
                              <input
                                onClick={() => {
                                  setPromoSelected(4);
                                }}
                                class="form-check-input"
                                type="radio"
                                name="gridRadios"
                                id="gridRadios4"
                                value="option4"
                              />
                              <label class="form-check-label" for="gridRadios4">
                                Product Discount
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label" htmlFor="name">
                              Name <span style={{color : 'red'}}>*</span>
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              className="form-control tip"
                              autoComplete="off"
                              value={formData.name}
                              onChange={handleChange}
                            />
                          </div>
                           <div className="col-sm-12">
                                  {errors.name && <span className="text-danger">{errors.name}</span>}
                                </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              className="col-form-label"
                              htmlFor="start_date"
                            >
                              Start Date <span style={{color : 'red'}}>*</span>
                            </label>
                            <input
                              type="date"
                              name="start_date"
                              id="startDate"
                              className="form-control tip date"
                              value={formData.start_date}
                              onChange={handleChange}

                            />
                          </div>
                          <div className="col-sm-12">
                            {errors.start_date && <span className="text-danger">{errors.start_date}</span>}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label
                              className="col-form-label"
                              htmlFor="end_date"
                            >
                              End Date <span style={{color : 'red'}}>*</span>
                            </label>
                            <input
                              type="date"
                              name="end_date"
                              id="endDate"
                              className="form-control tip date"
                              value={formData.end_date}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-12">
                            {errors.end_date && <span className="text-danger">{errors.end_date}</span>}
                          </div>
                        </div>
                      </div>

                      {promoSelected === 1 && (
                        <>
                          <form id="combo_promotion_form">
                            <div className="row mb-3">
                              <div className="col-md-6">
                              
                                <div className="form-group">
                            <label className="col-form-label" htmlFor="name">
                            Add Product <span style={{color : 'red'}}>*</span>
                            </label>
                            <Select
                                    isClearable
                                    onInputChange={productHandleInputChange}
                                    onChange={(e) =>
                                      productHandleChange(e, "buy_combo")
                                    }
                                    options={productFilteredOptions}
                                    placeholder="Select Product"
                                    className="promoselect"
                                  />
                          </div>
                              </div>
                              <div className="col-md-6">
                                <label className="col-form-label">
                                  Add Product <span style={{color : 'red'}}>*</span>
                                </label>
                                <Select
                                  isClearable
                                  data-type="get_combo"
                                  onInputChange={productHandleInputChange}
                                  onChange={(e) =>
                                    productHandleChange(e, "get_combo")
                                  }
                                  options={productFilteredOptions}
                                  placeholder="Select Product"
                                  className="promoselect"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="row mb-3">
                                  <div className="col-12 col-lg-12 col-sm-12">
                                    <label
                                      for="name1"
                                      className="col-form-label"
                                    >
                                      Buy Products{" "}
                                    </label>
                                    <div className="buyprct table">
                                      <table className="table table-bordered dataTable resizable">
                                        <thead>
                                          <tr>
                                            <th>Product (Code - Name) </th>
                                            <th>Type</th>
                                            <th>
                                              <span className="fa fa-trash"></span>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {buyP.length === 0 ? (
                                            <tr style={{ textAlign: 'center' }}>
                                              <td colSpan={4}>No product added</td>
                                            </tr>
                                          ) : (
                                            buyP.map((row) => (
                                              <tr key={row.id}>
                                                <td>
                                                  {row.code} - {row.name} - {row.size}
                                                  <input type="hidden" name="product2buy_id[]" value={row.id} />
                                                  <input type="hidden" name="product2buy[]" value={row.name} />
                                                  <input type="hidden" name="combo_item_code[]" value={row.code} />
                                                  <input type="hidden" name="quantity[]" value={1} />
                                                </td>
                                                <td style={{width : '85px'}}>
                                                  <select className="form-control" name="product_buy_type[]">
                                                    {row.split == '0' && <option value="0">Box</option>}
                                                    {row.split == '1' && <option value="1">Piece</option>}
                                                    {row.split == '2' && (
                                                      <>
                                                        <option value="0">Box</option>
                                                        <option value="1">Piece</option>
                                                      </>
                                                    )}
                                                  </select>
                                                </td>
                                                
                                                <td class="text-center" style={{width : '35px'}}>
                                                  <button
                                                    type="button"
                                                    className="delete-promo"
                                                    onClick={() => handleRemove(row.id, 'buy')}
                                                    style={{padding: '0px',fontSize: '19px',color: '#dc3545',background: 'none',border: 'none'}}
                                                  >
                                                    <span className="fa fa-trash-o"></span>
                                                  </button>
                                                </td>

                                                {/* <td class="text-center">
                      <div class="text-center">
                          <a class="tip" title="Edit Promo" href="http://localhost:3000/react/Promotions/Edit_Promos/33">
                              <i class="fa fa-edit"></i>
                          </a>
                          <a href="#" class="delete-promo" data-id="33">
                              <i class="fa fa-trash"></i>
                          </a>
                      </div>
                  </td> */}

                                              </tr>
                                            ))
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="row mb-3">
                                  <div className="col-12 col-lg-12 col-sm-12">
                                    <label
                                      for="name1"
                                      className="col-form-label"
                                    >
                                      Get Products{" "}
                                    </label>
                                    <div className="buyprct table">
                                      <table className="table table-bordered dataTable resizable">
                                        <thead>
                                          <tr>
                                            <th>Product (Code - Name) </th>
                                            <th>Type</th>
                                            
                                            <th>
                                              <span className="fa fa-trash"></span>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {getP.length === 0 ? (
                                            <tr style={{ textAlign: 'center' }}>
                                              <td colSpan={4}>No product added</td>
                                            </tr>
                                          ) : (
                                            getP.map((row) => (
                                              <tr key={row.id}>
                                                <td>
                                                  {row.code} - {row.name} - {row.size}
                                                  <input type="hidden" name="product2get_id[]" value={row.id} />
                                                  <input type="hidden" name="product2get[]" value={row.name} />
                                                  <input type="hidden" name="combo_item_code[]" value={row.code} />
                                                  <input
                                                    type="hidden"
                                                    name="quantity[]"
                                                    value={1}
                                                  />
                                                </td>
                                                <td style={{width : '85px'}}>
                                                  <select className="form-control" name="product_get_type[]">
                                                    {row.split == '0' && <option value="0">Box</option>}
                                                    {row.split == '1' && <option value="1">Piece</option>}
                                                    {row.split == '2' && (
                                                      <>
                                                        <option value="0">Box</option>
                                                        <option value="1">Piece</option>
                                                      </>
                                                    )}
                                                  </select>
                                                </td>
                                                
                                                <td class="text-center" style={{width : '35px'}}>
                                                  <button
                                                    type="button"
                                                    className="delete-promo"
                                                    onClick={() => handleRemove(row.id, 'get')}
                                                    style={{padding: '0px',fontSize: '19px',color: '#dc3545',background: 'none',border: 'none'}}
                                                     >
                                                    <span className="fa fa-trash-o"></span>
                                                  </button>
                                                </td>
                                              </tr>
                                            ))
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label className="col-form-label">
                                    Total Buy Qty <span style={{color : 'red'}}>*</span>
                                  </label>
                                  <input
                                    type="number"
                                    class="form-control no-arrows"
                                    name="total_buy_qty"
                                    autoComplete="off"
                                    value={formData.total_buy_qty}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-sm-12">
                                  {errors.total_buy_qty && <span className="text-danger">{errors.total_buy_qty}</span>}
                                </div>
                              </div>

                              <div class="col-md-3">
                                <div class="form-group">
                                  <label className="col-form-label">
                                    Total Get Qty <span style={{color : 'red'}}>*</span>
                                  </label>
                                  <input
                                    type="number"
                                    class="form-control no-arrows"
                                    name="total_get_qty"
                                    autoComplete="off"
                                    autoCapitalize="on"
                                    value={formData.total_get_qty}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-sm-12">
                                  {errors.total_get_qty && <span className="text-danger">{errors.total_get_qty}</span>}
                                </div>
                              </div>

                              <div class="col-md-3">
                                <div class="form-group">
                                  <label className="col-form-label">
                                    Sub type
                                  </label>
                                  <select
                                    id="inputState"
                                    name="subtype"
                                    class="form-select"
                                  >
                                    <option value="any">Any</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </form>
                        </>
                      )}

                      {promoSelected === 2 && (
                        <>
                          <form id="buynget_promotion_form">
                            <div className="row mb-3">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label  className="col-form-label"  htmlFor="add_item1"  >
                                    Add Product <span style={{color : 'red'}}>*</span>
                                  </label>
                                  <Select
                                  isClearable
                                  data-type="get_combo"
                                  onInputChange={productHandleInputChange}
                                  onChange={(e) =>
                                    productHandleChange(e, "buy_combo_1")
                                  }
                                  options={productFilteredOptions}
                                  placeholder="Select Product"
                                  className="promoselect"
                                />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    className="col-form-label"
                                    htmlFor="add_item2"
                                  >
                                    Add Product <span style={{color : 'red'}}>*</span>
                                  </label>
                                  <Select
                                  isClearable
                                  data-type="get_combo"
                                  onInputChange={productHandleInputChange}
                                  onChange={(e) =>
                                    productHandleChange(e, "get_combo_1")
                                  }
                                  options={productFilteredOptions}
                                  placeholder="Select Product"
                                  className="promoselect"
                                />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="row mb-3">
                                  <div className="col-12 col-lg-12 col-sm-12">
                                    <label
                                      for="name1"
                                      className="col-form-label"
                                    >
                                      Buy Products{" "}
                                    </label>
                                    <div className="buyprct table">
                                      <table className="table table-bordered dataTable resizable">
                                        <thead>
                                          <tr>
                                            <th>Product (Code - Name) </th>
                                            <th>Type</th>
                                            <th>Qty </th>
                                            <th>
                                              <span className="fa fa-trash"></span>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {buyP.length === 0 ? (
                                            <tr style={{ textAlign: 'center' }}>
                                              <td colSpan={4}>No product added</td>
                                            </tr>
                                          ) : (
                                            buyP.map((row) => (
                                              <tr key={row.id}>
                                                <td>
                                                  {row.code} - {row.name} - {row.size}
                                                  <input type="hidden" name="product2buy_id[]" value={row.id} />
                                                  <input type="hidden" name="product2buy[]" value={row.name} />
                                                  <input type="hidden" name="combo_item_code[]" value={row.code} />
                                                </td>
                                                <td style={{width : '85px'}}>
                                                  <select className="form-control" name="product_buy_type[]">
                                                    {row.split == '0' && <option value="0">Box</option>}
                                                    {row.split == '1' && <option value="1">Piece</option>}
                                                    {row.split == '2' && (
                                                      <>
                                                        <option value="0">Box</option>
                                                        <option value="1">Piece</option>
                                                      </>
                                                    )}
                                                  </select>
                                                </td>
                                                <td style={{width : '85px'}}>
                                                  <input
                                                    type="text"
                                                    style={{ textAlign: 'center' }}
                                                    name="quantity[]"
                                                  />
                                                </td  >
                                                <td class="text-center" style={{width : '35px'}}>
                                                  <button
                                                    type="button"
                                                    className="delete-promo"
                                                    onClick={() => handleRemove(row.id, 'buy')}
                                                    style={{padding: '0px',fontSize: '19px',color: '#dc3545',background: 'none',border: 'none'}}
                                                  >
                                                    <span className="fa fa-trash-o"></span>
                                                  </button>
                                                </td>
                                              </tr>
                                            ))
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="row mb-3">
                                  <div className="col-12 col-lg-12 col-sm-12">
                                    <label
                                      for="name1"
                                      className="col-form-label"
                                    >
                                      Get Products{" "}
                                    </label>
                                    <div className="buyprct table">
                                      <table className="table table-bordered dataTable resizable">
                                        <thead>
                                          <tr>
                                            <th>Product (Code - Name) </th>
                                            <th>Type</th>
                                            <th>Qty </th>
                                            <th>
                                              <span className="fa fa-trash"></span>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {getP.length === 0 ? (
                                            <tr style={{ textAlign: 'center' }}>
                                              <td colSpan={4}>No product added</td>
                                            </tr>
                                          ) : (
                                            getP.map((row) => (
                                              <tr key={row.id}>
                                                <td>
                                                  {row.code} - {row.name} - {row.size}
                                                  <input type="hidden" name="product2get_id[]" value={row.id} />
                                                  <input type="hidden" name="product2get[]" value={row.name} />
                                                  <input type="hidden" name="combo_item_code[]" value={row.code} />
                                                </td>
                                                <td style={{width : '85px'}}>
                                                  <select className="form-control" name="product_get_type[]">
                                                    {row.split == '0' && <option value="0">Box</option>}
                                                    {row.split == '1' && <option value="1">Piece</option>}
                                                    {row.split == '2' && (
                                                      <>
                                                        <option value="0">Box</option>
                                                        <option value="1">Piece</option>
                                                      </>
                                                    )}
                                                  </select>
                                                </td>
                                                <td style={{width : '85px'}}>
                                                  <input
                                                    type="text"
                                                    style={{ textAlign: 'center' }}
                                                    name="getquantity[]"
                                                  />
                                                </td>
                                                <td className="text-center" style={{width : '35px'}} >
                                                  <button
                                                    type="button"
                                                    className="delete-promo"
                                                    onClick={() => handleRemove(row.id, 'get')}
                                                    style={{padding: '0px',fontSize: '19px',color: '#dc3545',background: 'none',border: 'none'}}
                                                  >
                                                    <span className="fa fa-trash-o"></span>
                                                  </button>
                                                </td>
                                              </tr>
                                            ))
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </>
                      )}

                      {promoSelected === 3 && (
                        <>
                          <form id="inv_amt_promotion_form">
                            <div class="row mb-3">
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label className="col-form-label">
                                    Price <span style={{color: 'red'}}>*</span>
                                  </label>
                                  <input
                                    type="number"
                                    name="amount"
                                    class="form-control no-arrows"
                                    value={formData.amount}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-sm-12">
                                  {errors.amount && <span className="text-danger">{errors.amount}</span>}
                                </div>
                              </div>

                              <div class="col-md-3">
                                <div class="form-group">
                                  <label className="col-form-label">
                                    Type <span style={{color: 'red'}}>*</span>
                                  </label>
                                 

                                  <select id="inputState" name="gettype" class="form-select"
                                  value={inputInvoiceType}
                                    onChange={handleInvoiceSelectChange}
                                  >
                                    <option value="amount">Amount</option>
                                    <option value="percentage">Percentage</option>
                                  </select>
                                </div>
                              </div>

                              {inputInvoiceType === 'amount' && (
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label className="col-form-label">
                                    Amount <span style={{color: 'red'}}>*</span>
                                  </label>
                                  <input
                                    type="number"
                                    name="getamount"
                                    class="form-control no-arrows"
                                    value={formData.getamount}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-sm-12">
                                  {errors.getamount && <span className="text-danger">{errors.getamount}</span>}
                                </div>
                              </div>
                              )}

                              {inputInvoiceType === 'percentage' && (
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label className="col-form-label">
                                    Percentage  <span style={{color: 'red'}}>*</span>
                                  </label>
                                  <input
                                    type="number"
                                    name="percentage"
                                    class="form-control no-arrows"
                                    value={formData.percentage}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-sm-12">
                                  {errors.percentage && <span className="text-danger">{errors.percentage}</span>}
                                </div>
                              </div>
                              )}

                            </div>
                          </form>
                        </>
                      )}

                      {promoSelected === 4 && (
                        <>
                          <form id="product_disc_promotion_form">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="col-form-label">
                                    Add Product <span style={{color : 'red'}}>*</span>
                                  </label>
                                  <Select
                                    isClearable
                                    onInputChange={productHandleInputChange}
                                    onChange={(e) =>
                                      productHandleChange(e, "product_disc")
                                    }
                                    options={productFilteredOptions}
                                    placeholder="Select Product"
                                    className="promoselect"
                                  />
                                </div>
                              </div>

                              <div class="col-md-3">
                                <div class="form-group">
                                  <label className="col-form-label">
                                    Type <span style={{color : 'red'}}>*</span>
                                  </label>
                                  <select id="inputState" name="gettype1" class="form-select"
                                  value={inputType}
                                    onChange={handleSelectChange}
                                  >
                                    <option value="amount">Amount</option>
                                    <option value="percentage">Percentage</option>
                                  </select>
                                </div>
                              </div>

                              {inputType === 'amount' && (
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label className="col-form-label">
                                    Amount <span style={{color : 'red'}}>*</span>
                                  </label>
                                  <input
                                    type="number"
                                    name="getamount1"
                                    class="form-control no-arrows"
                                    value={formData.getamount1}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-sm-12">
                                  {errors.getamount1 && <span className="text-danger">{errors.getamount1}</span>}
                                </div>
                              </div>
                               )}

                               {inputType === 'percentage' && (
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label className="col-form-label">
                                    Percentage <span style={{color : 'red'}}>*</span>
                                  </label>
                                  <input
                                    type="number"
                                    name="percentage1"
                                    class="form-control no-arrows"
                                    value={formData.percentage1}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-sm-12">
                                  {errors.percentage1 && <span className="text-danger">{errors.percentage1}</span>}
                                </div>
                              </div>
                              )}

                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="row mb-3">
                                  <div className="col-12 col-lg-12 col-sm-12">
                                    <label
                                      for="name1"
                                      className="col-form-label"
                                    >
                                      Products
                                    </label>
                                    <div className="buyprct table">
                                      <table className="table table-bordered dataTable resizable">
                                        <thead>
                                          <tr>
                                            <th>Product (Code - Name) </th>
                                            <th>Type</th>
                                            <th>
                                              <span className="fa fa-trash"></span>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {productList.length === 0 ? (
                                            <tr style={{ textAlign: 'center' }}>
                                              <td colSpan={4}>No product added</td>
                                            </tr>
                                          ) : (
                                            productList.map((row) => (
                                              <tr key={row.id}>
                                                <td>
                                                  {row.code} - {row.name} - {row.size}
                                                  <input type="hidden" name="product2buy_id[]" value={row.id} />
                                                  <input type="hidden" name="product2buy[]" value={row.name} />
                                                  <input type="hidden" name="combo_item_code[]" value={row.code} />
                                                </td>
                                                <td style={{width : '85px'}}>
                                                  <select className="form-control" name="product_buy_type[]">
                                                    {row.split == '0' && <option value="0">Box</option>}
                                                    {row.split == '1' && <option value="1">Piece</option>}
                                                    {row.split == '2' && (
                                                      <>
                                                        <option value="0">Box</option>
                                                        <option value="1">Piece</option>
                                                      </>
                                                    )}
                                                  </select>
                                                </td>
                                                <td className="text-center" style={{width : '35px'}}>
                                                  <button
                                                    type="button"
                                                    className="delete-promo"
                                                    onClick={() => handleRemove(row.id, 'productDisc')}
                                                    style={{padding: '0px',fontSize: '19px',color: '#dc3545',background: 'none',border: 'none'}}
                                                  >
                                                    <span className="fa fa-trash-o"></span>
                                                  </button>
                                                </td>
                                              </tr>
                                            ))
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </>
                      )}

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="col-form-label" htmlFor="description">Description</label>
                            <textarea
                              name="description"
                              className="form-control skip"
                              id="description"
                              style={{ height: "100px" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-12 col-sm-12">
                        <hr />
                      </div>

                      <div className="row mb-3 padd-l-13px">
                        <div className="col-12 col-lg-12 col-sm-12">
                          <a
                            href="#"
                            onClick={CreatePromotion}
                            class="float-right-btn common-popup-btn"
                          >
                            Submit
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Add_Promos;
