import React, { useState,useRef } from 'react';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import { API_URL } from '../Config'; // Make sure to import your API URL from your config
import config from "../common/Config"; // Assuming this is where your config for token/API headers is stored

const Opening_Balance = ({ tableRows, totalVal, customerDetails, companyAddress }) => {
  const [value, setValue] = useState(''); // For the calculator's expression
  const [focusedDenomination, setFocusedDenomination] = useState(null); // To track which input field is focused
  const [denominationValues, setDenominationValues] = useState({
    hundred: 0,
    fifty: 0,
    twenty: 0,
    ten: 0,
    five: 0,
    two: 0,
    one: 0,
    fiftyCents: 0,
    twentyCents: 0,
    tenCents: 0,
    fiveCents: 0,
    twoCents: 0,
    oneCent: 0,
    other: 0 // "Other" denomination for custom input
  });

  const modalRef = useRef(null);
  const today = new Date();
  const formattedDate = today.toLocaleDateString();
   const accessToken = localStorage.getItem('accessToken');

  // Handle changes in the input fields
  const handleDenominationChange = (denomination, amount) => {
    setDenominationValues(prevState => ({
      ...prevState,
      [denomination]: amount
    }));
  };

  // Set the focused denomination input
  const handleInputFocus = (denomination) => {
    setFocusedDenomination(denomination);
  };

  // Function to handle button clicks on the calculator
  const handleButtonClick = (buttonValue) => {
    if (focusedDenomination) {
      // Add the number to the focused denomination input
      setDenominationValues(prevState => {
        const updatedValue = prevState[focusedDenomination] + buttonValue;
        return {
          ...prevState,
          [focusedDenomination]: parseFloat(updatedValue)
        };
      });
    } else {
      // If no input is focused, just update the expression value
      setValue(value + buttonValue);
    }
  };

  // Calculate total based on the denominations
  const calculateTotal = () => {
    return (
      denominationValues.hundred * 100 +
      denominationValues.fifty * 50 +
      denominationValues.twenty * 20 +
      denominationValues.ten * 10 +
      denominationValues.five * 5 +
      denominationValues.two * 2 +
      denominationValues.one * 1 +
      denominationValues.fiftyCents * 0.5 +
      denominationValues.twentyCents * 0.2 +
      denominationValues.tenCents * 0.1 +
      denominationValues.fiveCents * 0.05 +
      denominationValues.twoCents * 0.02 +
      denominationValues.oneCent * 0.01 +
      denominationValues.other
    ).toFixed(2); // Ensure two decimal points for cents
  };

  // Function to submit the data to the API
  const submitData = async () => 
  {

  const userId = localStorage.getItem('userId');
  const formData = new FormData();

  // Append each value to FormData
  formData.append('user_id', userId);
  formData.append('hundred', denominationValues.hundred);
  formData.append('fifty', denominationValues.fifty);
  formData.append('twenty', denominationValues.twenty);
  formData.append('ten', denominationValues.ten);
  formData.append('five', denominationValues.five);
  formData.append('two', denominationValues.two);
  formData.append('one', denominationValues.one);
  formData.append('fiftyCents', denominationValues.fiftyCents);
  formData.append('twentyCents', denominationValues.twentyCents);
  formData.append('tenCents', denominationValues.tenCents);
  formData.append('fiveCents', denominationValues.fiveCents);
  formData.append('twoCents', denominationValues.twoCents);
  formData.append('oneCent', denominationValues.oneCent);
  formData.append('other', denominationValues.other);
  formData.append('total', calculateTotal()); // Include the calculated total

  try {
    const response = await fetch(API_URL + '/epos-submit-opening-balance', {    
      method: 'POST',
        headers: {
        //  'Content-Type': 'application/json',
          'authorization': `Bearer ${accessToken}` 
        },
      body: formData, // Directly set FormData as the body
    });

    if (response.ok) {
      const result = await response.json();
      alert('Data submitted successfully!');
      console.log(result); // Handle API response if needed

       // Close the modal after successful submission
       const bsModal = window.bootstrap.Modal.getInstance(modalRef.current);
          bsModal.hide();
    } else {
      const errorData = await response.json();
      alert('Error: ' + errorData.message);
    }
  } catch (error) {
    alert('Error submitting data: ' + error.message);
  }
};


  return (
    <>
      <div className="container">
        <div className="modal fade" id="opening_balance" tabIndex="-1" ref={modalRef}>
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Float In</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body" id="modal-body-content">
                <div className="opening-balance-popup">
                  <p>Hi, Impos Release Notes. Please process the float in.</p>

                  <div className="cashbalancebox">
                    <h4><span>Money</span></h4>
                    <div className="row">
                      <div className="col-6 col-lg-6 col-sm-6">
                        <table className="table-borderless resizable">
                          <tbody>
                            {/* Denomination Rows */}
                            {[
                              { label: '£ 100', value: 'hundred', factor: 100 },
                              { label: '£ 50', value: 'fifty', factor: 50 },
                              { label: '£ 20', value: 'twenty', factor: 20 },
                              { label: '£ 10', value: 'ten', factor: 10 },
                              { label: '£ 5', value: 'five', factor: 5 },
                              { label: '£ 2', value: 'two', factor: 2 },
                              { label: '£ 1', value: 'one', factor: 1 },
                              { label: '£ .50', value: 'fiftyCents', factor: 0.5 },
                              { label: '£ .20', value: 'twentyCents', factor: 0.2 },
                              { label: '£ .10', value: 'tenCents', factor: 0.1 },
                              { label: '£ .05', value: 'fiveCents', factor: 0.05 },
                              { label: '£ .02', value: 'twoCents', factor: 0.02 },
                              { label: '£ .01', value: 'oneCent', factor: 0.01 },
                            ].map(({ label, value, factor }) => (
                              <tr key={value}>
                                <td>{label}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={denominationValues[value]}
                                    onChange={(e) => handleDenominationChange(value, parseFloat(e.target.value) || 0)}
                                    onFocus={() => handleInputFocus(value)}
                                  />
                                </td>
                                <td>=</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={(denominationValues[value] * factor).toFixed(2)}
                                    disabled
                                  />
                                </td>
                              </tr>
                            ))}

                            {/* "Other" Row */}
                            <tr>
                              <td>Other</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={denominationValues.other}
                                  onChange={(e) => handleDenominationChange('other', parseFloat(e.target.value) || 0)}
                                />
                              </td>
                              <td>=</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={denominationValues.other.toFixed(2)}  // Ensure it displays to 2 decimal places
                                  disabled
                                />
                              </td>
                            </tr>

                            {/* Total Row */}
                            <tr>
                              <td>Total</td>
                              <td>&nbsp;</td>
                              <td>=</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={calculateTotal()}
                                  disabled
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      {/* Calculator */}
                      <div className="col-6 col-lg-6 col-sm-6">
                        <div className="calculator">
                          <form action="">
                            {/* Calculator Buttons */}
                            <div>
                              <input type="button" className='calcyinput' value="7" onClick={() => handleButtonClick('7')} />
                              
                              <input type="button" className='calcyinput' value="8" onClick={() => handleButtonClick('8')} />
                             
                              <input type="button" className='calcyinput' value="9" onClick={() => handleButtonClick('9')} />
                              
                            </div>
                            <div>
                              <input type="button" className='calcyinput' value="4" onClick={() => handleButtonClick('4')} />
                              <input type="button" className='calcyinput' value="5" onClick={() => handleButtonClick('5')} />
                              <input type="button" className='calcyinput' value="6" onClick={() => handleButtonClick('6')} />
                            </div>
                            <div>
                              <input type="button" className='calcyinput' value="1" onClick={() => handleButtonClick('1')} />
                              <input type="button" className='calcyinput' value="2" onClick={() => handleButtonClick('2')} />
                              <input type="button" className='calcyinput' value="3" onClick={() => handleButtonClick('3')} />
                            </div>
                            <div>
                              <input type="button" value="0" className='calcyinput' onClick={() => handleButtonClick('0')} />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <button onClick={submitData} className="float-right-btn common-popup-btn">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Opening_Balance;
