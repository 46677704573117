import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { format } from 'date-fns';
import CustomDateRangePicker from '../common/CustomDateRangePicker';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";

const SalesOrderHistory = () => {
  const [salesDates, setSalesDates] = useState([null, null]);
  const [grandTotal, setGrandTotal] = useState('');
  const [currentGrandTotal, setCurrentGrandTotal] = useState('');
  const salesOrderRef = useRef(null);
  const accessToken = localStorage.getItem('accessToken');
  const [searchValue, setSearchValue] = useState([]);

  // Handle form submit, set date range and trigger getSalesOrderList immediately
  const handleSubmit = (e) => {
    e.preventDefault();

    // Immediately get startDate and endDate from salesDates
    const [startDate, endDate] = salesDates;

    // Format start and end dates (if selected)
    let formattedStartDate = startDate ? format(startDate, 'MM/dd/yyyy') : '';
    let formattedEndDate = endDate ? format(endDate, 'MM/dd/yyyy') : '';

    // Call the data fetching function with the selected dates
    getSalesOrderList(formattedStartDate, formattedEndDate);
  };

  // Fetch sales order list from API
  const getSalesOrderList = (startDate, endDate) => {
    const $table = $(salesOrderRef.current);
    const searchValue = $('#searchValue').val();

    // Destroy any existing DataTable instance to reinitialize with new data
    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }

    // Initialize the DataTable with the new date range
    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${config.API_URL}/order/history`,
        type: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        data: function (d) {
          return $.extend({}, d, {
            draw: d.draw,
            start: d.start,
            length: d.length,
            searchValue: searchValue,
            searchByFromdate: startDate,
            searchByTodate: endDate,
            authorization: `Bearer ${accessToken}`,
          });
        },
        dataSrc: function (json) {
          if (json && json.data && Array.isArray(json.data)) {
            setGrandTotal(json.total_grand_total);
            setCurrentGrandTotal(json.total_grand_total1);
          }

          return Array.isArray(json.data) ? json.data : [];
        },
      },
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
      columns: [
        { data: 'date' },
        {
          data: 'reference_no',
          render: (data, type, row) => {
            return `<a href="${config.BASE_URL}/order_summary/print/${row.id}">${row.reference_no}</a>`;
          },
        },
        {
          data: 'customer',
          render: (data) => {
            return `<span title="${data}" class="mightOverflow col-xs-1">${data}</span>`;
          },
        },
        { data: 'grand_total' },
        { data: 'grand_total1' },
        { data: 'previous_due' },
        { data: 'grand_total' },
        { data: 'route_number' },
        {
          data: null,
          render: (data, type, row) => {
            let badgeColor = "";
        
            switch (row.sale_status) {
              case "Rejected":
                badgeColor = "bg-warning text-dark"; // Yellow
                break;
              case "Intransit":
                badgeColor = "bg-success text-white"; // Green
                break;
              case "Undelivered":
                badgeColor = "bg-danger text-white"; // Red
                break;
              default:
                badgeColor = "bg-secondary text-white"; // Gray
                break;
            }
        
            return `<span class="badge ${badgeColor}">${row.sale_status}</span>`;
          },
        },
      ],
      columnDefs: [
        {
          orderable: false,
          targets: '_all',
        },
        {
          targets: 2,
          render: function (data) {
            return data === '1' ? 'Active' : 'Inactive';
          },
        },
      ],

      pageLength: 10,
    });
  };

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;

      return;
    }

    // Get sales order list with empty dates on initial load
    getSalesOrderList('', '');

    return () => {
      if (salesOrderRef.current && $.fn.DataTable.isDataTable(salesOrderRef.current)) {
        $(salesOrderRef.current).DataTable().destroy();
      }
    };
  }, [searchValue]);

  return (
    <>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#">
                <i className="fa fa-home"></i>
              </a>{' '}
              &gt; <a href="#">Dashboard</a> &gt; <span className="last-crumb">Sales History</span>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div>
                <div className="col-md-12 col-lg-12 col-xs-12">
                  <div className="col-md-12 col-xs-12">
                    <div className="row">
                      <div className="col-md-12 col-lg-6 col-sm-6">
                        <CustomDateRangePicker dates={salesDates} onChange={setSalesDates} onSubmit={handleSubmit} />
                      </div>
                      
                    <div className="col-md-6 col-xs-6" style={{ paddingTop: '7px' }}>
                      <div className="col-md-2 col-lg-6 col-sm-6" style={{ float: 'left', textAlign: 'center' }}>
                        <label style={{ marginBottom: '5px', float: 'left', width: '100%' }}><b>Order Grand Total:</b></label>{grandTotal}
                      </div>
                      <div className="col-md-2 col-lg-6 col-sm-6" style={{ float: 'left', textAlign: 'center' }}>
                        <label style={{ marginBottom: '5px', float: 'left', width: '100%' }}><b>Current Grand Total:</b></label>{currentGrandTotal}
                      </div>
                    </div>
                    </div>
                  </div>
                </div>

                <div className="total-manifests-area dashboard">
                  <div className="col-md-12 col-lg-12 col-xs-12">
                    <div className="verical-scrl-wrapper order-history-table" id="style-3">
                      <table className="table table-bordered dataTable resizable" ref={salesOrderRef}>
                        <thead>
                          <tr>
                            <th>Order Date</th>
                            <th>Order ID</th>
                            <th>Customers (A/C No)</th>
                            <th>Order Amount</th>
                            <th>Current Amount</th>
                            <th>Previous Dues</th>
                            <th>Total Due</th>
                            <th>Route</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* DataTable will populate this dynamically */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SalesOrderHistory;
