import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";
import Swal from 'sweetalert2';

const SupplierSuggestionList = ({ supplierArray = [] }) =>{

	const accessToken = localStorage.getItem('accessToken'); 

	const [supplierOptions, setSupplierOptions] = useState([]);
    const [supplierFilteredOptions, setSupplierFilteredOptions] = useState([]);
    const [productValue, setProductValue] = useState(null);  
    const [productInputValue, setProductInputValue] = useState('');
    const [newProductList, setNewProductList] = useState([]);

    const [tableRows, setTableRows] = useState([]);
  


	const supplierHandleInputChange = (inputValue) => {
        setProductInputValue(inputValue);
        const newFilteredOptions = supplierOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setSupplierFilteredOptions(newFilteredOptions);
    };

   

    const supplierHandleChange = (selectedOption) => {
        
        if (selectedOption ) {
            setProductValue(selectedOption);
            fetchAddSupplierInfo(selectedOption.value);
        } else {
            setProductValue(null); 
        }
    };

    const fetchAddSupplierInfo = async (selectedOption) => {
        const formData = new FormData();
        formData.append('id', selectedOption);

        const existingProduct = tableRows.find(item => item.id === selectedOption);

        if (existingProduct) {
            //alert('Supplier already exists in the list.');
            Swal.fire({
                title: "Failed!",
                text: "Supplier already exists in the list",
                icon: "error",
                timer: 1500,
                showConfirmButton: false,
            });
            return;
        }

        const apiUrl = `${config.API_URL}/get_supplier_byId`;
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to fetch new product');
            }

            const data = await response.json();
            const supplierData = data.data;
           

            setNewProductList(data.supplierData);

 

            const newRow = {
                
                id: supplierData.id,
                company : supplierData.company,
            };


            setTableRows((prevRows) => [...prevRows, newRow]);

        } catch (error) {
            console.error('API fetch error test:', error);
        }
    };

    const fetchSupplierList = async () => {

        const apiSalesRouteUrl = `${config.API_URL}/purchases/getSupplier`;
        try {
            const response = await fetch(apiSalesRouteUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            });

            const data = await response.json();
            const formattedOptions = data.supplier.map(item => ({
                label: item.company,
                value: item.id,
            }));
            setSupplierOptions(formattedOptions);
            setSupplierFilteredOptions(formattedOptions);

        } catch (error) {
            console.error('api fail error fetching supplier');
        }
    };

    const handleCancelSupplier = (id) => {
        setTableRows(prevRows => prevRows.filter(row => row.id !== id));
    };

  	useEffect(() => {

  		if (accessToken === null){
            window.location.href = `${config.BASE_URL}/login`; 
            return;
        }
        fetchSupplierList();

  	},[]);

    useEffect(() => {
        if (supplierArray.length > 0) {
            const initialRows = supplierArray.map(supplier => ({
                id: supplier.supplier_id,
                company: supplier.company,
            }));
            setTableRows(initialRows);
        }
    }, [supplierArray]);

	return(
		<>
			<div>
				<div className="col-md-12 col-lg-12 col-sm-12" >	    
	                <Select
			            isClearable
			            onInputChange={supplierHandleInputChange}
			            onChange={supplierHandleChange}
			            options={supplierFilteredOptions}  // Pass the filtered options here
			            placeholder="Select Supplier"
			            value={productValue} className="promoselect abovedd"
			        />
				</div>
                <div>
				<div className="total-manifests-area dashboard" style={{position : 'relative'}} >
                    
                    
                    <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
                        <table className="table table-bordered dataTable resizable" id="newProductTable" >
                            <thead>
                                <tr>
                                    <th style={{zIndex : '9', width: "50%"}}>Supplier</th>
                                    <th style={{zIndex : '9', width: "50%"}}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows.length === 0 ? (
                                    <tr style={{ textAlign: "center" }}>
                                        <td colSpan={9}>No supplier added</td>
                                    </tr>
                                ) : (
                                    tableRows.map(row => (
                                        <tr key={row.id}>
                                            <input type="hidden" value={row.id} name="supplier_id[]" />
                                            <td>{row.company}</td>
                                            
                                            <td>
                                                <button
                                                    className="btn btn-danger cancel-btn"
                                                   onClick={() => handleCancelSupplier(row.id)}

                                                   style={{padding: '0px',fontSize: '19px',color: '#dc3545',background: 'none',border: 'none'}}
                                                >
                                                <i class="fa fa-trash-o" ></i>
                                                    
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>

                    
                </div>
</div>



			</div>


		</>
	);

}
export default SupplierSuggestionList;
