import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import AccountService from '../../services/AccountServices';
import CustomDateRangePicker from '../common/CustomDateRangePicker';
import { API_URL, BASE_URL } from '../Config';
import config from '../common/Config';
import $ from 'jquery';
import Swal from 'sweetalert2';

const PaymentProcess = () => {
	const [reportrange, setReportrange] = useState('');
	const [dates, setDates] = useState([null, null]);
	const [draw, setDraw] = useState(1);
	const [start, setStart] = useState(0);
	const [length, setLength] = useState(5000);
	const accessToken = localStorage.getItem('accessToken');
	const user_id = localStorage.getItem('userId');
	
	const [receivedPayment, setReceivedPayment] = useState([]);
	const [paymentHistory, setPaymentHistory] = useState([]);

	const [phReportrange, setPhReportrange] = useState('');
	const [paymentDates, setPaymentDates] = useState([null, null]);

	const [shouldLoadPayments, setShouldLoadPayments] = useState(false);
	const [isInitialLoad, setIsInitialLoad] = useState(true); // Track initial load

	const [checkedItems, setCheckedItems] = useState({});
	const [checkedValues, setCheckedValues] = useState([]);

	const searchFormData = () => {
		let startDate = dates[0] ? format(dates[0], 'MM/dd/yyyy') : '';
		let endDate = dates[1] ? format(dates[1], 'MM/dd/yyyy') : '';
		setReportrange(startDate ? startDate + ' - ' + endDate : '');
		setShouldLoadPayments(true);
	};

	const searchPHFormData = () => {
		let startDate = paymentDates[0] ? format(paymentDates[0], 'MM/dd/yyyy') : '';
		let endDate = paymentDates[1] ? format(paymentDates[1], 'MM/dd/yyyy') : '';
		setPhReportrange(startDate ? startDate + ' - ' + endDate : '');
		setShouldLoadPayments(true);
	};


	

	useEffect(() => {
		if (accessToken === null) {
			window.location.href = `${config.BASE_URL}/login`;
			return;
		}
		if (isInitialLoad) {
			loadReceivedPaymentList();
			loadPaymentHistoryList();
			setIsInitialLoad(false);
		} else if (shouldLoadPayments) {
			loadReceivedPaymentList();
			loadPaymentHistoryList();
			setShouldLoadPayments(false);
		}
	}, [shouldLoadPayments, accessToken, isInitialLoad]);

	const loadReceivedPaymentList = async () => {
		try {
			const data = await AccountService.fetchReceivedPaymentList(draw, start, length, reportrange, accessToken);
			console.log(data);
			setReceivedPayment(data); // Store the fetched data in state
		} catch (error) {
			console.error('Failed to load received payment list:', error);
		}
	};

	const loadPaymentHistoryList = async () => {
		try {
			const data = await AccountService.fetchPaymentHistoryList(draw, start, length, phReportrange, accessToken);
			//console.log(data);
			setPaymentHistory(data);
		} catch (error) {
			console.log('Failed to load payment history list', error);
		}
	}

	useEffect(() => {
		const table = $('#receivedPaymentList').DataTable({
			pageLength: 10,
			paging: true,
			lengthMenu: [10, 20, 50],
			searching: true,
			ordering: false,
			info: true,
			responsive: true,
			autoWidth: false,
			language: {
				paginate: {
					first: 'First',
					last: 'Last',
					next: 'Next',
					previous: 'Previous',
				},
				search: 'Search:',
			},
			data: receivedPayment,
			columns: [
				{
					data: null,
					width: '4%',
					className: 'text-center',
					render: function (data, type, row) {
						const isChecked = !!checkedItems[row.route];
						return `
		              <label class="checkbox" style="margin: 0px auto;float: none;">
		                <input 
		                  type="checkbox" 
		                  name="val[]" 
		                  value="${row.id}" 
		                  class="dataTableCheckbox"
		                />
		                <span class="default"></span>
		              </label>
		            `;
					}
				},
				{ data: 'date', className: 'text-start', width: '10%' },
				{ data: 'customer' },
				{ data: 'route' },
				{ data: 'collected_by' },
				{ data: 'mode' },
				{ data: 'amount' },


			],
			columnDefs: [

			]
		});

		$(document).off('change', '#receivedPaymentList .dataTableCheckbox').on('change', '#receivedPaymentList .dataTableCheckbox', function() {
     
	      const value = $(this).val();
	      const checked = $(this).is(':checked');
		    setCheckedValues(prevState => {
		      if (checked) {
		        // Add value if checked
		        return [...prevState, value];
		      } else {
		        // Remove value if unchecked
		        return prevState.filter(val => val !== value);
		      }
		    });

	    });



		return () => {
			table.destroy();
		};
	}, [receivedPayment]);

	useEffect(() => {
		const table = $('#paymentHistoryList').DataTable({
			pageLength: 10,
			paging: true,
			lengthMenu: [10, 20, 50],
			searching: true,
			ordering: false,
			info: true,
			responsive: true,
			autoWidth: false,
			language: {
				paginate: {
					first: 'First',
					last: 'Last',
					next: 'Next',
					previous: 'Previous',
				},
				search: 'Search:',
			},
			data: paymentHistory,
			columns: [

				{ data: 'date', className: 'text-start', width: '10%' },
				{ data: 'name' },
				{ data: 'route' },
				{ data: 'driver' },
				{ data: 'payment_mode' },
				{ data: 'amount' },


			],
			columnDefs: [

			]
		});



		return () => {
			table.destroy();
		};
	}, [paymentHistory]);

	const [activeTab, setActiveTab] = useState('nav-home');

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	const handelAcceptPayment = async () =>{
		if (checkedValues.length > 0) {

			const result = await Swal.fire({
		      title: 'Are you sure?',
		      text: 'Do you want allocate payment ?',
		      icon: 'warning',
		      showCancelButton: true,
		      confirmButtonText: 'Yes',
		      cancelButtonText: 'No',
		    });
		    if (result.isConfirmed) {
		      
		     

				const formData = new FormData();
				
				formData.append('user_id', user_id);
				checkedValues.forEach((value, index) => {
				  formData.append('id[]', value);
				});

		
				const apiAddOrdersUrl = `${config.API_URL}/payment/assign_to_manager`;
			  	try {
			      const response = await fetch(apiAddOrdersUrl, {
			      		method: 'POST',
			      		headers: {
			        		authorization: `Bearer ${accessToken}`,
			      		},
			      		body: formData,
			    		});

			      if (!response.ok) {
			        
			        Swal.fire({
			          title: 'Error',
			          text: 'something went wrong, try again.',
			          icon: 'error',
			          showConfirmButton: false, 
			          timer: 2000,               
			          timerProgressBar: true,     
			        });
			      }
			      const data = await response.json();
			      console.log(data);
			      if(data.success){
			      	

			      	Swal.fire({
			            title: "Success!",
			            text: "Payment received successfully.",
			            icon: "success",
			            timer: 1500,
			            showConfirmButton: false,
			          }).then(() => 
			          {
			            setCheckedValues([]);
			             window.location.href = `${config.BASE_URL}/account/payments`;
			            
			          });

			
			      }else{
			      	Swal.fire({
			          title: 'Error',
			          text: 'something went wrong, try again.',
			          icon: 'error',
			          showConfirmButton: false, 
			          timer: 2000,               
			          timerProgressBar: true,     
			        });
			      }


			      
			    } catch (error) {
			      	console.error('Error Accept Order:', error);
			    }
				
		   	}
			
		}else{
			//console.log('please select order to create manifest');
			//setFlashMessage({ message: 'please select order to create manifest', type: 'error' });

			Swal.fire({
	          title: 'Error',
	          text: 'Please select payment.',
	          icon: 'error',
	          showConfirmButton: false, 
	          timer: 2000,               
	          timerProgressBar: true,     
	        });
		}
	} 

	return (
		<>
			<Header />
			<div className="breadcrumbs">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 col-lg-12 col-sm-12">
							<Link to="/Dashboard"><i className="fa fa-home"></i></Link>
							<Link to="/account/push_invoice">Account</Link>
							<Link to="/account/push_invoice">Payments</Link>
						</div>
					</div>
				</div>
			</div>

			<div className="main-container">
				<div className="container-fluid">
					<div style={{ position: 'relative' }}>
						<nav>
							<div className="nav nav-tabs" id="nav-tab" role="tablist">
								<button
									className={`nav-link ${activeTab === 'nav-home' ? 'active' : ''}`}
									id="nav-home-tab"
									onClick={() => handleTabClick('nav-home')}
									role="tab"
									aria-controls="nav-home"
									aria-selected={activeTab === 'nav-home'}
								>
									Received Payment
								</button>
								<button
									className={`nav-link ${activeTab === 'nav-profile' ? 'active' : ''}`}
									id="nav-profile-tab"
									onClick={() => handleTabClick('nav-profile')}
									role="tab"
									aria-controls="nav-profile"
									aria-selected={activeTab === 'nav-profile'}
								>
									Payment History
								</button>

							</div>
						</nav>

						<div className="tab-content" id="nav-tabContent">

							<div className={`tab-pane fade ${activeTab === 'nav-home' ? 'active show' : ''}`} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" >

								

								<div className="backlist-content-area">	
								<button type="button" onClick={handelAcceptPayment} className="acceptbtn" style={{ float: 'right' }} >Accept Payment</button>
								<div>
									<div className="col-md-9 col-lg-9 col-sm-12">
										<CustomDateRangePicker dates={dates} onChange={setDates} onSubmit={searchFormData} />
									</div>
								</div>
								<div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
									<table className="table table-bordered dataTable resizable" id="receivedPaymentList">
										<thead>
											<tr>
												<th>#</th>
												<th>Date</th>
												<th>Customer Name(A/C No)</th>
												<th>Route</th>
												<th>Collected By</th>
												<th>Mode</th>
												<th>Collected Amt</th>
											</tr>
										</thead>
										<tbody>

										</tbody>
									</table>
								</div>
							</div>

							</div>

							<div className={`tab-pane fade ${activeTab === 'nav-profile' ? 'active show' : ''}`} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" >
							<div className="backlist-content-area">	
								<div className="row" >
									<div className="col-md-9 col-lg-9 col-sm-12">
										<CustomDateRangePicker dates={paymentDates} onChange={setPaymentDates} onSubmit={searchPHFormData} />
									</div>
								</div>
								<div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
									<table className="table table-bordered dataTable resizable" id="paymentHistoryList">
										<thead>
											<tr>
												<th>Date</th>
												<th>Customer Name(A/C No)</th>
												<th>Route</th>
												<th>Collected By</th>
												<th>Mode</th>
												<th>Collected Amt</th>
											</tr>
										</thead>
										<tbody>

										</tbody>
									</table>
								</div>
</div>




							</div>



							
						</div>

					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default PaymentProcess;
