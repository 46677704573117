import React, { useState, useEffect, useRef, useCallback  } from 'react';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'select2/dist/js/select2.min'; // Ensure Select2 is imported
import 'select2/dist/css/select2.min.css';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";

const StartStockTake = () => {

	const [pageOptions, setPageOptions] = useState([
    	{ value: 'categories', label: 'Categories' },
    	{ value: 'brands', label: 'Brands' },
    	{ value: 'all', label: 'All' }
  	]);
  	const [pageFilterValue, setPageFilterValue] = useState('categories');
  	const [categoryOptions, setCategoryOptions] = useState([]);
  	const [categoryValue, setCategoryValue] = useState([]);
  	const [brandOptions, setBrandOptions] = useState([]);
  	const [brandValue, setBrandValue] = useState([]);
  	const accessToken = localStorage.getItem('accessToken');
  	const [flashMessage, setFlashMessage] = useState(null);


  	const handleCloseMessage = () => {
	    setFlashMessage(null);
	};

  	const handlePageChange = (event) => {
    	const value = event.target.value;
    	setPageFilterValue(value);
    	setCategoryValue('');
    	setBrandValue('');
  	};

  	const handleCategoryChange = (event) => {
    	const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
        setCategoryValue(selectedOptions);
  	};

  	const handleBrandChange = (event) => {
    	const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
        setBrandValue(selectedOptions);
  	};

  	const fetchCategoriesOptions = async () => {
    	const apiCategoriesUrl = `${API_URL}/stock_take/getCategories`;
    	try {
      		const response = await fetch(apiCategoriesUrl, {
        		method: 'POST',
        		headers: {
          			authorization: `Bearer ${accessToken}`,
        		},
      		});
      		const data = await response.json();
      		const formattedOptions = data.category.map(item => ({
        		value: item.id,
        		label: item.name,
      		}));
      		setCategoryOptions(formattedOptions);
    	} catch (error) {
      		console.error('API fail Error:', error);
    	}
  	};

	const fetchBrandOptions = async () => {
	    const apiBrandsUrl = `${API_URL}/stock_take/getBrands`;
	    try {
	      	const response = await fetch(apiBrandsUrl, {
	        	method: 'POST',
	        	headers: {
	          		authorization: `Bearer ${accessToken}`,
	        	},
	      	});
	      	const data = await response.json();
	      	const formattedOptions = data.brands.map(item => ({
	        	value: item.id,
	        	label: item.name,
	      	}));
	      	setBrandOptions(formattedOptions);
	    } catch (error) {
	      console.error('API fail Error:', error);
	    }
	};

	useEffect(() => {
	    if (accessToken === null) {
	      window.location.href = `${config.BASE_URL}/login`;
	      return;
	    }

	    fetchCategoriesOptions();
	    fetchBrandOptions();
	}, [accessToken]);

  	const categoryRef = useCallback(node => {
    	if (node !== null) {
      		$(node).select2({
	       		placeholder: 'Select Categories',
	        	width: '100%',
	        	tags: true,
	      	}).on('change', handleCategoryChange);
    	}
		return () => {
      		if (node) {
        		$(node).select2('destroy');
      		}
    	};
  	}, [categoryOptions]);

  	const brandRef = useCallback(node => {
    	if (node !== null) {
      		$(node).select2({
        		placeholder: 'Select Brands',
        		width: '100%',
        		tags: true,
      		}).on('change', handleBrandChange);
    	}
    	return () => {
	      	if (node) {
	        	$(node).select2('destroy');
	      	}
    	};
  	}, [brandOptions]);

  	useEffect(() => {
    	if (pageFilterValue === 'categories' && categoryRef.current) {
      		$(categoryRef.current).select2();
    	}

    	if (pageFilterValue === 'brands' && brandRef.current) {
      		$(brandRef.current).select2();
    	}

    	return () => {
      		if (categoryRef.current) {
        		$(categoryRef.current).select2('destroy');
      		}
      		if (brandRef.current) {
        		$(brandRef.current).select2('destroy');
      		}
    	};
  	}, [pageFilterValue, categoryOptions, brandOptions]);


  	const handelStartStack = async () => {
  		console.log(pageFilterValue);

  		const formData = new FormData();
  		if(pageFilterValue === 'categories'){
  			if (categoryValue.length > 0) {
			 	categoryValue.forEach(value => {
	                formData.append('category[]', value);
	            });
			}else{
				setFlashMessage({ message: 'Please select categories to start stack track', type: 'error' });
				return;
			}
  		}else if(pageFilterValue === 'brands'){
  			if (brandValue.length > 0) {
			 	brandValue.forEach(value => {
	                formData.append('brand[]', value);
	            });
			}else{
				setFlashMessage({ message: 'Please select brand to start stack track', type: 'error' });
				return;
			}
  		}else if(pageFilterValue === 'all'){
  			formData.append('all', 'all');
  		}
  		const apiAddOrdersUrl = `${API_URL}/products/start_stock_take`;
	  	try {
	      	const response = await fetch(apiAddOrdersUrl, {
	      		method: 'POST',
	      		headers: {
	        		authorization: `Bearer ${accessToken}`,
	      		},
	      		body: formData,
	    	});

	      	if (!response.ok) {
	        	setFlashMessage({ message: 'something went wrong, try again', type: 'error' });
	      	}
	      	const data = await response.json();
	      
	      	setFlashMessage({ message: 'Start stack track, successfully', type: 'success' });
	      	setCategoryValue([]);
	        setBrandValue([]); 
	    } catch (error) {
	      	console.error('Error Accept Order:', error);	      
	     	setFlashMessage({ message: 'Error . Please check the console for details', type: 'error' });
	    }
  	}

	return(
		<>
			<Header />
			<div className="breadcrumbs">
		        <div className="container-fluid">
		          <div className="row">
		            <div className="col-12 col-lg-12 col-sm-12"> 
		              <Link to="/Dashboard"><i className="fa fa-home"></i></Link>  
		              <Link to="/stock_take/start_stock_take">Stock Take </Link> 
		            </div>
		          </div>
		       	</div>
		    </div>
		    <div className="main-container">
		    	<div className="container-fluid">
		    		<div className="total-manifests-area dashboard">
			      	
	          			
	          			<div className="row">
	          				<div className="col-md-9 col-lg-9 col-sm-12">
      							<div className="col-md-6 col-lg-6 col-sm-6" style={{ float: 'left', padding: '0px 0px 0px 0' }}>
       	 							<label style={{ marginBottom: '5px' }}><b>Select Options</b></label>
							        <select
							          value={pageFilterValue}
							          onChange={handlePageChange}
							          className="form-select"
							        >
							          {pageOptions.map(option => (
							            <option key={option.value} value={option.value}>
							              {option.label}
							            </option>
							          ))}
							        </select>
      							</div>

							    {pageFilterValue === 'categories' && (
							    <div className="col-md-6 col-lg-6 col-sm-6" style={{ float: 'left', padding: '0px 0px 0px 15px' }}>
							        <label style={{ marginBottom: '5px' }}><b>Categories</b></label>
							        <select
							            ref={categoryRef}
							            multiple
							            value={categoryValue}
							            className="form-control"
							          >
							            <option value="">Select Categories</option>
							            {categoryOptions.map(option => (
							              <option key={option.value} value={option.value}>
							                {option.label}
							              </option>
							            ))}
							        </select>
							    </div>
							    )}

							    {pageFilterValue === 'brands' && (
							        <div className="col-md-6 col-lg-6 col-sm-6" style={{ float: 'left', padding: '0px 0px 0px 15px' }}>
							        <label style={{ marginBottom: '5px' }}><b>Brands</b></label>
							        <select
							            ref={brandRef}
							            multiple
							            value={brandValue}
							            className="form-control"
							          >
							            <option value="">Select Brands</option>
							            {brandOptions.map(option => (
							              <option key={option.value} value={option.value}>
							                {option.label}
							              </option>
							            ))}
							        </select>
							    </div>
							    )}
							</div>

	          			</div>
						<hr/>
	          			<div className="row">
	          				<div className="col-md-12 col-lg-12 col-sm-12" style={{float: 'right', paddingRight: '15px' }} >
								<button type="button" onClick={handelStartStack}  className="acceptbtn" style={{float: 'right', margin: '0 0 0 15px'}}>Start Stock Take</button>
							</div>
	          			</div>

	          		</div>
		    	</div>
		    </div>

		    <FlashMessage
          		message={flashMessage?.message}
          		type={flashMessage?.type}
          		onClose={handleCloseMessage}
        	/>

		    <Footer />

		</>
	);

}
export default StartStockTake;