import React, { useEffect, useRef } from 'react';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';

const Edit_New_Invoice = () => {
  const tableRef = useRef(null);

  useEffect(() => {
    $(document).ready(function () {
      $(tableRef.current).DataTable();
    });

    const deleteRow = (rowIndex) => {
      tableRef.current.deleteRow(rowIndex);
    };

    const tableClick = (e) => {
      if (e.target.value === 'Delete') {
        deleteRow(e.target.parentNode.parentNode.rowIndex);
      }
    };

    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener('click', tableClick, false);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (tableElement) {
        tableElement.removeEventListener('click', tableClick, false);
      }
    };
  }, []);

  return (
    <div>
      <Header />
      <div className="modal fade" id="add-product" tabIndex="-1">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Product</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="add-product-popup">
                <form>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="desc" className="col-sm-3 col-form-label">&nbsp;</label>
                    <div className="col-sm-9">
                      <select className="form-select" aria-label="Default select example">
                        <option selected="">Select Product *</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="desc" className="col-sm-3 col-form-label">&nbsp;</label>
                    <div className="col-sm-9">
                      <select className="form-select" aria-label="Default select example">
                        <option selected="">Select Order Type *</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="desc" className="col-sm-3 col-form-label">Price</label>
                    <div className="col-sm-9">
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="desc" className="col-sm-3 col-form-label">QTY</label>
                    <div className="col-sm-9">
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <hr />
                  <div className="form-group">
                    <input type="submit" className="btn btn-secondary closebtn" data-bs-dismiss="modal" value="Close" />
                    <input type="submit" className="btn btn-secondary savbtn" data-bs-dismiss="modal" value="Save Changes" />
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer"> </div>
          </div>
        </div>
      </div>
      
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="Dashboard">Dashboard</a> &gt; <a href="new_sales">New Sales</a> &gt; <span className="last-crumb">Edit Invoice</span>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-addprod-area dashboard">
                <div className="addnew-product-area">
                  <h3>
                    <span>Edit Invoice</span>
                    <button type="submit" id="submit" className="create-catalogue-btn" data-bs-toggle="modal" data-bs-target="#add-product">Add New Product</button>
                  </h3>
                </div>
                <div className="">
                  <div className="row">
                    <div className="col-12 col-lg-6 col-sm-6">
                      <p>Customer Name - Vanilla Stores</p>
                      <p>Account No - 05</p>
                    </div>
                    <div className="col-12 col-lg-6 col-sm-6">
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-2 col-form-label">Delivery Address:</label>
                        <div className="col-sm-10">
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="verical-scrl-wrapper common-table addnew-tbl" id="style-3">
                    <table className="table table-bordered resizable" id="dsTable" ref={tableRef}>
                      <thead>
                        <tr>
                          <th>Sr No.</th>
                          <th>Code</th>
                          <th>Description</th>
                          <th>Order Qty</th>
                          <th>Allot Qty</th>
                          <th>Order Type</th>
                          <th>Unit price</th>
                          <th>Net Amount</th>
                          <th>VAT Amount</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                      <td>01</td>
                      <td>1107</td>
                      <td><p class="cut-text">WARRIOR BIRTHDAY CAKE-12x64g</p></td>
                      <td>2</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>Box</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>25.98</td>
                      <td>5.20</td>
                      <td><input type="button" value="Delete" class="deletebtn" /></td>
                    </tr>
                      <tr>
                      <td>02</td>
                      <td>1564</td>
                      <td><p class="cut-text">WARRIOR BIRTHDAY CAKE-12x64g</p></td>
                      <td>6</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>Box</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>25.98</td>
                      <td>5.20</td>
                      <td><input type="button" value="Delete" class="deletebtn" /></td>
                    </tr>
                      <tr>
                      <td>03</td>
                      <td>1567</td>
                      <td><p class="cut-text">WARRIOR BIRTHDAY CAKE-12x64g</p></td>
                      <td>8</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>Box</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>25.98</td>
                      <td>5.20</td>
                      <td><input type="button" value="Delete" class="deletebtn" /></td>
                    </tr>
                      <tr>
                      <td>04</td>
                      <td>1107</td>
                      <td><p class="cut-text">WARRIOR BIRTHDAY CAKE-12x64g</p></td>
                      <td>2</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>Box</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>25.98</td>
                      <td>5.20</td>
                      <td><input type="button" value="Delete" class="deletebtn" /></td>
                    </tr>
                      <tr>
                      <td>05</td>
                      <td>1564</td>
                      <td><p class="cut-text">WARRIOR BIRTHDAY CAKE-12x64g</p></td>
                      <td>6</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>Box</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>25.98</td>
                      <td>5.20</td>
                      <td><input type="button" value="Delete" class="deletebtn" /></td>
                    </tr>
                      <tr>
                      <td>06</td>
                      <td>1567</td>
                      <td><p class="cut-text">WARRIOR BIRTHDAY CAKE-12x64g</p></td>
                      <td>8</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>Box</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>25.98</td>
                      <td>5.20</td>
                      <td><input type="button" value="Delete" class="deletebtn" /></td>
                    </tr>
                      <tr>
                      <td>07</td>
                      <td>1107</td>
                      <td><p class="cut-text">WARRIOR BIRTHDAY CAKE-12x64g</p></td>
                      <td>2</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>Box</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>25.98</td>
                      <td>5.20</td>
                      <td><input type="button" value="Delete" class="deletebtn" /></td>
                    </tr>
                      <tr>
                      <td>08</td>
                      <td>1564</td>
                      <td><p class="cut-text">WARRIOR BIRTHDAY CAKE-12x64g</p></td>
                      <td>6</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>Box</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>25.98</td>
                      <td>5.20</td>
                      <td><input type="button" value="Delete" class="deletebtn" /></td>
                    </tr>
                      <tr>
                      <td>09</td>
                      <td>1567</td>
                      <td><p class="cut-text">WARRIOR BIRTHDAY CAKE-12x64g</p></td>
                      <td>8</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>Box</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>25.98</td>
                      <td>5.20</td>
                      <td><input type="button" value="Delete" class="deletebtn" /></td>
                    </tr>
                      <tr>
                      <td>10</td>
                      <td>1107</td>
                      <td><p class="cut-text">WARRIOR BIRTHDAY CAKE-12x64g</p></td>
                      <td>2</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>Box</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>25.98</td>
                      <td>5.20</td>
                      <td><input type="button" value="Delete" class="deletebtn" /></td>
                    </tr>
                      <tr>
                      <td>11</td>
                      <td>1564</td>
                      <td><p class="cut-text">WARRIOR BIRTHDAY CAKE-12x64g</p></td>
                      <td>6</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>Box</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>25.98</td>
                      <td>5.20</td>
                      <td><input type="button" value="Delete" class="deletebtn" /></td>
                    </tr>
                      <tr>
                      <td>12</td>
                      <td>1567</td>
                      <td><p class="cut-text">WARRIOR BIRTHDAY CAKE-12x64g</p></td>
                      <td>8</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>Box</td>
                      <td><input type="text" class="form-control" /></td>
                      <td>25.98</td>
                      <td>5.20</td>
                      <td><input type="button" value="Delete" class="deletebtn" /></td>
                    </tr>
                    </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Edit_New_Invoice;
