

export const API_URL = 'https://testapi.rameegroup.co.in';

//export const BASE_URL = 'https://dan.rameegroup.co.in/react';

//export const BASE_URL = 'https://kmfood.co.uk';

export const BASE_URL = 'https://dan.rameegroup.co.in/react';



//export const BASE_URL = 'http://localhost:3000';




export const DEMO_URL = 'https://kmfood.co.uk';

 
