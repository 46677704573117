import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";

const InventorySalesReport = () => {

	const [reportrange, setReportrange] = useState('');
	const [fromDate, setFromDate] = useState('');
  	const [toDate, setToDate] = useState('');
	const [draw, setDraw] = useState(1);
  	const [start, setStart] = useState(0);
  	const [length, setLength] = useState(50);
  	const [dates, setDates] = useState([null, null]);
  	const [reportDate, setReportDate] = useState('');
  	const [inventoryReport, setInventoryReport] = useState([]);


  	const handleDateChange = (value) => {
    	setDates(value);
  	};

  	const formatDateRange = (dates) => {
    	if (dates[0] && dates[1]) {
      		const startDate = dates[0].toDateString();
      		const endDate = dates[1].toDateString();
      		return `${startDate} - ${endDate}`;
    	}
    	return 'Not selected';
  	};

  	const fetchReport = async () => {
  		const formData = new FormData();
	    formData.append('draw', draw);
	    formData.append('start', start);
	    formData.append('length', length);
	    formData.append('reportrange', reportrange ? reportrange : '');

	   
	    const apiUrl = `${config.API_URL}/sales/report`;
	    const accessToken = localStorage.getItem('accessToken');      
        if (accessToken === null){
            window.location.href = `${config.BASE_URL}/login`; 
            return;
        }
	    try {
	     	const response = await fetch(apiUrl, {
        		method: 'POST',
        		headers: {
          			
          			authorization: `Bearer ${accessToken}`,
        		},
        		body: formData,
      		});

	      	if (!response.ok) {
	        	throw new Error('Failed to fetch Not Order Report');
	      	}
	      	const data = await response.json();
	      	setInventoryReport(data.data);
	      	setReportDate(data.startdate+' To Date : '+data.enddate);
	    }catch (error) {
	      console.error('api fail Error fetching Not Order Report:', error);
	    }

  	};

  	useEffect(() => {
    	fetchReport();
  	},[reportrange]);


  	useEffect(() => {
    	const table = $('#inventorySalesReport').DataTable({
      		pageLength: 50,
      		paging: true,
      		lengthMenu: [10, 20, 50],
      		searching: true,
      		ordering: false,
      		info: true,
      		responsive: true,
      		autoWidth: false,
      		language: {
	        	paginate: {
	          		first: 'First',
	          		last: 'Last',
	          		next: 'Next',
	          		previous: 'Previous',
	        	},
	        	search: 'Search:',
	      	},
      		data: inventoryReport,
      		columns: [
        		{ data: 'code' },
        		{ data: 'name' },
        		{ data: 'cost' },
        		{ data: 'price' },
        		{ data: 'total_box_qty' },
        		{ data: 'total_piece_qty' },
        		{ data: 'sale_box_qty' },
        		{ data: 'sale_piece_qty' }
    		],
    		columnDefs: [
		        { 
		            targets: [0, 1, 2, 3, 4, 5, 6, 7], 
		            orderable: false 
		        }
		    ],
    		drawCallback: function (settings) {
        		var api = this.api();
        		var startIndex = api.page.info().start;
        		
    		}
    	});
    	return () => {
      		table.destroy();
    	};
  	}, [inventoryReport]);


  	const searchFormData = () => {
  		let startDate = dates[0] ? format(dates[0], 'MM/dd/yyyy') : '';
  		let endDate = dates[1] ? format(dates[1], 'MM/dd/yyyy') : '';
  		setReportrange(startDate ? startDate+' - '+endDate : '');
  		fetchReport();
  	};

  	return (
		<div>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/reports/inventory_sales_report">Reports</Link> 
		                  <Link to="/reports/inventory_sales_report">Inventory sales report</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>

	      	<div className="main-container">
			    <div className="container-fluid">
			        <div className="row">
		                <div className="col-md-9 col-lg-9 col-sm-12">
		                	<div className="col-md-3 cp-lg-3 col-sm-3" style={{float: 'left'}}>
							<label  style={{marginBottom: '5px'}} ><b>Select Date</b></label>
		                		
				                <DateRangePicker value={dates}
							        onChange={handleDateChange}
							        format="MM/dd/yyyy"
							        character=" - "
							        style={{ width: '100%' }}
							    />
							</div>
							<div className="col-md-3 cp-lg-3 col-sm-3" style={{float: 'left', paddingTop: '30px'}} >
								<button type="button" onClick={searchFormData} className="acceptbtn" style={{float: 'left', marginLeft: '10px'}} >Submit</button>
							</div>
					      
		            
		                </div>
	                </div>

	                <div className="total-manifests-area dashboard">
	                    <p><b>Inventory Sales Report </b></p>
	                    <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
	                        <table className="table table-bordered resizable" id="inventorySalesReport">
	                            <thead>
	                                <tr>
	                                    <th>Code</th>
	                                    <th>Name</th>
	                                    <th>Cost</th>
	                                    <th>Price</th>
	                                    <th>Total Box Qty</th>
	                                    <th>Total Piece Qty</th>
	                                    <th>Sale Box Qty</th>
	                                    <th>Sale Piece Qty</th>
	                                </tr>
	                            </thead>
	                            <tbody>
	                                
	                            </tbody>
	                        </table>
	                    </div>
	                </div>
			           
			    </div>
			</div>

			<Footer />
		</div>
	);

}

export default InventorySalesReport;