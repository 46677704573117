import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from './Config';
import config from "./common/Config";

const CreateManifest = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [orderHistory, setorderHistory] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const accessToken = localStorage.getItem('accessToken'); 

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('role', selectedRole);

  
    const apiUrl = `${config.API_URL}/order/history`; 
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch new sales data');
      }

      const data = await response.json();
      console.log(data);
      setorderHistory(data.data);
    } catch (error) {
      console.error('Error fetching new sales data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, selectedRole]);

  useEffect(() => {
    const table = $('#neworderTable').DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: true,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
      data: orderHistory,
      columns: [
        { data: 'date' },
        {
          data: 'reference_no',
          render: function (data) {
            return `<a href="RoutePlaningInvoice">${data}</a>`;
          },
        },
        {
          data: 'customer',
          render: function (data) {
            return `<p class="cut-text">${data}</p>`;
          },
        },
        { data: 'grand_total' },
        { data: 'grand_total1' },
        { data: 'previous_due' },
        { data: 'grand_total' },
        { data: 'route_number' },
        // { data: 'sale_status' }
         // { data: 'status' },
         { 
          data: null,
          render: function (data, type, row) {                
         
                return '<span class="btn btn-primary badge" >'  + row.sale_status + '</span>';
             
          }
      },
       ],
    });
    return () => {
      table.destroy();
    };
  }, [orderHistory]);
  return (
    <div>
      <Header />
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard">
                <div className="dashform">
                  <div style={{ float: 'right' }}>
                    <label>From Date</label>
                    <input
                      type="date"
                      name="fromDate"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                      className="form-control"/>
                    <label>To Date</label>
                    <input
                      type="date"
                      name="toDate"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                      className="form-control"
                    />
                    <button type="submit" onClick={handleSubmit} className="selectdate-btn">
                      Submit
                    </button>
                  </div>
                </div>
                <div class="total-manifests-area dashboard">
                  <div style={{ float: 'right', width: '100%', textAlign: 'right', marginTop: '0' }}>
                    <button type="submit" id="submit" className="acceptbtn">
                      Accept order
                    </button>
                  </div>

<hr className='devider' /> 

                  <div className="verical-scrl-wrapper create-menifest-area" id="style-3">
                    <table className="table table-bordered dataTable resizable" id="neworderTable">
                      <thead>
                        <tr>
                          <th>Order date</th>
                          <th>Order Id</th>
                          <th>Customers(A/C No)</th>
                          <th>Order Amount</th>
                          <th>Current Amount</th>
                          <th>Previous Dues</th>
                          <th>Total Due</th>
                          <th>Route</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderHistory.map((item, index) => (
                          <tr key={index}>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CreateManifest;
