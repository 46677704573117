import React, { useState, useEffect } from 'react';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import CategorySuggestionBox from './CategorySuggestionBox';
import UnitSuggestionBox from './UnitSuggestionBox';
import BrandSuggestionBox from './BrandSuggestionBox';
import BarcodeSuggestionBox from './BarcodeSuggestionBox';
import VatSuggestionBox from './VatSuggestionBox';
import SupplierSuggestionList from './SupplierSuggestionList';
import config from "../../common/Config";
import Swal from 'sweetalert2';
import axios from 'axios';

const Add_Products = () => {


  const accessToken = localStorage.getItem('accessToken');
  const userId = localStorage.getItem('userId');


  const [selectedOption, setSelectedOption] = useState('0');

  const toggleOptions = (option) => {
    setSelectedOption(option);
  };

  const [formData, setFormData] = useState({
    code: '',
    name: '',
    cost: '',
    price: '',
    piece_cost: '',
    inner_ean_number: '',
    outer_ean_number: '',
    alert_quantity: '',
    details: '',
    product_details: '',
    slug: '',
    size: '',
    pack: '',
    bay: '',
    rack: '',
    second_name: '',
    split: '',
    split_price: '',
    price_1: '',
    price_2: '',
    price_3: '',
    price_4: '',
    price_5: '',
    piece_price1: '',
    piece_price2: '',
    piece_price3: '',
    piece_price4: '',
    piece_price5: '',
    is_discount_allow: '0',
    is_discount_allow: '',
    percentage: '',
    new_arrival: '0',
    new_arrival_date: '',
    min_shelf_life: '',
    max_shelf_life: '',
  });
  const [errors, setErrors] = useState({});

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked ? '1' : '0', // Set '1' if checked, null if unchecked
    }));
  };

  const [priceVariations, setPriceVariations] = useState({
    percentage2: 0,
    percentage3: 0,
    percentage4: 0,
    percentage5: 0,
    percentage6: 0
  });
  const fetchPriceVariationList = async () => {

    const formData = new FormData();
    formData.append("draw", "1");
    formData.append("start", "0");
    formData.append("length", "1");
    const apiCategoryUrl = `${config.API_URL}/get_price_variation`;
    try {
      const response = await fetch(apiCategoryUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });
      const data = await response.json();
      //console.log(data);
      if (data && data.data && data.data.length > 0) {
        const priceData = data.data[0];
        setPriceVariations({
          percentage2: priceData.percentage2,
          percentage3: priceData.percentage3,
          percentage4: priceData.percentage4,
          percentage5: priceData.percentage5,
          percentage6: priceData.percentage6,
        });
      }
    } catch (error) {
      console.error('API failed: error fetching categories', error);
    }
  };

  useEffect(() => {
    setSelectedOption('0');
    fetchPriceVariationList();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const costValue = parseFloat(formData.cost);
    const pieceCostValue = parseFloat(formData.piece_cost);

    let fieldErrors = {};
    if (name === "price") {
      const price1 = parseFloat(value);
      if (price1 > 0 && price1 > costValue) {

        if (!isNaN(price1)) {
          const price2 = price1 * (priceVariations.percentage2 / 100);
          const price3 = price1 * (priceVariations.percentage3 / 100);
          const price4 = price1 * (priceVariations.percentage4 / 100);
          const price5 = price1 * (priceVariations.percentage5 / 100);
          const price6 = price1 * (priceVariations.percentage6 / 100);

          setFormData({
            ...formData,
            price: price1,
            price_1: price2.toFixed(2),
            price_2: price3.toFixed(2),
            price_3: price4.toFixed(2),
            price_4: price5.toFixed(2),
            price_5: price6.toFixed(2),
          });
        }
      } else {
        fieldErrors.price = "price not less than  cost.";
        setFormData({
          ...formData,
          price: price1,
          price_1: '',
          price_2: '',
          price_3: '',
          price_4: '',
          price_5: '',
        });
      }
    }

    if (name === "split_price") {
      const splitPrice = parseFloat(value);
      if (splitPrice > 0 && splitPrice > pieceCostValue) {
        if (!isNaN(splitPrice)) {
          const piecePrice1 = splitPrice * (priceVariations.percentage2 / 100);
          const piecePrice2 = splitPrice * (priceVariations.percentage3 / 100);
          const piecePrice3 = splitPrice * (priceVariations.percentage4 / 100);
          const piecePrice4 = splitPrice * (priceVariations.percentage5 / 100);
          const piecePrice5 = splitPrice * (priceVariations.percentage6 / 100);

          setFormData({
            ...formData,
            split_price: splitPrice,
            piece_price1: piecePrice1.toFixed(2),
            piece_price2: piecePrice2.toFixed(2),
            piece_price3: piecePrice3.toFixed(2),
            piece_price4: piecePrice4.toFixed(2),
            piece_price5: piecePrice5.toFixed(2),
          });
        }
      } else {
        fieldErrors.split_price = "price not less than  Piece Cost.";
        setFormData({
          ...formData,
          split_price: splitPrice,
          piece_price1: '',
          piece_price2: '',
          piece_price3: '',
          piece_price4: '',
          piece_price5: '',
        });
      }
    }

    if (name === 'pack') {

      const numericValue = parseFloat(value);

      if (isNaN(numericValue) || numericValue < 0) {
        fieldErrors[name] = "Pack must be a positive number.";
        setFormData({ ...formData, [name]: 0 });
      } else {
        fieldErrors[name] = '';
        setFormData({ ...formData, [name]: numericValue });
      }
    }

    if (name === "code") {
      const slugVal = value;
      setFormData((prevState) => ({
        ...prevState,
        slug: slugVal,
      }));
    }



    const isSelectCostBoxHidden = selectedOption == '0' || selectedOption == '2';
    const isSelectPieceBoxHidden = selectedOption == '1' || selectedOption == '2';


    switch (name) {

      case 'code':
        if (!value) {
          fieldErrors.code = "Mention code.";
        } else if (value.length < 2) {
          fieldErrors.code = "code must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.code = "code must not exceed 255 characters.";
        }
        if (!fieldErrors.code) {
          fieldErrors.code = '';
        }
        break;

      case 'name':
        if (!value) {
          fieldErrors.name = "Mention name.";
        } else if (value.length < 2) {
          fieldErrors.name = "name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.name = "name must not exceed 255 characters.";
        }
        if (!fieldErrors.name) {
          fieldErrors.name = '';
        }
        break;



      case 'size':
        if (!value) {
          fieldErrors.size = "Mention size.";
        } else if (value.length < 2) {
          fieldErrors.size = "size must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.size = "size must not exceed 255 characters.";
        }
        if (!fieldErrors.size) {
          fieldErrors.size = '';
        }
        break;

      case 'pack':
        if (!value) {
          fieldErrors.pack = "Mention pack.";
        } else if (value.length < 1) {
          fieldErrors.pack = "pack must consist of at least 1 digit.";
        } else if (value.length > 255) {
          fieldErrors.pack = "pack must not exceed 255 digit.";
        }
        if (!fieldErrors.pack) {
          fieldErrors.pack = '';
        }
        break;




      case 'cost':
        if (!isSelectCostBoxHidden) break;
        if (!value) {
          fieldErrors.cost = "Mention cost.";
        } else if (value.length < 1) {
          fieldErrors.cost = "cost must consist of at least 1 digit.";
        } else if (value.length > 255) {
          fieldErrors.cost = "cost must not exceed 255 digit.";
        }
        if (!fieldErrors.cost) {
          fieldErrors.cost = '';
        }
        break;

      case 'price':
        if (!isSelectCostBoxHidden) break;
        if (!value) {
          fieldErrors.price = "Mention price.";
        } else if (value < 0) {
          fieldErrors.price = "Mention positive.";
        } else if (value.price > costValue) {
          fieldErrors.price = "price not less than  cost.";
        } else if (value.price < 1) {
          fieldErrors.price = "price must consist of at least 1 digit.";
        } else if (value.price > 255) {
          fieldErrors.price = "price must not exceed 255 digit.";
        }
        if (!fieldErrors.price) {
          fieldErrors.price = '';
        }
        break;

      case 'price_1':
        if (!isSelectCostBoxHidden) break;
        if (!value) {
          fieldErrors.price_1 = "Mention price.";
        } else if (value < 0) {
          fieldErrors.price_1 = "Mention positive.";
        } else if (value.price_1 < 1) {
          fieldErrors.price_1 = "price must consist of at least 1 digit.";
        } else if (value.price_1 > 255) {
          fieldErrors.price_1 = "price must not exceed 255 digit.";
        }
        if (!fieldErrors.price_1) {
          fieldErrors.price_1 = '';
        }
        break;

      case 'price_2':
        if (!isSelectCostBoxHidden) break;
        if (!value) {
          fieldErrors.price_2 = "Mention price.";
        } else if (value < 0) {
          fieldErrors.price_2 = "Mention positive.";
        } else if (value.price_2 < 1) {
          fieldErrors.price_2 = "price must consist of at least 1 digit.";
        } else if (value.price_2 > 255) {
          fieldErrors.price_2 = "price must not exceed 255 digit.";
        }
        if (!fieldErrors.price_2) {
          fieldErrors.price_2 = '';
        }
        break;

      case 'price_3':
        if (!isSelectCostBoxHidden) break;
        if (!value) {
          fieldErrors.price_3 = "Mention price.";
        } else if (value < 0) {
          fieldErrors.price_3 = "Mention positive.";
        } else if (value.price_3 < 1) {
          fieldErrors.price_3 = "price must consist of at least 1 digit.";
        } else if (value.price_3 > 255) {
          fieldErrors.price_3 = "price must not exceed 255 digit.";
        }
        if (!fieldErrors.price_3) {
          fieldErrors.price_3 = '';
        }
        break;

      case 'price_4':
        if (!isSelectCostBoxHidden) break;
        if (!value) {
          fieldErrors.price_4 = "Mention price.";
        } else if (value < 0) {
          fieldErrors.price_4 = "Mention positive.";
        } else if (value.price_4 < 1) {
          fieldErrors.price_4 = "price must consist of at least 1 digit.";
        } else if (value.price_4 > 255) {
          fieldErrors.price_4 = "price must not exceed 255 digit.";
        }
        if (!fieldErrors.price_4) {
          fieldErrors.price_4 = '';
        }
        break;

      case 'price_5':
        if (isSelectCostBoxHidden) break;
        if (!value) {
          fieldErrors.price_5 = "Mention price.";
        } else if (value < 0) {
          fieldErrors.price_5 = "Mention positive.";
        } else if (value.price_5 < 1) {
          fieldErrors.price_5 = "price must consist of at least 1 digit.";
        } else if (value.price_5 > 255) {
          fieldErrors.price_5 = "price must not exceed 255 digit.";
        }
        if (!fieldErrors.price_5) {
          fieldErrors.price_5 = '';
        }
        break;

      case 'piece_cost':
        if (!isSelectPieceBoxHidden) break;
        if (!value) {
          fieldErrors.piece_cost = "Mention cost.";
        } else if (value < 0) {
          fieldErrors.price_5 = "Mention positive.";
        } else if (value.length < 1) {
          fieldErrors.piece_cost = "cost must consist of at least 1 digit.";
        } else if (value.length > 255) {
          fieldErrors.piece_cost = "cost must not exceed 255 digit.";
        }
        if (!fieldErrors.piece_cost) {
          fieldErrors.piece_cost = '';
        }
        break;

      case 'split_price':
        if (!isSelectPieceBoxHidden) break;
        if (!value) {
          fieldErrors.split_price = "Mention price.";
        } else if (value < 0) {
          fieldErrors.split_price = "Mention positive.";
        } else if (value.split_price < 1) {
          fieldErrors.split_price = "price must consist of at least 1 digit.";
        } else if (value.split_price > 255) {
          fieldErrors.split_price = "price must not exceed 255 digit.";
        }
        if (!fieldErrors.split_price) {
          fieldErrors.split_price = '';
        }
        break;

      case 'piece_price1':
        if (!isSelectPieceBoxHidden) break;
        if (!value) {
          fieldErrors.piece_price1 = "Mention price.";
        } else if (value < 0) {
          fieldErrors.piece_price1 = "Mention positive.";
        } else if (value.piece_price1 < 1) {
          fieldErrors.piece_price1 = "price must consist of at least 1 digit.";
        } else if (value.piece_price1 > 255) {
          fieldErrors.piece_price1 = "price must not exceed 255 digit.";
        }
        if (!fieldErrors.piece_price1) {
          fieldErrors.piece_price1 = '';
        }
        break;

      case 'piece_price2':
        if (!isSelectPieceBoxHidden) break;
        if (!value) {
          fieldErrors.piece_price2 = "Mention price.";
        } else if (value < 0) {
          fieldErrors.piece_price2 = "Mention positive.";
        } else if (value.piece_price2 < 1) {
          fieldErrors.piece_price2 = "price must consist of at least 1 digit.";
        } else if (value.piece_price2 > 255) {
          fieldErrors.piece_price2 = "price must not exceed 255 digit.";
        }
        if (!fieldErrors.piece_price2) {
          fieldErrors.piece_price2 = '';
        }
        break;

      case 'piece_price3':
        if (!isSelectPieceBoxHidden) break;
        if (!value) {
          fieldErrors.piece_price3 = "Mention price.";
        } else if (value < 0) {
          fieldErrors.piece_price3 = "Mention positive.";
        } else if (value.piece_price3 < 1) {
          fieldErrors.piece_price3 = "price must consist of at least 1 digit.";
        } else if (value.piece_price3 > 255) {
          fieldErrors.piece_price3 = "price must not exceed 255 digit.";
        }
        if (!fieldErrors.piece_price3) {
          fieldErrors.piece_price3 = '';
        }
        break;

      case 'piece_price4':
        if (!isSelectPieceBoxHidden) break;
        if (!value) {
          fieldErrors.piece_price4 = "Mention price.";
        } else if (value < 0) {
          fieldErrors.piece_price4 = "Mention positive.";
        } else if (value.piece_price4 < 1) {
          fieldErrors.piece_price4 = "price must consist of at least 1 digit.";
        } else if (value.piece_price4 > 255) {
          fieldErrors.piece_price4 = "price must not exceed 255 digit.";
        }
        if (!fieldErrors.piece_price4) {
          fieldErrors.piece_price4 = '';
        }
        break;

      case 'piece_price5':
        if (isSelectPieceBoxHidden) break;
        if (!value) {
          fieldErrors.piece_price5 = "Mention price.";
        } else if (value < 0) {
          fieldErrors.piece_price5 = "Mention positive.";
        } else if (value.piece_price5 < 1) {
          fieldErrors.piece_price5 = "price must consist of at least 1 digit.";
        } else if (value.piece_price5 > 255) {
          fieldErrors.piece_price5 = "price must not exceed 255 digit.";
        }
        if (!fieldErrors.piece_price5) {
          fieldErrors.piece_price5 = '';
        }
        break;



      default:
        break;
    }


    setErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors
    }));
  };

  const validateForm = () => {
    const newErrors = {};



    if (!formData.code) {
      newErrors.code = "Mention code.";
    } else if (formData.code.length < 2) {
      newErrors.code = "code must consist of at least 2 characters.";
    } else if (formData.code.length > 255) {
      newErrors.code = "code must not exceed 255 characters.";
    }

    if (!formData.name) {
      newErrors.name = "Mention name.";
    } else if (formData.name.length < 2) {
      newErrors.name = "name must consist of at least 2 characters.";
    } else if (formData.name.length > 255) {
      newErrors.name = "name must not exceed 255 characters.";
    }



    if (!formData.pack) {
      newErrors.pack = "Mention pack.";
    } else if (formData.pack.length < 1) {
      newErrors.pack = "pack must consist of at least 1 digit.";
    } else if (formData.pack.length > 255) {
      newErrors.pack = "pack must not exceed 255 characters.";
    }

    const isSelectCostBoxHidden = selectedOption == '0' || selectedOption == '2';
    console.log(isSelectCostBoxHidden);
    if (isSelectCostBoxHidden) {
      if (!formData.cost) {
        newErrors.cost = "Mention cost.";
      } else if (formData.cost.length < 1) {
        newErrors.cost = "cost must consist of at least 1 digit.";
      } else if (formData.cost.length > 255) {
        newErrors.cost = "cost must not exceed 255 digits.";
      }

      if (!formData.price) {
        newErrors.price = "Mention price.";
      } else if (formData.price.length < 1) {
        newErrors.price = "price must consist of at least 1 digit.";
      } else if (formData.price.length > 255) {
        newErrors.price = "price must not exceed 255 digits.";
      }

      if (!formData.price_1) {
        newErrors.price_1 = "Mention price.";
      } else if (formData.price_1.length < 1) {
        newErrors.price_1 = "price must consist of at least 1 digit.";
      } else if (formData.price_1.length > 255) {
        newErrors.price_1 = "price must not exceed 255 digits.";
      }

      if (!formData.price_2) {
        newErrors.price_2 = "Mention price.";
      } else if (formData.price_2.length < 1) {
        newErrors.price_2 = "price must consist of at least 1 digit.";
      } else if (formData.price_2.length > 255) {
        newErrors.price_2 = "price must not exceed 255 digits.";
      }

      if (!formData.price_3) {
        newErrors.price_3 = "Mention price.";
      } else if (formData.price_3.length < 1) {
        newErrors.price_3 = "price must consist of at least 1 digit.";
      } else if (formData.price_3.length > 255) {
        newErrors.price_3 = "price must not exceed 255 digits.";
      }

      if (!formData.price_4) {
        newErrors.price_4 = "Mention price.";
      } else if (formData.price_4.length < 1) {
        newErrors.price_4 = "price must consist of at least 1 digit.";
      } else if (formData.price_4.length > 255) {
        newErrors.price_4 = "price must not exceed 255 digits.";
      }

      if (!formData.price_5) {
        newErrors.price_5 = "Mention price.";
      } else if (formData.price_5.length < 1) {
        newErrors.price_5 = "price must consist of at least 1 digit.";
      } else if (formData.price_5.length > 255) {
        newErrors.price_5 = "price must not exceed 255 digits.";
      }
    }

    const isSelectPieceBoxHidden = selectedOption == '1' || selectedOption == '2';
    if (isSelectPieceBoxHidden) {
      if (!formData.piece_cost) {
        newErrors.piece_cost = "Mention cost.";
      } else if (formData.piece_cost.length < 1) {
        newErrors.piece_cost = "cost must consist of at least 1 digit.";
      } else if (formData.piece_cost.length > 255) {
        newErrors.piece_cost = "cost must not exceed 255 digits.";
      }

      if (!formData.split_price) {
        newErrors.split_price = "Mention price.";
      } else if (formData.split_price.length < 1) {
        newErrors.split_price = "price must consist of at least 1 digit.";
      } else if (formData.split_price.length > 255) {
        newErrors.split_price = "price must not exceed 255 digits.";
      }

      if (!formData.piece_price1) {
        newErrors.piece_price1 = "Mention price.";
      } else if (formData.piece_price1.length < 1) {
        newErrors.piece_price1 = "price must consist of at least 1 digit.";
      } else if (formData.piece_price1.length > 255) {
        newErrors.piece_price1 = "price must not exceed 255 digits.";
      }

      if (!formData.piece_price2) {
        newErrors.piece_price2 = "Mention price.";
      } else if (formData.piece_price2.length < 1) {
        newErrors.piece_price2 = "price must consist of at least 1 digit.";
      } else if (formData.piece_price2.length > 255) {
        newErrors.piece_price2 = "price must not exceed 255 digits.";
      }

      if (!formData.piece_price3) {
        newErrors.piece_price3 = "Mention price.";
      } else if (formData.piece_price3.length < 1) {
        newErrors.piece_price3 = "price must consist of at least 1 digit.";
      } else if (formData.piece_price3.length > 255) {
        newErrors.piece_price3 = "price must not exceed 255 digits.";
      }

      if (!formData.piece_price4) {
        newErrors.piece_price4 = "Mention price.";
      } else if (formData.piece_price4.length < 1) {
        newErrors.piece_price4 = "price must consist of at least 1 digit.";
      } else if (formData.piece_price4.length > 255) {
        newErrors.piece_price4 = "price must not exceed 255 digits.";
      }

      if (!formData.piece_price5) {
        newErrors.piece_price5 = "Mention price.";
      } else if (formData.piece_price5.length < 1) {
        newErrors.piece_price5 = "price must consist of at least 1 digit.";
      } else if (formData.piece_price5.length > 255) {
        newErrors.piece_price5 = "price must not exceed 255 digits.";
      }
    }





    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitProductForm = async (event) => {

    event.preventDefault();

    if (!validateForm()) return;
    const form = document.getElementById('addproductform');


    setIsSubmitting(true);

    const formData = new FormData(form);
    try {
      const response = await axios.post(`${config.API_URL}/add_products`, formData, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        }
      });
      const result = response.data;
      //console.log(result)
      const msg = result.message;
      if (result.success == true) {
        Swal.fire({
          title: "Success!",
          text: "product added successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          window.location.href = config.BASE_URL + '/products/';



        });
      } else {
        Swal.fire({
          title: "Failed!",
          text: msg,
          icon: "error",
          timer: 2500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error User add:", error);
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const [imageName, setImageName] = useState('');
  const [imagePreview, setImagePreview] = useState('');


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageName(file.name);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleRemoveImage = () => {
    setImageName('');
    setImagePreview('');
    document.getElementById("product_image_input").value = "";
  };

  return (
    <div>
      <Header />
      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12"> <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="Dashboard">Master</a> &gt; <span class="last-crumb">Add Product</span>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard" style={{ position: 'relative' }}>
                <div class="row">
                  <div class="col-12 col-lg-4 col-sm-4"> </div>
                </div>
                <div className='add-product-popup'>
                  <form id='addproductform'>
                    <p>Note : Fields marked with a red asterisk (<span>*</span>) are mandatory and must be filled in order to proceed.</p>
                    <input type="hidden" value={userId} name="user_id" />
                    <div className='row mb-3 padd-l-13px'>
                      <div className='col-6 col-lg-6 col-sm-6'>
                        <div class="row mb-3">

                          <div class="col-4">
                            <div class="col-12 col-lg-12 col-sm-12">
                              <label for="desc" class="col-form-label">Product Code <span style={{ color: 'red' }}> * </span></label>
                            </div>
                            <div class="col-12 col-lg-12 col-sm-12">
                              <div className="input-group">
                                <div className="col-sm-12">
                                  <input type="text" class="form-control" value={formData.code} onChange={handleChange} name="code" />
                                </div>
                                <div className="col-sm-12">
                                  {errors.code && <span className="text-danger">{errors.code}</span>}
                                </div>
                              </div>

                              
                            </div>
                          </div>


                          <div class="col-8">
                            <div class="col-12 col-lg-12 col-sm-12">
                              <label for="desc" class="col-form-label">Product Name <span style={{ color: 'red' }}> * </span></label>
                            </div>
                            <div class="col-12 col-lg-12 col-sm-12">
                              <div className="input-group">
                                <div className="col-sm-12">
                                  <input type="text" class="form-control" value={formData.name} onChange={handleChange} name="name" />
                                </div>
                                <div className="col-sm-12">
                                  {errors.name && <span className="text-danger">{errors.name}</span>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                      <div className='row mb-3'>
                      <div className='col-12 col-lg-12 col-sm-12'>
                          <div class="row">
                            <label for="inputPassword" class="col-sm-12 col-form-label">Product Details</label>
                            <div class="col-sm-12">
                              <textarea name="details" value={formData.details} onChange={handleChange} class="form-control" style={{ height: '50px' }}></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                        <div class="row mb-3">


                        <div class="col-6">

<div class="col-12 col-lg-12 col-sm-12">
  <div className="input-group">
    <div className="col-sm-12">
      <input type="hidden" class="form-control" value={formData.slug} name="slug" />
    </div>

  </div>



  <div class="col-12">

    <div className='row'>
      <div class="col-12 col-lg-6 col-sm-6">
        <label for="desc" class="col-form-label">Size <span style={{ color: 'red' }}> * </span></label>
        <input type="text" class="form-control" value={formData.size} onChange={handleChange} name="size" />

        <div className="col-sm-12">
          {errors.size && <span className="text-danger">{errors.size}</span>}
        </div>

      </div>


      <div class="col-12 col-lg-6 col-sm-6">
                                    <label for="desc" class="col-form-label">Pack <span style={{ color: 'red' }}> * </span></label>
                                    <input type="number" class="form-control no-arrows" value={formData.pack} onChange={handleChange} name="pack" />

                                    <div className="col-sm-12">
                                      {errors.pack && <span className="text-danger">{errors.pack}</span>}
                                    </div>

                                  </div>


      


    </div>


  </div>
</div>
</div>

                          <div class="col-6">

                            <div class="col-12 col-lg-12 col-sm-12">
                              <div className="input-group">
                                <div className="col-sm-12">
                                  <input type="hidden" class="form-control" value={formData.slug} name="slug" />
                                </div>

                              </div>



                              <div class="col-12">

                                <div className='row'>
                                <div class="col-12 col-lg-6 col-sm-6">
        <label for="desc" class="col-form-label">Unit <span style={{ color: 'red' }}> * </span></label>
        
        <div className="col-sm-12">

        <div style={{ position: 'relative', zIndex: '1000' }}>

        <UnitSuggestionBox className='abovedd' />
        </div>
        </div>
      </div>
                                  <div class="col-12 col-lg-6 col-sm-6">
                                    <label for="desc" class="col-form-label">Vat <span style={{ color: 'red' }}> * </span></label>
                                    
                                    <div class="col-12 col-lg-12 col-sm-12">

                                    <div style={{ position: 'relative', zIndex: '1000' }}>

                                      <VatSuggestionBox className='abovedd' />
                                      </div>
                                    </div>
                                  </div>
                                </div>


                              </div>
                            </div>
                          </div>






                        </div>







                        <div class="row mb-3">

                          <div class="col-6">
                            <div class="col-12 col-lg-12 col-sm-12">
                              <label for="desc" class="col-form-label">Brand</label>
                            </div>
                            <div class="col-12 col-lg-12 col-sm-12">
                              <BrandSuggestionBox className='abovedd' />
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="col-12 col-lg-12 col-sm-12">
                            <label for="desc" class="col-form-label">Category <span style={{ color: 'red' }}> * </span></label>
                            </div>
                            <div class="col-12 col-lg-12 col-sm-12">
                              <CategorySuggestionBox />
                            </div>
                          </div>

                        </div>

                        

                        

                        <div class="row">
                          <div class="col-sm-10">
                            <div class="row">
                              <div class="col-1 form-check"></div>
                              <div class="col-2 form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="split"
                                  value="0"
                                  checked={selectedOption == '0'}
                                  onChange={() => toggleOptions('0')
                                  }
                                />
                                <label class="form-check-label" for="gridRadios1"> Box </label>
                              </div>
                              <div class="col-2 form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="split"
                                  value="1"
                                  checked={selectedOption == '1'}
                                  onChange={() => toggleOptions('1')}
                                />
                                <label class="form-check-label" for="gridRadios2"> Piece </label>
                              </div>
                              <div class="col-2 form-check ">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="split"
                                  value="2"
                                  checked={selectedOption == '2'}
                                  onChange={() => toggleOptions('2')}
                                />
                                <label class="form-check-label" for="gridRadios3"> Both </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">

                          {(selectedOption == '0' || selectedOption == '2') && (
                            <div className='col-12 col-sm-12 col-lg-12' style={{ margin: '10px 0' }}>
                              <div className='grebx'>

                                <div class="row mb-3">
                                  <div class="col-4 col-lg-3 col-sm-3">
                                    <label for="desc" class="col-form-label">Box Cost <span style={{ color: 'red' }}> * </span></label>
                                  </div>
                                  <div class="col-8 col-lg-3 col-sm-3">
                                    <div className="input-group">
                                      <div className="col-sm-12">
                                        <input type="number" class="form-control no-arrows" value={formData.cost} onChange={handleChange} name="cost" />
                                      </div>
                                      <div className="col-sm-12">
                                        {errors.cost && <span className="text-danger">{errors.cost}</span>}
                                      </div>
                                    </div>
                                  </div>
                                  
                                </div>
                                <div class="row mb-3">
                                <div class="col-4 col-lg-3 col-sm-3">
                                    <label for="desc" class="col-form-label">Product Price 1 <span style={{ color: 'red' }}> * </span></label>
                                  </div>
                                  <div class="col-8 col-lg-3 col-sm-3">
                                    <div className="input-group">
                                      <div className="col-sm-12">
                                        <input type="number" class="form-control no-arrows" value={formData.price} onChange={handleChange} name="price" />
                                      </div>
                                      <div className="col-sm-12">
                                        {errors.price && <span className="text-danger">{errors.price}</span>}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-4 col-lg-3 col-sm-3">
                                    <label for="desc" class="col-form-label">Product Price 2 <span style={{ color: 'red' }}> * </span></label>
                                  </div>
                                  <div class="col-8 col-lg-3 col-sm-3">
                                    <div className="input-group">
                                      <div className="col-sm-12">
                                        <input type="number" class="form-control no-arrows" value={formData.price_1} onChange={handleChange} name="price_1" />
                                      </div>
                                      <div className="col-sm-12">
                                        {errors.price_1 && <span className="text-danger">{errors.price_1}</span>}
                                      </div>
                                    </div>
                                  </div>
                                 
                                </div>
                                <div class="row mb-3">
                                <div class="col-4 col-lg-3 col-sm-3">
                                    <label for="desc" class="col-form-label">Product Price 3 <span style={{ color: 'red' }}> * </span></label>
                                  </div>
                                  <div class="col-8 col-lg-3 col-sm-3">
                                    <div className="input-group">
                                      <div className="col-sm-12">
                                        <input type="number" class="form-control no-arrows" value={formData.price_2} onChange={handleChange} name="price_2" />
                                      </div>
                                      <div className="col-sm-12">
                                        {errors.price_2 && <span className="text-danger">{errors.price_2}</span>}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-4 col-lg-3 col-sm-3">
                                    <label for="desc" class="col-form-label">Product Price 4 <span style={{ color: 'red' }}> * </span></label>
                                  </div>
                                  <div class="col-8 col-lg-3 col-sm-3">
                                    <div className="input-group">
                                      <div className="col-sm-12">
                                        <input type="number" class="form-control no-arrows" value={formData.price_3} onChange={handleChange} name="price_3" />
                                      </div>
                                      <div className="col-sm-12">
                                        {errors.price_3 && <span className="text-danger">{errors.price_3}</span>}
                                      </div>
                                    </div>
                                  </div>
                                  
                                </div>
                                <div class="row mb-3">

                                <div class="col-4 col-lg-3 col-sm-3">
                                    <label for="desc" class="col-form-label">Product Price 5 <span style={{ color: 'red' }}> * </span></label>
                                  </div>
                                  <div class="col-8 col-lg-3 col-sm-3">
                                    <div className="input-group">
                                      <div className="col-sm-12">
                                        <input type="number" class="form-control no-arrows" value={formData.price_4} onChange={handleChange} name="price_4" />
                                      </div>
                                      <div className="col-sm-12">
                                        {errors.price_4 && <span className="text-danger">{errors.price_4}</span>}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-4 col-lg-3 col-sm-3">
                                    <label for="desc" class="col-form-label">Product Price 6 <span style={{ color: 'red' }}> * </span></label>
                                  </div>
                                  <div class="col-8 col-lg-3 col-sm-3">
                                    <div className="input-group">
                                      <div className="col-sm-12">
                                        <input type="number" class="form-control no-arrows" value={formData.price_5} onChange={handleChange} name="price_5" />
                                      </div>
                                      <div className="col-sm-12">
                                        {errors.price_5 && <span className="text-danger">{errors.price_5}</span>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {(selectedOption == '1' || selectedOption == '2') && (
                            <div className='col-12 col-sm-12 col-lg-12' style={{ margin: '10px 0' }}>
                              <div className='grebx'>

                                <div class="row mb-3">
                                  <div class="col-4 col-lg-3 col-sm-3">
                                    <label for="desc" class="col-form-label">Piece Cost <span style={{ color: 'red' }}> * </span></label>
                                  </div>
                                  <div class="col-8 col-lg-3 col-sm-3">
                                    <div className="input-group">
                                      <div className="col-sm-12">
                                        <input type="number" class="form-control no-arrows" value={formData.piece_cost} onChange={handleChange} name="piece_cost" />
                                      </div>
                                      <div className="col-sm-12">
                                        {errors.piece_cost && <span className="text-danger">{errors.piece_cost}</span>}
                                      </div>
                                    </div>
                                  </div>
                                  
                                </div>

                                <div class="row mb-3">
                                <div class="col-4 col-lg-3 col-sm-3">
                                    <label for="desc" class="col-form-label"> Piece Price 1 <span style={{ color: 'red' }}> * </span></label>
                                  </div>
                                  <div class="col-8 col-lg-3 col-sm-3">
                                    <div className="input-group">
                                      <div className="col-sm-12">
                                        <input type="number" class="form-control no-arrows" name="split_price" value={formData.split_price} onChange={handleChange} />
                                      </div>
                                      <div className="col-sm-12">
                                        {errors.split_price && <span className="text-danger">{errors.split_price}</span>}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-4 col-lg-3 col-sm-3">
                                    <label for="desc" class="col-form-label"> Piece Price 2 <span style={{ color: 'red' }}> * </span></label>
                                  </div>
                                  <div class="col-8 col-lg-3 col-sm-3">
                                    <div className="input-group">
                                      <div className="col-sm-12">
                                        <input type="number" class="form-control no-arrows" value={formData.piece_price1} onChange={handleChange} name="piece_price1" />
                                      </div>
                                      <div className="col-sm-12">
                                        {errors.piece_price1 && <span className="text-danger">{errors.piece_price1}</span>}
                                      </div>
                                    </div>
                                  </div>
                                  
                                </div>

                                <div class="row mb-3">
                                <div class="col-4 col-lg-3 col-sm-3">
                                    <label for="desc" class="col-form-label"> Piece Price 3 <span style={{ color: 'red' }}> * </span></label>
                                  </div>
                                  <div class="col-8 col-lg-3 col-sm-3">
                                    <div className="input-group">
                                      <div className="col-sm-12">
                                        <input type="number" class="form-control no-arrows" value={formData.piece_price2} onChange={handleChange} name="piece_price2" />
                                      </div>
                                      <div className="col-sm-12">
                                        {errors.piece_price2 && <span className="text-danger">{errors.piece_price2}</span>}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-4 col-lg-3 col-sm-3">
                                    <label for="desc" class="col-form-label"> Piece Price 4 <span style={{ color: 'red' }}> * </span></label>
                                  </div>
                                  <div class="col-8 col-lg-3 col-sm-3">
                                    <div className="input-group">
                                      <div className="col-sm-12">
                                        <input type="number" class="form-control no-arrows" value={formData.piece_price3} onChange={handleChange} name="piece_price3" />
                                      </div>
                                      <div className="col-sm-12">
                                        {errors.piece_price3 && <span className="text-danger">{errors.piece_price3}</span>}
                                      </div>
                                    </div>
                                  </div>
                                  
                                </div>


                                <div class="row mb-3">

                                <div class="col-4 col-lg-3 col-sm-3">
                                    <label for="desc" class="col-form-label"> Piece Price 5 <span style={{ color: 'red' }}> * </span></label>
                                  </div>
                                  <div class="col-8 col-lg-3 col-sm-3">
                                    <div className="input-group">
                                      <div className="col-sm-12">
                                        <input type="number" class="form-control no-arrows" value={formData.piece_price4} onChange={handleChange} name="piece_price4" />
                                      </div>
                                      <div className="col-sm-12">
                                        {errors.piece_price4 && <span className="text-danger">{errors.piece_price4}</span>}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-4 col-lg-3 col-sm-3">
                                    <label for="desc" class="col-form-label"> Piece Price 6 <span style={{ color: 'red' }}> * </span></label>
                                  </div>
                                  <div class="col-8 col-lg-3 col-sm-3">
                                    <div className="input-group">
                                      <div className="col-sm-12">
                                        <input type="number" class="form-control no-arrows" value={formData.piece_price5} onChange={handleChange} name="piece_price5" />
                                      </div>
                                      <div className="col-sm-12">
                                        {errors.piece_price5 && <span className="text-danger">{errors.piece_price5}</span>}
                                      </div>
                                    </div>
                                  </div>


                                </div>
                              </div>
                            </div>
                          )}

                        </div>
                       
                       
                      </div>
                      <div className='col-6 col-lg-6 col-sm-6'>

                        <div class="mb-3">
                          <div class="col-12 col-lg-12 col-sm-12">
                          
                              <label for="desc" class="col-form-label">Select Supplier </label>
                            
                          </div>

                          <div class="">
                            <SupplierSuggestionList  />
                          </div>

                        </div>
                        <div class="row mb-3" style={{ display: "none" }}>
                          <div class="col-6">
                            <div class="col-12 col-lg-12 col-sm-12">
                              <label for="desc" class="col-form-label">Minimum Shelf Life (in Days)</label>
                            </div>
                            <div class="col-12 col-lg-12 col-sm-12">
                              <input type="number" class="form-control no-arrows" value={formData.min_shelf_life} onChange={handleChange} name="min_shelf_life" />
                            </div>
                          </div>
                          <div class="col-6">
                            
                            <div class="col-12 col-lg-12 col-sm-12">
                              <label for="desc" class="col-form-label">Maximum Shelf Life (in Days)</label>
                            </div>
                            <div class="col-12 col-lg-12 col-sm-12">
                              <input type="number" class="form-control no-arrows" value={formData.max_shelf_life} onChange={handleChange} name="max_shelf_life" />
                            </div>


                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-6">
                          <div className='row'>

                          <div class="col-12 col-lg-7 col-sm-7">
                              <label for="desc" class="col-form-label">Barcode Symbology <span style={{ color: 'red' }}> * </span></label>
                              <BarcodeSuggestionBox className='abovedd' />
                              </div>
                            


                            <div class="col-12 col-lg-5 col-sm-5">
                            <label for="desc" class="col-form-label">Bay</label>
                            <input type="text" class="form-control" value={formData.bay} onChange={handleChange} name="bay" />
                            </div>

</div>
                          </div>
                          <div class="col-6">

                            <div className='row'>
                              <div class="col-12 col-lg-6 col-sm-6">
                              <label for="desc" class="col-form-label">Rack</label>
                              <input type="text" class="form-control" value={formData.rack} onChange={handleChange} name="rack" /> 
                              </div>
                              <div class="col-12 col-lg-6 col-sm-6">
                              <label for="desc" class="col-form-label">Alert Quantity</label>
                              <input type="text" class="form-control" value={formData.alert_quantity} onChange={handleChange} name="alert_quantity" />
                            </div>
                            </div>


                          </div>
                        </div>
                        <div class="row mb-3">

                          <div class="col-4">
                            <div class="col-12 col-lg-12 col-sm-12">
                              <label for="desc" class="col-form-label">Inner EAN</label>
                            </div>
                            <div class="col-12 col-lg-12 col-sm-12">
                              <input type="text" class="form-control" value={formData.inner_ean_number} onChange={handleChange} name="inner_ean_number" />
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="col-12 col-lg-12 col-sm-12">
                              <label for="desc" class="col-form-label">Outer EAN</label>
                            </div>
                            <div class="col-12 col-lg-12 col-sm-12">
                              <input type="text" class="form-control" value={formData.outer_ean_number} onChange={handleChange} name="outer_ean_number" />
                            </div>
                          </div>

                          <div class="col-4">
                            <div class="col-12 col-lg-12 col-sm-12">
                              <label for="desc" class="col-form-label">Pallate EAN</label>
                            </div>
                            <div class="col-12 col-lg-12 col-sm-12">
                              <input type="text" class="form-control" value={formData.outer_ean_number} onChange={handleChange} name="outer_ean_number" />
                            </div>
                          </div>

                        </div>



                        <div class="row mb-3">
                          <div class="col-6">
                            <div class="col-12">
                              <div class="form-check">
                                <label class="checkbox">
                                  <input
                                    type="checkbox"
                                    name="new_arrival"
                                    checked={formData.new_arrival === '1'}
                                    onChange={handleCheckboxChange}
                                    value="1"
                                  />
                                  <span class="default"></span>
                                </label>
                                <label class="form-check-label" for="gridCheck1"> New Arrival </label>
                              </div>

                              {formData.new_arrival === '1' && (
                                <div className='arrivebox'>
                                <div class="col-12">
                                  <div class="col-12">
                                    <label for="desc" class="col-form-label">Arrival Ends on</label>
                                  </div>
                                  <div class="col-12">
                                    <input type="date" class="form-control" value={formData.new_arrival_date} onChange={handleChange} name="new_arrival_date" />
                                  </div>
                                </div>
                                </div>
                              )}

                            </div>
                          </div>

                          <div class="col-6">
                            <div class="col-12">
                              <div class="form-check">
                                <label class="checkbox">

                                  <input
                                    type="checkbox"
                                    name="is_discount_allow"
                                    checked={formData.is_discount_allow === '1'}
                                    onChange={handleCheckboxChange}
                                    value="1"
                                  />
                                  <span class="default"></span>
                                </label>
                                <label class="form-check-label" for="gridCheck2"> Discount Product </label>
                              </div>
                              {formData.is_discount_allow === '1' && (
                                <div className='arrivebox'>
                                <div class="col-12">
                                  <div class="col-12">
                                    <label for="desc" class="col-form-label">Discount Percentage</label>
                                  </div>
                                  <div class="col-12">
                                    <input type="number" class="form-control no-arrows" value={formData.percentage} onChange={handleChange} name="percentage" />
                                  </div>
                                </div>
                                </div>

                              )}

                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          

                        <div class="row col-6">
                            <div class="col-12 col-lg-12 col-sm-12">
                              <label for="desc" class="col-form-label">Product Image</label>
                            </div>
                            <div class="col-12 col-lg-12 col-sm-12">
                              

                              <input
                                type="file"
                                className="form-control"
                                id="product_image_input"
                                name="product_image"
                                accept="image/png, image/jpeg"
                                onChange={handleFileChange}
                              />

                              <input type="hidden" name="old_image" value={formData.old_image} />
                            </div>
                            {imagePreview && (
                              <div className="col-12 mt-2" style={{ position: 'relative' }}>
                                
                                {imagePreview && (
                                  <img
                                    src={imagePreview}
                                    alt="Preview"
                                    className="img-thumbnail mt-2"
                                    style={{ maxWidth: '100px', marginRight: '10px' }}
                                  />
                                )}
                                <button
                                  type="button"
                                  className="small-cros-icon"
                                  onClick={handleRemoveImage}
                                  
                                >
                                  X
                                </button>
                              </div>
                            )}
                          </div>

                          <div class="col-6">
                            
                            <div class="col-12 col-lg-12 col-sm-12">
                            <label for="desc" class="col-form-label">&nbsp;</label>
                              <div class="form-check">
                                <label class="checkbox">
                                  <input type="checkbox" name="featured" value="1" />
                                  <span class="default"></span></label>
                                <label class="form-check-label" for="gridCheck1"> Featured (Shop homepage listing) </label>
                              </div>
                            </div>
                          </div>
                        </div>
                       


                      </div>
                      <div className='col-12 col-lg-12 col-sm-12'>
                        <hr />
                      </div>
                      <div className='row padd-l-13px'>
                        {/* <div className='col-6 col-lg-6 col-sm-6'>
                          <div class="row mb-3">
                            <label for="inputPassword" class="col-sm-2 col-form-label">Product Details</label>
                            <div class="col-sm-10">
                              <textarea name="details" value={formData.details} onChange={handleChange} class="form-control" style={{ height: '70px' }}></textarea>
                            </div>
                          </div>
                        </div> */}
                        {/* <div className='col-6 col-lg-6 col-sm-6'>
                          <div class="row">
                            <label for="inputPassword" class="col-sm-2 col-form-label">Product details for invoice</label>
                            <div class="col-sm-10">
                              <textarea name="product_details" value={formData.product_details} onChange={handleChange} class="form-control" style={{ height: '70px' }}></textarea>
                            </div>
                          </div>
                        </div> */}
                      </div>



                      {/* <div class="col-6">
                            <div class="col-12 col-lg-12 col-sm-12">
                              <label for="desc" class="col-form-label">Barcode Symbology <span style={{ color: 'red' }}> * </span></label>
                            </div>
                            <div class="col-12 col-lg-12 col-sm-12">
                              <BarcodeSuggestionBox className='abovedd' />
                            </div>
                          </div> */}

                      
                      <div className='row mb-3 padd-l-13px'>
                        <div className='col-12 col-lg-12 col-sm-12' style={{ textAlign: "center"  }}>
                          <button type="button" disabled={isSubmitting} onClick={submitProductForm} class="common-popup-btn">Add Product</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Add_Products; 
