import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import jsPDF from 'jspdf';  // Library to generate PDF
import 'jspdf-autotable';  // Plugin for auto table
import generateReturnPDF from './generateReturnPDF';


const ReturnListPrint = (props) => {

	const { orderId } = useParams();
	const [shopDetails, setShopDetails] = useState([]);
	const [orderDetails, setOrderDetails] = useState([]);
	const [productDetails, setProductDetails] = useState([]);
	const [totalVal, setTotalVal] = useState({
	    totalstd: 0,
	    totalzeroGoods: 0,
	    totalTax: 0,
	    orderDiscount: 0,
	    grandTotal: 0,
	  }
	);
	const accessToken = localStorage.getItem('accessToken');
	

	const fetchInvoice = async () => {
	    const formData = new FormData();
	    formData.append('id', orderId);
	    const apiUrl = `${config.API_URL}/return/returnView`;
	    try {
	      const response = await fetch(apiUrl, {
	        method: 'POST',
	        headers: {
	          authorization: `Bearer ${accessToken}`,
	        },
	        body: formData,
	      });

	      if (!response.ok) {
	        throw new Error('Failed to fetch Not Order Report');
	      }
	      const data = await response.json();
	      //console.log(data.details.shop_details);
	      setShopDetails(data.data.shop_details);
	      setOrderDetails(data.data.commonData);
	      setProductDetails(data.data.itemData);
	      
	      const totalstd = data.data.itemData
	        .filter(item => item.tax !== '0.00' && item.tax !== '0' && item.tax != null)
	        .reduce((sum, item) => sum + (parseFloat(item.unit_price) * parseFloat(item.quantity)), 0);

	      const totalzeroGoods = data.data.itemData
	        .filter(item => item.tax === '0.00' || item.tax === '0'  || item.tax === null)
	        .reduce((sum, item) => sum + (parseFloat(item.unit_price) * parseFloat(item.quantity)), 0);

	      // Set the total values
	      setTotalVal({
	        totalstd,
	        totalzeroGoods,
	        totalTax: parseFloat(data.data.commonData.total_tax),
	        orderDiscount: 0,
	        grandTotal: parseFloat(data.data.commonData.grand_total),
	      });

	      
	    } catch (error) {
	      console.error('api fail Error fetching Not Order Report:', error);
	    }
	  };


	useEffect(() => {
	   if (accessToken === null) {
			window.location.href = `${config.BASE_URL}/login`;
			return;
		}
	    fetchInvoice();
	},[]);

	useEffect(() => {
    	const table = $('#invoicePrint').DataTable({
      		pageLength: 50,
      		paging: false,
      		lengthMenu: [10, 20, 50],
      		searching: false,
      		ordering: false,
      		info: false,
      		responsive: true,
      		autoWidth: false,
      		data: productDetails,
      		columns: [
        		{ 
        			data: null,
         			render: function (data, type, row) { 
            			return `<span>${row.product_code} - ${row.product_name}</span> `;
          			}
        		},
        		{ data: null, className : 'text-center',
        			render : function(data, type, row){
        				return `<span>${parseInt(row.quantity)}</span>`;
        			}
        		},
        		{ 
        			data: 'unit_price',
        			className : 'text-center',
        			render: function (data, type, row) { 
        				return row.unit_price ? `<span>£ ${parseFloat(row.unit_price).toFixed(2)}</span>` : `<span>£ 0.00</span>`;
          			}
        		},
        		{ 
        			data: 'item_tax',
        			className : 'text-center',
        			render: function (data, type, row) { 
						return row.tax ? `<span>${row.tax}</span>` : `<span>0.00</span>`;
          			}
        		},
        		{ 
        			data: 'subtotal',
        			className : 'text-center',
        			render: function (data, type, row) { 
        				return row.subtotal ? `<span>£ ${parseFloat(row.subtotal).toFixed(2)}</span>` : `<span>£ 0.00</span>`;
          			}
        		},
        		
    		],
    		columnDefs: [
		        {
		          targets: [0], 
		          orderable: false
		        }
	      	],
    		drawCallback: function (settings) {
        		var api = this.api();
        		
		      $('tfoot').empty();
        
		        // Create footer rows with totals
		        const footerRows = [
		          {
		            colSpan: 4,
		            label: 'Total Std Goods',
		            value: `£${totalVal.totalstd.toFixed(2)}`
		          },
		          {
		            colSpan: 4,
		            label: 'VAT Amount',
		            value: `£${totalVal.totalTax.toFixed(2)}`
		          },
		          {
		            colSpan: 4,
		            label: 'Total Zero Goods',
		            value: `£${totalVal.totalzeroGoods.toFixed(2)}`
		          },
		          {
		            colSpan: 4,
		            label: 'Invoice Total',
		            value: `£${totalVal.grandTotal.toFixed(2)}`
		          }
		        ];
		        
		        // Append rows to the footer
		        footerRows.forEach(row => {
		          const footerRow = $('<tr>')
		            .append(`<td colSpan="${row.colSpan}"><span class="right">${row.label}</span></td>`)
		            .append(`<td><span>${row.value}</span></td>`);
		          $('tfoot').append(footerRow);
		        });
    		}
    	});
    	return () => {
      		table.destroy();
    	};
  	}, [productDetails, totalVal]);


	


  const handleGenerateDeliveryNotePDF = (e, orderId) => {
	    generateReturnPDF(orderId);
	}

	return (
		<>
			<Header />
	  		<div className="breadcrumbs">
			  	<div className="container-fluid">
			    	<div className="row">
			      		<div className="col-12 col-lg-12 col-sm-12"> 
			        		<Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
			        		<Link to="/picking/proforma">Proforma Invoice</Link> 
			        		<span className="last-crumb">Invoice Details</span> 
			      		</div>
			   	 	</div>
			  	</div>
			</div>
			<div className="main-container">
        		<div className="container-fluid">
        			<div className="row">
				      	
				      	<div className="col-6">
				      		{/*<button onClick={(e) => handleGenerateDeliveryNotePDF(e, orderId)}>
									  Generate PDF
									</button>*/}
				      	</div>
				    	</div>
        				<div className="row">
			            <div className="col-12">
			              <div className="show-order-area dashboard">
			                <div className="sales-view-area">
			                  
			                  	<div className="row" style={{paddingBottom: '10px', borderBottom: '1px solid #ccc'}} >
				                    <div className="col-7">
				                      <p>
				                        <span>{shopDetails.shop_name}</span><br /> TEL {shopDetails.phone}<br />
				                        VAT NO,<br />
				                        <span>COMPANY REG NO : {shopDetails.company_reg_no}</span>
				                      </p>
				                    </div>
				                    <div className="col-5">
				                      <ul>
				                        <li>PLEASE PHONE THE OFFICE TO <br /> PAY BY CREDIT/DEBIT CARD</li>
				                        <li><span>Bank A/C :</span> {shopDetails.bank_ac}</li>
				                        <li><span>Sort Code :</span> {shopDetails.sort_code}</li>
				                      </ul>
				                    </div>
			                  	</div>			                  	
			                  	<div className="row" style={{paddingTop: '10px'}}>
				                    <div className="col-7">
				                      <p>
				                        <span>{orderDetails.company}</span><br />{orderDetails.address}<br />
				                        {orderDetails.city}, {orderDetails.postal_code}, {orderDetails.country}<br />
				                       
				                      </p>
				                    </div>
				                    <div className="col-5">
				                      <ul>
				                        <li><span>Credit Note No:</span>  INV{orderDetails.reference_no}</li>
				                        <li><span>Credit Date:</span> {orderDetails.date}</li>
				                        <li><span>Cust Account No: :</span> {orderDetails.reference_no}</li>
				                        
				                      </ul>
				                    </div>
			                  	</div>

			                  <div className="sales-view-table">
			                    <table className="table table-bordered" id="invoicePrint">
			                      <thead>
			                        <tr>
			                          <th style={{backgroundColor: '#e28483'}} >Description</th>
			                          <th style={{backgroundColor: '#e28483'}} >Quantity</th>
			                          <th style={{backgroundColor: '#e28483'}} >Unit Price</th>
			                          <th style={{backgroundColor: '#e28483'}} >VAT Amount</th>
			                          <th style={{backgroundColor: '#e28483'}} >Net Amount</th>
			                        </tr>
			                      </thead>
			                      <tbody>



			                      </tbody>
			                      <tfoot>
			                      	<tr>
			                          <td colSpan="4">
			                          	<span className="right">Total Std Goods</span>
			                          </td>
			                          <td><span>£43.84</span></td>
			                        </tr>
			                        <tr>
			                          <td colSpan="4">
			                          	<span className="right">VAT Amount</span>
			                          </td>
			                          <td><span>£8.77</span></td>
			                        </tr>
			                        <tr>
			                          <td colSpan="4">
			                          	<span className="right">Total Zero Goods</span>
			                          </td>
			                          <td><span>0.00</span></td>
			                        </tr>
			                        <tr>
			                          <td colSpan="4">
			                          	<span className="right">Order Discount</span>
			                          </td>
			                          <td><span>0.00</span></td>
			                        </tr>
			                        <tr>
			                          <td colSpan="4">
			                          	<span className="right">Total Amount</span>
			                          </td>
			                          <td><span>£52.61</span></td>
			                        </tr>
			                      </tfoot>
			                    </table>
			                    <p style ={{textAlign: 'center'}}>No claims for damages or short delivery will be considered unless made at the time of delivery of goods.These goods are not sold on a sale or return basis.</p>
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>






        		</div>
        	</div>

			<Footer />
		</>

	);
}

export default ReturnListPrint;
