import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { API_URL, BASE_URL } from './Config';
import config from "./common/Config";


const CompleteTrips = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [completeTrips, setCompleteTrips] = useState([]); // State to store statistics data
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const accessToken = localStorage.getItem('accessToken');


  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('role', selectedRole);

    const apiUrl = `${config.API_URL}/get_complete_trip_list`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch new sales data');
      }

      const data = await response.json();
      setCompleteTrips(data.data);
      //console.log(data);
    } catch (error) {
      console.error('Error fetching new sales data:', error);
    }
  };

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }
    fetchData();
  }, [fromDate, toDate, selectedRole]);

  useEffect(() => {
    const table = $('#completeTripsTable').DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: false,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
      data: completeTrips,
      columns: [
        { data: null },
        { data: 'date' },
        { data: 'trip_number',
        render: function (data, type, row) {
            return '<a href="'+BASE_URL+'/complete_trips/closed_trip_summary/' + row.id + '">' + row.trip_number + '</a>';
        }
    },
    { data: 'driver' },
    { data: 'vehicle' , className: "text-center" },
    // { data: 'status', className: "text-center" },
    //{ data: 'sale_status' },  
    { 
      data: null,
      render: function (data, type, row) {                
            
            return '<span class="btn btn-primary badge " >'  + row.status + '</span>';
         
      }
  },
    ],
    columnDefs: [
    { 
        targets: [0,1,2,3,4,5], 
        sortable: false 
    }
    ],
    drawCallback: function (settings) {
        var api = this.api();
        var startIndex = api.page.info().start;
        api.column(0, { page: 'current' }).nodes().each(function (cell, i) {
            cell.innerHTML = startIndex + i + 1;
        });
    }
    });
    return () => {
      table.destroy();
    };
  }, [completeTrips]);
  return (
    <div>
      <Header />
      <div className="breadcrumbs">
          <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-lg-12 col-sm-12"> 
                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
                  <Link to="/CompleteTrips">Closed Trips</Link> 
                </div>
            </div>
          </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard">
                
                <div className="verical-scrl-wrapper complete-trips-area" id="style-3" style={{marginTop: '0px', width : '100%'}} >
                  <table className="table table-bordered dataTable resizable" id="completeTripsTable">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>Date</th>
                        <th>Trip ID</th>
                        <th>Driver</th>
                        <th>Vehicle</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {completeTrips.map((item, index) => (
                        <tr key={index}>
                         
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CompleteTrips;
