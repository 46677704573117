import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import config from "./common/Config";
import Swal from 'sweetalert2';

const NewMarkDelivery = (props) => {

		const { manifestId } = useParams();
		const [options, setOptions] = useState([]);
		const [driver, setSelectedDriver] = useState('');
		const [markDeliveryList, setmarkDeliveryList] = useState([]);
		const [draw, setDraw] = useState(1);
		const [start, setStart] = useState(0);
		const [length, setLength] = useState(50);

		const [manifestOrder, setmanifestOrder] = useState('');
		const [orderOptions, setorderOptions] = useState([]);
		
		const [showModal, setShowModal] = useState(false);
		// const modalRef = useRef(null);

		const accessToken = localStorage.getItem('accessToken'); 

	//   const handleShow = () => {
	//      if (modalRef.current) {
	//       const modal = new window.bootstrap.Modal(modalRef.current);
	//       modal.show();
	//     }
	//   };

useEffect(() => {
  async function fetchDriverOptions() {
    try {
      // Correctly passing headers as the third argument
      const response = await axios.post(
        `${config.API_URL}/getDriver`, // API URL
        {}, // You can pass the data to be sent in the body (empty object if none)
        {
          headers: {
            authorization: `Bearer ${accessToken}`, // Authorization header
          },
        }
      );

      // Check for valid response
      if (!response.data || !response.data.data.driver || !Array.isArray(response.data.data.driver)) {
        throw new Error('Invalid response format or empty driver array');
      }

      // Format options from the response
      const formattedOptions = response.data.data.driver.map(item => ({
        key: `${item.first_name} ${item.last_name}`,
        value: item.id,
      }));

      setOptions(formattedOptions); // Set the driver options
    } catch (error) {
      console.error('Error fetching driver options:', error);
      // Handle error as needed, e.g., show a message to the user
    }
  }

  fetchDriverOptions();
}, []);


  	useEffect(() => {
    	async function fetchOrderOptions() {
	      	try {
	      		const response = await axios.get(`${config.API_URL}/manifest_orderlist`, {
	         		headers: {
	            		authorization: `Bearer ${accessToken}`,
	          		},
	        	});
	        	if (!response.data || !response.data.data.orders || !Array.isArray(response.data.data.orders)) {
	          		throw new Error('Invalid response format or empty order array');
	        	}
	        	const manifest_orderOptions = response.data.data.orders.map(item => ({
	          		key: `${item.reference_no}`,
	          		value: item.id,
	        	}));
	  
	        	setorderOptions(manifest_orderOptions);
	      	} catch (error) {
	        	console.error('Error fetching orders options:', error);
	        	// Handle error as needed, e.g., show a message to the user
	      	}
	    }
    	fetchOrderOptions();
  	}, []);

  	const fetchData = async () => {
  		const formData = new FormData();

  		formData.append('id_new', manifestId);
  		formData.append('draw', draw);
	    formData.append('start', start);
	    formData.append('length', length);

	    const apiUrl = `${config.API_URL}/manifest_list/get_mark_delivery`; 
	    try {
	      	const response = await fetch(apiUrl, {
	        	method: 'POST',
	        	headers: {
	          		authorization: `Bearer ${accessToken}`,
        		},
	        	body: formData,
	      	});
	      	if (!response.ok) {
	        	throw new Error('Failed to fetch new sales data');
	      	}

	      	const data = await response.json();
	      	//console.log(data.data2);
	      	setmarkDeliveryList(data.data);

	      	setSelectedDriver(data.data2[0].manifest.driver_id);
	      	

	    } catch (error) {
	      console.error('Error fetching new sales data:', error);
	    }
  	};

  	useEffect(() => {
    	fetchData();
  	}, []);

  	useEffect(() => {
    	const table = $('#markDeliverytable').DataTable({
      		pageLength: 50,
      		paging: true,
      		lengthMenu: [10, 20, 50],
      		searching: true,
      		ordering: false,
      		info: true,
      		responsive: true,
      		autoWidth: false,
      		language: {
        		paginate: {
          			first: 'First',
          			last: 'Last',
          			next: 'Next',
          			previous: 'Previous',
        		},
       			search: 'Search:',
      		},
      		data: markDeliveryList,
      		columns: [          
        		{ data: 'date', width : '9%' },    
        		{ data: 'invoice_num', width : '10%' },
        		{ data: 'customer',  },
        		{ data: 'invoice_amt', width : '10%' },
        		{ data: 'sales_person_collected', width : '12%' },
        		{ data: 'previous_dues', width : '10%' }
    		],
    		columnDefs: [
        		{ targets: [], sortable: true }
    		]
    	});
    	return () => {
      		table.destroy();
    	};
  	}, [markDeliveryList]);

  	const handleClick = async () => {
  		let selmainval = `${manifestOrder}`;
	    if(!selmainval){
	    	
	    	Swal.fire({
		        title: 'Error',
		        text: 'Please Select order.',
		        icon: 'error',
		        showConfirmButton: false, 
		        timer: 2000,               
		        timerProgressBar: true,     
		      });
	    }else{
	    	const formOrder = new FormData();
	  		formOrder.append('update_id', manifestId);
	  		formOrder.append('order_id', selmainval);
	  		const orderUrl = `${config.API_URL}/manifest_add_order`; 
	  		try {
	  			
		      const res = await fetch(`${config.API_URL}/manifest_add_order`, {
		        method: 'POST',
		        headers: {
		         authorization: `Bearer ${accessToken}`,
		        },
		        body: formOrder,
		      });
		      if (!res.ok) {
		        throw new Error('Network response was not ok');
		      }
		      const data = await res.json();
		       fetchData();
		      Swal.fire({
		          title: 'Success',
		          text: 'order added successful!.',
		          icon: 'success',
		          showConfirmButton: false, 
		          timer: 2000,               
		          timerProgressBar: true,     
		        });
		     
		    } catch (err) {
		      console.log(err.message);
		    }


	    }
	  };

	  const changeDriver = async () =>{
	  	let driverid = `${driver}`;
	  	if(!driverid){
	  		alert("Select Driver");
	  	}else{
	  		console.log(driverid);
	  	}
	  };

  	return (
  		<>
	  		<Header />
	  		<div className="breadcrumbs">
			  	<div className="container-fluid">
			    	<div className="row">
			      		<div className="col-12 col-lg-12 col-sm-12"> 
			        		<Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
			        		<Link to="/ManifestList">Dispatch & Deliveries</Link> 
			        		<span className="last-crumb">Manifest List</span> 
			      		</div>
			   	 	</div>
			  	</div>
				</div>
			 
				<div className="main-container">
        	<div className="container-fluid">
          	<div className="row">
            	<div className="col-12 col-lg-12 col-sm-12">
            		<div className="row">

                	<div className="col-6 col-lg-5 col-sm-5">
					<div className="row">
					<div className="col-5 col-lg-5 col-sm-7">
                		<select value={driver} onChange={(e) => setSelectedDriver(e.target.value)} className="datatable-selector form-select" name="driver_list" id="driver_list"  style={{marginRight: "15px"}} >
				                <option value="">Select a driver</option>
				                  {options.map(option => (
				                        <option key={option.value} value={option.value}>
				                          {option.key}
				                        </option>
				                  ))}
				            </select>
							</div>
							<div className="col-7 col-lg-7 col-sm-5">
	                  <button type="button" onClick={changeDriver}  className="acceptbtn" style={{float: 'left'}}>
	                      Submit
	                  </button>
					  </div>
</div>

					  </div>
					  <div className="col-7 col-lg-7 col-sm-7">
	                  <div style={{ float: 'right', textAlign: 'right' }}>
	                    <button  type="button"  className="acceptbtn" data-bs-toggle="modal" data-bs-target="#addNewOrderModal">
						
	                      Add New Order
	                    </button>
	                  </div>
                	</div>


                </div>

              	<div className="show-order-area dashboard">
              		<div className="verical-scrl-wrapper menifest-area" style={{ marginTop: '0px' }} id="style-3">
                    <table className="table table-bordered dataTable resizable" id="markDeliverytable">
	                    <thead>
					              <tr>
                        	<th>Invoiced Date</th>
                        	<th>Invoice Number</th>
                        	<th>Customer Name(A/c No)</th>
                        	<th>Invoice Amount</th>
                        	<th>Sales Rep Collected</th>
                        	<th>Previous Dues</th>
					               </tr>
					              </thead>
					              <tbody>

					              </tbody>
					            </table>
					          </div>
					        </div>
					    </div>
					  </div>
				  </div>
				</div>
	  		<Footer />

	  		
	  		 
      	<div className="modal fade" aria-hidden="true" tabIndex="-1" role="dialog" id='addNewOrderModal'>
        	<div className="modal-dialog modal-dialog-centered" role="document">
          	<div className="modal-content">
            	<div className="modal-header">
              	<h5 className="modal-title">ADD ORDER</h5>
              	
				  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            	</div>
            	<div className="modal-body">

              	<select value={manifestOrder} onChange={(e) => setmanifestOrder(e.target.value)} className="datatable-selector form-control" name="order_list" id="order_list"  style={{marginRight: "15px"}} >
		                <option value="">Select</option>
		                  {orderOptions.map(option => (
		                        <option key={option.value} value={option.value}>
		                          {option.key}
		                        </option>
		                  ))}
		            </select>

            	</div>
            	<div className="modal-footer">
              	<button type="button" className="common-closebtn" data-bs-dismiss="modal" >Close button</button>
              	<button type="button" className="float-right-btn common-popup-btn" data-bs-dismiss="modal" onClick={handleClick} >Save changes</button>
            	</div>
          	</div>
        	</div>
      	</div>

  		</>
  	);

};

export default NewMarkDelivery;