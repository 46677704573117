import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import FlashMessage from "../FlashMessage";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { Link } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import config from "../common/Config";
import { format } from "date-fns";
import { DateRangePicker } from "rsuite";

const MaxValueOrder = () => {
  const accessToken = localStorage.getItem("accessToken");
  const [totalOrder, setTotalOrder] = useState('');
  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const [totalAmount, setTotalAmount] = useState('');
  const [average, setAverage] = useState('');
  const [dates, setDates] = useState([null, null]);
  const TableRef = useRef(null);

  let dateRange = '';
  useEffect(() => {
    if (accessToken === null) {
      window.location.href = "/react/login";
      return;
    }

    initializeTable();
  }, []);

 
  const handleDateChange = (value) => {
    setDates(value);
    if (value && value[0] && value[1]) {
       s = value[0] ? format(value[0], 'yyyy-MM-dd') : '';
       e = value[1] ? format(value[1], 'yyyy-MM-dd') : '';  
      }
    };


  const s = localStorage.getItem('startDate');
  const e = localStorage.getItem('endDate');

  const handleSubmit = () => {
    initializeTable(); 
  };

  const initializeTable = () => {
    const $table = $(TableRef.current);
    if ($.fn.DataTable.isDataTable($table)) {
        $table.DataTable().destroy();
    }
  
    let i = 0;
    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
          url: `${config.API_URL}/getCustomerOrder`,
          type: 'POST',
          headers: {
             authorization : `Bearer ${accessToken}`,
          },
          data: function (d) {
              return $.extend({}, d, {
                  draw : d.draw,
                  start : d.start,
                  length : d.length,
                  start_date : s ? s : '',
                  end_date : e ? e : ''
              });
          },
          dataSrc: function (json) {
            return Array.isArray(json.data) ? json.data : [];
          }
      },
      language: {
          paginate: {
              first: 'First',
              last: 'Last',
              next: 'Next',
              previous: 'Previous',
          },
          search: 'Search:',
      },
      columns: [
        {
            data: null, 
            render: function(data, type, row, meta) {
              return meta.row + 1;
            },
            createdCell: function(td) {
              $(td).css("text-align", "center"); 
            }
          },
        { data: "accound_no",
            createdCell: function(td) {
              $(td).css("text-align", "center"); 
            } },        
        { data: "name" },
        { data: "total_orders",
            createdCell: function(td) {
              $(td).css("text-align", "center"); 
            } },
        { data: "total_amount" }
      ],
      columnDefs: [
        {
          orderable: false, 
          targets: '_all'
        },
      ],
    
      pageLength: 10,
    });
  };










  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <Link to="#">Sales</Link>
              <Link to="#">Max Order Value</Link>
            </div>
          </div>
        </div>
      </div>
     
      <div className="main-container">
        <div className="container-fluid">
        <div className="col-md-12 col-lg-12 col-xs-12">
            <div className="col-md-12 col-xs-12">
            <div className="row">
		        	<div className="col-md-12 col-lg-12 col-sm-12">
              <DateRangePicker
                                value={dates}
                                onChange={handleDateChange}
                                format="dd/MM/yyyy" // Ensure the calendar uses dd/MM/yyyy format
                                character=" - "
                                className="datepkr"
                              />
                            <button
                              type="button"
                              id="submit"
                              className="multisubmit"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                <div className="col-md-12 col-xs-12 " style={{paddingTop : '7px'}}>
                <div className="col-md-2 cp-lg-2 col-sm-3" style={{float: 'left', textAlign: 'center'}}>	
                    <label style={{marginBottom: '5px', float:'left', width: '100%'}} ><b>Total Order : </b></label>{totalOrder} 
                    </div>
                    <div className="col-md-2 cp-lg-2 col-sm-3" style={{float: 'left', textAlign: 'center'}}>	
                    <label style={{marginBottom: '5px', float:'left', width: '100%'}} ><b>Total Amount : </b></label> {Number(totalAmount).toFixed(2)}
                   </div>
                   <div className="col-md-2 cp-lg-2 col-sm-3" style={{float: 'left', textAlign: 'center'}}>	
                    <label style={{marginBottom: '5px', float:'left', width: '100%'}} ><b>Average : </b></label> {Number(average).toFixed(2)}
                   </div>
                  </div>
              </div>
		        </div>
          </div>
        </div>



          <div
            className="total-manifests-area dashboard"
            style={{ position: "relative" }}>
            <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
              <table className="table table-bordered resizable" id="neworderTable" ref={TableRef}>
                <thead>
                  <tr>
                    <th style={{textAlign:'center'}}>#</th>
                    <th style={{textAlign:'center'}}>Account No</th>
                    <th style={{textAlign:'center'}}>Customer Name</th>
                    <th style={{textAlign:'center'}}>Total Orders</th>
                    <th style={{textAlign:'center'}}>Total Amount</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MaxValueOrder;
