const Config = {


 API_URL : 'https://testapi.rameegroup.co.in', 

 //BASE_URL : 'https://kmfood.co.uk',

 //BASE_URL : 'https://dan.rameegroup.co.in/react',

 // BASE_URL : 'http://localhost:3000',

 BASE_URL : 'https://dan.rameegroup.co.in/react',

 
 DEMO_URL : 'https://demo.ebusso.com',


 LOG_OUT : 'https://dan.rameegroup.co.in/react',
};
  
export default Config;
  
 
