import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import jsPDF from 'jspdf';  // Library to generate PDF
import 'jspdf-autotable';  // Plugin for auto table
import printNewSalesInvoice from './printNewSalesInvoice';

const NewSalesInvoice = (props) => {

	const { orderId } = useParams();
	const [shopDetails, setShopDetails] = useState([]);
	const [orderDetails, setOrderDetails] = useState([]);
	const [productDetails, setProductDetails] = useState([]);
	const [totalVal, setTotalVal] = useState(
  {
   totalstd: 0,
   totalzeroGoods: 0,
   totalTax: 0,
   orderDiscount: 0,
   grandTotal: 0,
  }
 );

	const accessToken = localStorage.getItem('accessToken');

	const fetchInvoice = async () => {
   const formData = new FormData();
   formData.append('orderId', orderId);
   const apiUrl = `${API_URL}/ws-order-history-details`;
   try {
     const response = await fetch(apiUrl, {
       method: 'POST',
       headers: {
         authorization: `Bearer ${accessToken}`,
       },
       body: formData,
     });

     if (!response.ok) {
       throw new Error('Failed to fetch Not Order Report');
     }
     const data = await response.json();
	      //console.log(data.details.shop_details);
	      setShopDetails(data.details.shop_details);
	      setOrderDetails(data.details.orderDetails[0]);
	      setProductDetails(data.details.product_details);
	      
	    
       // Calculate total standard goods, zero-rated goods, tax, and other totals
        const totalstd = data.details.product_details
            .filter(item => item.item_tax != '0.00' && item.item_tax != null)
            .reduce((sum, item) => sum + (parseFloat(item.unit_price) * parseFloat(item.quantity)), 2);

        const totalzeroGoods = data.details.product_details
            .filter(item => item.item_tax == '0.00' || item.item_tax === null)
            .reduce((sum, item) => sum + (parseFloat(item.unit_price) * parseFloat(item.quantity)), 2);

        // Calculate total tax
        const totalTax = data.details.product_details
            .reduce((sum, item) => sum + (item.item_tax ? parseFloat(item.item_tax) : 0), 0);

        // Calculate order discounts
        const orderDiscount = parseFloat(data.details.orderDetails[0].order_discount) || 0;
        const orderDiscount1 = parseFloat(data.details.orderDetails[0].order_discount1) || 0;

        // Calculate grand total
        const grandTotal = totalstd + totalzeroGoods + totalTax - orderDiscount - (totalstd * orderDiscount1 / 100);

        

        setTotalVal({
            totalstd,
            totalzeroGoods,
            totalTax,
            orderDiscount,
            orderDiscount1,
            grandTotal
        });

	      
	    } catch (error) {
       console.error('api fail Error fetching Not Order Report:', error);
     }
   };

   useEffect(() => {
     if (accessToken === null) {
       window.location.href = `${config.BASE_URL}/login`;
       return;
     }
     fetchInvoice();
   },[]);

   useEffect(() => {
    const table = $('#invoicePrint').DataTable({
      pageLength: 50,
      paging: false,
      lengthMenu: [10, 20, 50],
      searching: false,
      ordering: false,
      info: false,
      responsive: true,
      autoWidth: false,
      data: productDetails,
      columns: [
      { 
        data: null,
        render: function (data, type, row) { 
          return `<span>${row.product_code} - ${row.product_name}</span> `;
        }
      },
      { 
        data: null, 
        className : 'text-center',
        render : function(data, type, row){
          return `<span>${parseInt(row.quantity)}</span>`;
        } 
      },
      { 
        data: null, 
        className : 'text-center',
        render : function(data, type, row){
          return `<span>${row.order_type}</span>`;
        } 
      },
      { 
        data: 'unit_price',
        className : 'text-end',
        width : '10%',
        render: function (data, type, row) { 
         return row.unit_price ? `<span>£ ${row.unit_price}</span>` : `<span>£ 0.00</span>`;
       }
     },
     { 
      data: 'item_tax',
      className : 'text-end',
      width : '10%',
      render: function (data, type, row) { 
        return row.item_tax ? `<span>£ ${row.item_tax}</span>` : `<span>0.00</span>`;
      }
    },
    { 
      data: 'subtotal',
      className : 'text-end',
      width : '10%',
      render: function (data, type, row) { 
      return row.subtotal ? `<span>£ ${parseFloat(row.unit_price * row.quantity).toFixed(2)}</span>` : `<span>£ 0.00</span>`;
     }
   },
   
   ],
   columnDefs: [
   {
    targets: [0], 
    orderable: false
  }
  ],
  drawCallback: function (settings) {
    var api = this.api();	
    $('tfoot').empty();
    
	      // Create footer rows with totals
        const footerRows = [
        {
          colSpan: 5,
          label: 'Total Std Goods',
          value: `£ ${totalVal.totalstd.toFixed(2)}`
        },
        {
          colSpan: 5,
          label: 'VAT Amount',
          value: `£ ${totalVal.totalTax.toFixed(2)}`
        },
        {
          colSpan: 5,
          label: 'Total Zero Goods',
          value: `£ ${totalVal.totalzeroGoods.toFixed(2)}`
        },
        {
          colSpan: 5,
          label: 'Promotion Discount',
          value: `£ ${totalVal.orderDiscount.toFixed(2)}`
        },
        {
          colSpan: 5,
          label: 'Order Discount(%)',
  value: `£ ${totalVal && totalVal.orderDiscount1 ? totalVal.orderDiscount1.toFixed(2) : '0.00'}` // Check for totalVal and orderDiscount1
},
{
  colSpan: 5,
  label: 'Invoice Total',
  value: `£ ${(totalVal && totalVal.grandTotal && totalVal.orderDiscount1 !== undefined)
    ? (totalVal.grandTotal - (totalVal.grandTotal * (totalVal.orderDiscount1 / 100))).toFixed(2)
    : '0.00'}` // Check for grandTotal and orderDiscount1
  }
  ];
  
        // Append rows to the footer
        footerRows.forEach(row => {
          const footerRow = $('<tr>')
          .append(`<td colSpan="${row.colSpan}"><span class="right">${row.label}</span></td>`)
          .append(`<td style="text-align : right;" ><span>${row.value}</span></td>`);
          $('tfoot').append(footerRow);
        });
      }
    });
    return () => {
      table.destroy();
    };
  }, [productDetails, totalVal]);


 const handleGenerateDeliveryNotePDF = (e, orderId) => {
      printNewSalesInvoice(orderId);
  }
return(
  <>
  <Header />
  <div className="breadcrumbs">
  <div className="container-fluid">
  <div className="row">
  <div className="col-12 col-lg-12 col-sm-12"> 
  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
  
  <span className="last-crumb">New Sales Invoice </span> 
  </div>
  </div>
  </div>
  </div>

  <div className="row">
  <div className="col-12">
  <div className="show-order-area dashboard">
  <div className="sales-view-area">
  <div className="row">
  <div class="col-7">
  <button onClick={(e) => handleGenerateDeliveryNotePDF(e, orderId)}>Print Invoice</button>
  
  </div>
  
  </div>
  
  <div className="row page-header" style={{paddingTop: '10px'}}>
  <div className="col-7">
  <p>
  <span>{orderDetails.company}</span><br />{orderDetails.address}<br />
  {orderDetails.city}, {orderDetails.postal_code}, {orderDetails.country}<br />
  
  </p>
  <p>
  <span>Tel : {orderDetails.phone}</span>
  </p>
  <p>
  <span>Email : {orderDetails.email}</span>
  </p>
  <p>
  <span>Customer A/C Number: {orderDetails.account_no}</span>
  </p>
  </div>
  <div className="col-5">
  <ul>
  <li><span>Order :</span>  INV{orderDetails.reference_no}</li>
  <li><span>Order Date :</span> {orderDetails.date}</li>
  <li><span>Sale Status:</span> {orderDetails.sale_status}</li>
  <li><span>Route Number :</span> {orderDetails.route_number}</li>
  <li><span>Payment Status :</span> {orderDetails.payment_status}</li>
  </ul>
  </div>
  </div>

  <div className="sales-view-table">
  <table className="table table-bordered resizable" id="invoicePrint">
  <thead>
  <tr>
    <th style={{backgroundColor: '#e28483'}} >Description</th>
    <th style={{backgroundColor: '#e28483'}} >Quantity</th>
    <th style={{backgroundColor: '#e28483'}} >Type</th>
    <th style={{backgroundColor: '#e28483'}} >Unit Price</th>
    <th style={{backgroundColor: '#e28483'}} >VAT Amount</th>
    <th style={{backgroundColor: '#e28483'}} >Net Amount</th>
  </tr>
  </thead>
  <tbody>

  </tbody>
  <tfoot>
  <tr>
  <td colSpan="4">
  <span className="right">Total Std Goods</span>
  </td>
  <td><span>£43.84</span></td>
  </tr>
  <tr>
  <td colSpan="4">
  <span className="right">VAT Amount</span>
  </td>
  <td><span>£8.77</span></td>
  </tr>
  <tr>
  <td colSpan="4">
  <span className="right">Total Zero Goods</span>
  </td>
  <td><span>0.00</span></td>
  </tr>
  <tr>
  <td colSpan="4">
  <span className="right">Order Discount</span>
  </td>
  <td><span>0.00</span></td>
  </tr>
  <tr>
  <td colSpan="4">
  <span className="right">Total Amount</span>
  </td>
  <td><span>£52.61</span></td>
  </tr>
  </tfoot>
  </table>
  <p style ={{textAlign: 'center'}}>No claims for damages or short delivery will be considered unless made at the time of delivery of goods.These goods are not sold on a sale or return basis.</p>
  </div>
  </div>
  </div>
  </div>
  </div>

  <Footer />
  </>
  );
}

export default NewSalesInvoice;