import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const EditPicking = () => {
  const { picklistNo } = useParams();
  const [options, setOptions] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [picker, setSelectedPicker] = useState('');
  const [newOrders, setNewOrders] = useState([]);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);

  useEffect(() => {
    async function fetchData() {
      const formData = new FormData();
      formData.append('searchByFromdate', fromDate);
      formData.append('searchByTodate', toDate);
      formData.append('draw', draw);
      formData.append('start', start);
      formData.append('length', length);
      formData.append('pickerId', picker);
      formData.append('picklist_number', picklistNo);

      const apiUrl = `${API_URL}/edit_picking_order_list`;
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        window.location.href = `${config.BASE_URL}/login`;
        return;
      }

      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to fetch orders');
        }

        const data = await response.json();
        //console.log(data);
        setNewOrders(data.data || []);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    }

    fetchData();
  }, [fromDate, toDate, picker, picklistNo, draw, start, length]);

  useEffect(() => {
    async function fetchPickerOptions() {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        window.location.href = `${config.BASE_URL}/login`;
        return;
      }

      try {
        const response = await axios.get(`${API_URL}/getPicker`, {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.data || !response.data.picker || !Array.isArray(response.data.picker)) {
          throw new Error('Invalid response format or empty picker array');
        }

        const formattedOptions = response.data.picker.map(item => ({
          key: `${item.first_name} ${item.last_name}`,
          value: item.id,
        }));

        setOptions(formattedOptions);
      } catch (error) {
        console.error('Error fetching picker options:', error);
      }
    }

    fetchPickerOptions();
  }, []);

  useEffect(() => {
    if (newOrders.length) {
      const table = $('#neworderTable').DataTable({
        paging: true,
        lengthMenu: [10, 20, 50],
        searching: true,
        ordering: false,
        info: true,
        responsive: true,
        autoWidth: false,
        language: {
          paginate: {
            first: 'First',
            last: 'Last',
            next: 'Next',
            previous: 'Previous',
          },
          search: 'Search:',
        },
        data: newOrders,
        columns: [
          { data: null, defaultContent: '' },
          { data: 'date' },
          { data: 'customer', render: data => `<p class="mightOverflow col-xs-1">${data}</p>` },
         
          { 
            data: 'reference_no', 
            width: '10%', 
            className: 'text-center',
            render: function (data, type, row) {
                return '<a href="'+BASE_URL+'/trip_summary/print/' + row.action + '">' + row.reference_no + '</a>';
            }
          },
          { data: 'grand_total' },
          { data: 'sales_rep_collected' },
          { data: 'route_number' },
          { data: null, defaultContent: '<a href="#" class="viewbtn" title="Delete"><i class="fa fa-trash"></i></a>' },
        ],
        drawCallback: function () {
          $('#neworderTable .viewbtn').off('click').on('click', function (e) {
            e.preventDefault();
            const row = table.row($(this).closest('tr')).data();
            handleButtonClick(row.reference_no, row.picklist_number);
          });
        },
      });

      return () => {
        table.destroy();
      };
    }
  }, [newOrders]);

  const handleButtonClick = async (orderId, picklistNumber) => {
    console.log('Button clicked:', { orderId, picklistNumber });

    const formData = new FormData();
    formData.append('orderId', orderId);
    formData.append('picklistNumber', picklistNumber);

    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    try {
      const response = await fetch(`${API_URL}/remove_order_picklist`, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('API response:', data);
      // Handle the API response data here
    } catch (error) {
      console.error('API call error:', error);
    }
  };

  const handleSelectionSubmit = async (e) => {
    e.preventDefault();

    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    const formData = new URLSearchParams();
    formData.append('picker_id', picker);
    formData.append('selectedOrderIds', JSON.stringify(selectedOrderIds));
    formData.append('picklist_number', picklistNo);

    try {
      const response = await fetch(`${API_URL}/update_picker`, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
      });

      if (!response.ok) {
        throw new Error('Failed to submit selection');
      }

      await response.json();
      alert("Picking created successfully");
    } catch (error) {
      console.error('Error submitting selection:', error);
    }
  };

  return (
    <div>
      <Header />
      <nav className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#"><i className="fa fa-home"></i></a>
              <span className="last-crumb">Edit Picking</span>
            </div>
          </div>
        </div>
      </nav>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div>
                <form id="picking_create" onSubmit={handleSelectionSubmit}>
                  <div className="bank-pagearea">
                    <div>
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#edtpicking" type="button" role="tab" aria-controls="edtpicking" aria-selected="true"> Edit Picking </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#addneworder" type="button" role="tab" aria-controls="addneworder" aria-selected="false"> Add New Order </button>
                        </li>
                      </ul>
                      <div className="tab-content fulltable-area" id="myTabContent">
                        <div className="tab-pane fade show active" id="edtpicking" role="tabpanel" aria-labelledby="edtpicking">
                          <div className="backlist-content-area">
                            <h3>Picklist No : {picklistNo}</h3>

                            <div className="dashform right">
                              <div style={{ float: 'right' }}>
                                <div style={{ width: '300px', float: 'left', marginRight: '15px' }}>
                                  <select
                                    value={picker}
                                    onChange={(e) => setSelectedPicker(e.target.value)}
                                    className="form-control datepicker-input"
                                  >
                                    <option value="">Re-assigned picker</option>
                                    {options.map(option => (
                                      <option key={option.value} value={option.value}>
                                        {option.key}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <button type="submit" className="multisubmit">
                                  Submit
                                </button>
                              </div>
                            </div>

                            <hr className='devider'/>

                            <div className="verical-scrl-wrapper newsales-table" id="style-3">
                              <table className="table table-bordered dataTable resizable" id="neworderTable">
                                <thead>
                                  <tr>
                                    <th>&nbsp;</th>
                                    <th>Order Date</th>
                                    <th>Customers (Ac/No)</th>
                                    <th>Order Id</th>
                                    <th>Invoice Amount</th>
                                    <th>Sales Rep Collected</th>
                                    <th>Route No</th>
                                    <th style={{ textAlign: 'left' }}>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* Content dynamically generated by DataTables */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane fade" id="addneworder" role="tabpanel" aria-labelledby="addneworder">
                          <div className="backlist-content-area">
                            <h3>Picklist No : {picklistNo}</h3>

                            <hr className='devider'/>

                            <div className="verical-scrl-wrapper newsales-table" id="style-3">
                              <table className="table table-bordered dataTable resizable" id="neworderTableAddNewOrder">
                                <thead>
                                  <tr>
                                    <th>&nbsp;</th>
                                    <th>Order Date</th>
                                    <th>Customers (Ac/No)</th>
                                    <th>Order Id</th>
                                    <th>Invoice Amount</th>
                                    <th>Sales Rep Collected</th>
                                    <th>Route No</th>
                                    <th style={{ textAlign: 'left' }}>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* Content dynamically generated by DataTables */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EditPicking;
