import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import FlashMessage from "../FlashMessage";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { Link } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import config from "../common/Config";
import { format } from "date-fns";
import { DateRangePicker } from "rsuite";

const TopSales = () => {
  const [dates, setDates] = useState([null, null]);
  const accessToken = localStorage.getItem("accessToken");
  const TableRef = useRef(null);
  const s = localStorage.getItem('startDate');
  const e = localStorage.getItem('endDate');

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = "/react/login";
      return;
    }
    initializeTable();
  }, []);
  
  const handleDateChange = (value) => {
    setDates(value);
    if (value && value[0] && value[1]) {
       s = value[0] ? format(value[0], 'yyyy-MM-dd') : '';
       e = value[1] ? format(value[1], 'yyyy-MM-dd') : '';  
      }
    };

    const handleSubmit = () => {
      initializeTable(); 
    };

    const formatDate = (date) => {
      if (!date) return "0";
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    };
  
  const initializeTable = () => {
    const $table = $(TableRef.current);
    if ($.fn.DataTable.isDataTable($table)) {
        $table.DataTable().destroy();
    }
  
    let i = 0;
    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
          url: `${config.API_URL}/get_top_sales`,
          type: 'POST',
          headers: {
             authorization : `Bearer ${accessToken}`,
          },
          data: function (d) {
              return $.extend({}, d, {
                  draw : d.draw,
                  start : d.start,
                  length : d.length,
                  start_date : s ? s : '',
                  end_date : e ? e : ''
              });
          },
          dataSrc: function (json) {
            return Array.isArray(json.data) ? json.data : [];
          }
      },
      language: {
          paginate: {
              first: 'First',
              last: 'Last',
              next: 'Next',
              previous: 'Previous',
          },
          search: 'Search:',
      },
      columns: [
        { data: "name" },
        { data: "order_amt",
          createdCell: function (td) {
            $(td).css("text-align", "right");
          } },        
        { data: "accept_amt",
          createdCell: function (td) {
            $(td).css("text-align", "right");
          } },
        { data: "picking_amt",
          createdCell: function (td) {
            $(td).css("text-align", "right");
          } },
        { data: "deliver_amt",
          createdCell: function (td) {
            $(td).css("text-align", "right");
          } },
        { data: "invoice_amt",
          createdCell: function (td) {
            $(td).css("text-align", "right");
          } },
        { data: "return_amt",
          createdCell: function (td) {
            $(td).css("text-align", "right");
          }  },
        { data: "total_tax",
          createdCell: function (td) {
            $(td).css("text-align", "right");
          } },
      ],
      columnDefs: [
        {
          orderable: false, 
          targets: '_all'
        },
      ],
      pageLength: 10,
    });
  };


  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <Link to="#">Sales</Link>
              <Link to="#">Top Sales</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
        <DateRangePicker
                                value={dates}
                                onChange={handleDateChange}
                                format="dd/MM/yyyy" // Ensure the calendar uses dd/MM/yyyy format
                                character=" - "
                                className="datepkr"
                              />
                            <button
                              type="button"
                              id="submit"
                              className="multisubmit"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
          <div
            className="total-manifests-area dashboard"
            style={{ position: "relative" }}
          >
            <div
              className="verical-scrl-wrapper common-table autoheight-table"
              id="style-3"
            >
              <table className="table table-bordered resizable" id="neworderTable" ref={TableRef}>
                <thead>
                  <tr>
                    <th style={{textAlign:'center'}}>Sales Rep</th>
                    <th style={{textAlign:'center'}}>Order</th>
                    <th style={{textAlign:'center'}}>Accept</th>
                    <th style={{textAlign:'center'}}>Picking</th>
                    <th style={{textAlign:'center'}}>Deliverd</th>
                    <th style={{textAlign:'center'}}>Invoice</th>
                    <th style={{textAlign:'center'}}>Return</th>
                    <th style={{textAlign:'center'}}>VAT</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TopSales;
